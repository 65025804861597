import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ActualiteComponent from '../actualites/ActualitesComponent';
import Avous from '../Avous/AvousComponent';
import ContactComponent from '../contact/ContactComponent';
import FooterComponent from '../footer/FooterComponent';
import HeaderComponent from '../header/HeaderComponent';
import StatistiquesComponent from '../statistiques/StatistiquesComponent';
import ThematiqueListeComponent from '../thematiques/ThematiqueListeComponent';
import HomeCss from '../../css/home/Home.css'



class Home extends Component {


  render() {
    return (
      <div id='home'>
        <HeaderComponent />
        

        <div id='thematique'>
          <ThematiqueListeComponent />
        </div>

        
        <div id='demande'>
          <Avous />
        </div>

        
        <div id='actualite'>
          <ActualiteComponent />
        </div>
       

        <div id='statistique'>
          <StatistiquesComponent />
        </div>

        <div class="b-example-divider"></div>

        <div id='contact'>
          <ContactComponent />
        </div>
        <div class="b-example-divider"></div>
        <div id='apropos'>
          <FooterComponent />
        </div>

      </div>
    );
  }
}

export default withRouter(Home);