import axios from "axios"
const url_societe = "http://localhost:9090/ENQUETE-SERVICE/societe"
//const url_societe="http://192.168.0.53:8080/societe"
class SocieteService {
    addSociete(societe) {
        return axios.post(url_societe + "/add", societe);
    }
    updateSociete(societeId, societe) {
        return axios.put(url_societe + '/update/' + societeId, societe)
    }
}
export default new SocieteService()