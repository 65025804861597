import axios from "axios"

const url_demande = "http://localhost:9090/ENQUETE-SERVICE/demande"
//const url_bd="http://192.168.0.53:8080/demande"
class DemandeService {
    getDemandeByUser(user) {
        return axios.get(url_demande + "/" + user)
    }

    getDemandeByType(type) {
        return axios.get(url_demande + "/type" + "/" + type)
    }


    getDemandeByUserAndType(user, type) {
        return axios.get(url_demande + "/type" + "/" + type + "/" + user)
    }

    getnbreAllDemande() {
        return axios.get(url_demande + "/count");
    }

    addDemande(demande) {
        return axios.post(url_demande + "/add", demande);
    }

    getDemandeById(id) {
        return axios.get(url_demande + "/search" + "/" + id);
    }
    updateDemande(demandeId, demande) {
        return axios.put(url_demande + '/update/' + demandeId, demande)
    }

    deleteDemande(idUser) {
        return axios.delete(url_demande + "/delete" + "/" + idUser)
    }

    getNotification(etat,viewbyUser,user) {
        return axios.get(url_demande + "/count" + "/" + etat+ "/" + viewbyUser+ "/" + user);
      }
}
export default new DemandeService()