import { eventWrapper } from '@testing-library/user-event/dist/utils';
import axios from 'axios';
import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import DemandeService from '../../service/DemandeService';

class ModalReply extends Component {
    constructor(props) {
        super(props)
        this.state = {
            demande: '',
            message: '',
            selected_file: ''
        }
        this.saveReponse = this.saveReponse.bind(this);
        

    }

    async componentDidUpdate() {
        await DemandeService.getDemandeById(this.props.demande).then((res) => {
            this.setState({
                demande: res.data
            })
        });

    }

    handleMessage = (e) => {
        this.setState({
            message: e.target.value
        })
    }

    saveReponse() {
        const { demande, message } = this.state
        let new_demande = {
            message: demande.message,
            objet: demande.objet,
            etat: "repondu",
            type: demande.type,
            user: demande.user,
            indicateurs: demande.indicateurs,
            reponse: message,
            dateEnvoi: demande.dateEnvoi,
            dateReponse: new Date(),
            entite: demande.entite,
            fichierReponse: this.state.selected_file.name,
            viewbyUser:'personne',
            viewbyAgent:'personne'

        }

        DemandeService.updateDemande(this.props.demande, new_demande).then((res) => {
            window.location.reload(true);

        });
    }
    getInput() {

        if (this.props.question == "question") {
            return <div className=' form-group '>
                <label>Réponse</label>
                <textarea value={this.state.message} onChange={this.handleMessage} className='message form-group col-lg-12 form-control py-5' placeholder='' />
            </div>
        }
        else {
            return <div id="laisser_message" className=' form-group '>
                <label>Laisser un message ? </label>
                <textarea value={this.state.message} onChange={this.handleMessage} className='message form-group col-lg-12 form-control py-5' placeholder='' />
            </div>
        }

    }
   

    getFooter() {
       // if (this.props.question == "question") {
            return <div >
                <Modal.Footer>
                    <Button variant="danger" onClick={this.props.closeModelReply} >Annuler</Button>
                    <Button variant="primary" onClick={this.saveReponse} >Envoyer</Button>
                </Modal.Footer>
            </div>
       // }
       /* else {
            return <div id="footer_demande" className=' footer_demande invisible'>
                <Modal.Footer>
                    <Button variant="danger" onClick={this.props.closeModelReply} >Annuler</Button>
                    <Button variant="primary" onClick={this.saveReponse} >Envoyer</Button>
                </Modal.Footer>
            </div>
        }*/
    }
    onclickHandlerFile = (e) => {
        const fd = new FormData();
        fd.append('file', this.state.selected_file, this.state.selected_file.name);
        axios.post('http://localhost/uploadFile.php', fd
        ).then(res => {
            console.log(res);
        }
        );
        //afficher input message 
        //let laisser_message = document.getElementById("laisser_message");
        //let footer_demande = document.getElementById("footer_demande");
        //if (this.state.selected_file !== '') {
          //  laisser_message.classList.remove("invisible");
         //   footer_demande.classList.remove("invisible");
        //}
    }
    handleOnchangeFile = (event) => {
        event.preventDefault();
        this.setState({
            selected_file: event.target.files[0]
        })

    }
   
    render() {

        return (
            <div>

                <Modal
                    {...this.props}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >

                    <Modal.Body>
                        <div className='card-body'>

                            {(this.props.question !== "question") && (<div className='form-group  '>
                                <label>Joindre un fichier</label>
                                <input type="file" onChange={this.handleOnchangeFile} className=' form-group col-lg-12 form-control ' placeholder='' />

                                <button onClick={this.onclickHandlerFile} className="btn btn-success" style={{ marginLeft: "10px" }}>Upload<bi className="bi  bi-check2"></bi></button></div>)}

                            {this.getInput()}
                        </div>
                    </Modal.Body>
                    {this.getFooter()}
                </Modal>
            </div>

        );
    }
}


export default withRouter(ModalReply);