import '../../css/statistiques/StatistiquesCss.css'

import React, { Component } from 'react';
import ThematiqueService from '../../service/ThematiqueService';
import BDService from '../../service/BDService';
import DemandeService from '../../service/DemandeService';
import CountUp from 'react-countup';

class StatistiquesComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nbreBds: 0,
            nbreThematiques: 0,
            nbreDemandes: 0
        }
    }
    componentDidMount() {
        ThematiqueService.getNbreThematique(true).then((res) => {
            this.setState({
                nbreThematiques: res.data
            })
        });

        DemandeService.getnbreAllDemande().then((res) => {
            this.setState({
                nbreDemandes: res.data
            })
        });

        BDService.getnbreAllBds().then((res) => {
            this.setState({
                nbreBds: res.data
            })
        });

    }
    render() {
        const { nbreBds } = this.state
        return (
            <div className='liste_statistiques'>
                <div >
                    <h2 id="title_stat" className='' ><i id="icone_stat" ></i><span>Statistiques</span></h2>
                    <div className=''>
                        <div className='row card-body  d-flex align-items-center justify-content-center justify-content-xs-between  liste_des_statistiques '>
                            {/*Statistiques nombre thematiques */}
                            <div className=' shadow   stat1   py-4  col-lg-3 '>
                                <div className='row  col-lg-12 '>
                                    <div className='col-lg-9 nom_val_stat '>
                                        <b className='nom_stat1'>Thematiques
                                        </b>
                                        <p className='valeur_stat'>
                                            <CountUp end={this.state.nbreThematiques} duration={1}/>
                                            
                                            </p>
                                    </div>
                                    <div className='icone_stat d-flex align-items-center'>
                                        <i className='col-lg-2 bi bi-table'></i>
                                    </div>

                                </div>
                            </div>
                            {/*Fin !!! Statistiques nombre base de thematiques  */}

                            {/*Statistiques nombre base de donnees */}
                            <div className=' shadow  stat2   py-4  col-lg-3 '>
                                <div className='row  col-md-12 '>
                                    <div className='col-lg-9 nom_val_stat '>
                                        <b className='nom_stat2'>Bases de données
                                        </b>
                                        <p className='valeur_stat'> 
                                        <CountUp end={this.state.nbreBds} duration={1}/></p>
                                    </div>
                                    <div className='icone_stat d-flex align-items-center'>
                                        <i className='col-lg-2 bi bi-server'></i>
                                    </div>

                                </div>
                            </div>
                            {/*Fin !!! Statistiques nombre base de Bases de données*/}

                            {/*Statistiques nombre base de demandeurs */}
                            <div className=' shadow  stat3   py-4  col-lg-3 '>
                                <div className='row  col-md-12 '>
                                    <div className='col-lg-9 nom_val_stat '>
                                        <b className='nom_stat3'>Demandes
                                        </b>
                                        <p className='valeur_stat'> 
                                        <CountUp end={this.state.nbreDemandes} duration={1}/></p>
                                    </div>
                                    <div className='icone_stat d-flex align-items-center'>
                                        <i className='col-lg-2 bi bi-envelope'></i>
                                    </div>

                                </div>
                            </div>
                            {/*Fin !!! Statistiques nombre base de demandeurs */}

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default StatistiquesComponent;