import axios from "axios"

const url_departement = "http://localhost:9090/ENQUETE-SERVICE/departement"
//const url_departement="http://192.168.0.53:8080/departement"
class Departement {

    getAllDepartement() {
        return axios.get(url_departement + '/all')
    }

    addDepartement(dep) {
        return axios.post(url_departement + "/add", dep);
    }

    getDepartementById(dep) {
        return axios.get(url_departement + "/search" + "/" + dep)
    }

    getDepartmtByRegion(region) {
        return axios.get(url_departement + "/" + region)
    }
}
export default new Departement()