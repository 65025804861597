import React, { Component } from 'react';
import BDService from '../../service/BDService';
import DepartementService from '../../service/DepartementService';
import PaysService from '../../service/PaysService';
import RegionService from '../../service/RegionService';
import ThematiqueService from '../../service/ThematiqueService';
import addBdCss from '../../css/thematiques/AddBd.css';
import Aside from '../espace_user/Aside';
import NavBarComponent from '../header/NavBarComponent';
import AddEntite from './addEntite';
import { Modal, Button } from 'react-bootstrap';
import ModalConfirm from './ModalConfirm';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withRouter } from "react-router-dom";
import MotCleService from "../../service/MotCleService";
import { keyboard } from '@testing-library/user-event/dist/keyboard';

class AddBd extends Component {
    state = {
        idbd: this.props.match.params.id,
        description: '',
        dateEnquete: '',
        dateEnqueteFin: '',
        dateImportation: '',
        thematique: '',
        nom: '',
        idDB:'',
        dateImportation: new Date(),
        listeThematique: [],
        
        //paysCollecte: '',
       // region: [],
       // departement: [],
        //depCollecte: '',
       // regionCollecte: '',
        autre: '',
       // nouveau_pays: '',
        new_thematique: '',
        nom_Entite: '',
        description_Entite: '',
        Entite: null,
        addModalShow: false,
        addEntiteShow: false,
        KeyWords: [],
        subtype: [],
        //pays
        Pays: [],
        paysSelected: [],
        tabPays:[],
        newPays:[],
        //region
        region: [],
        regionSelected: [],
        tabRegion:[],
        newRegion:[],
        //departement 
        departement: [],
        departSelected: [],
        tabDepartem:[],
        newDepartem:[],
        //keywords
        tabKeywords: [],
        newKeywords:[],
        errors:{},
        acces:''
        
    }

    formValidation = () => {

        const {acces,nom,thematique } = this.state;
        let isValid = true;
        const errors = {}

        if (nom.trim().length < 2) {
            errors.nomerror = "Veuillez mettre un nom correct";
            isValid = false;
        }
        if (acces=='') {
            errors.accesserror = "Veuillez mettre choisir le type d'accès";
            isValid = false;
        }


        if (thematique=='') {
            errors.thematiqueerror = "Veuillez mettre choisir une thematique";
            isValid = false;
        }

        this.setState({ errors });
        return isValid;
    }

    handleNomEntite = (e) => {
        this.setState({
            nom_Entite: e.target.value
        })
    }

    handleAcces = (e) => {
        this.setState({
            acces: e.target.value
        })
    }

    handleDescriptionEntite = (e) => {
        this.setState({
            description_Entite: e.target.value
        })
    }

    handleNomBD = (e) => {
        this.setState({
            nom: e.target.value
        })
    }

    handleThematique = (e) => {
        this.setState({
            thematique: e.target.value
        })
    }

    handleDescription = (e) => {
        this.setState({
            description: e.target.value
        })
    }

    handleDateEnquete = (e) => {
        this.setState({
            dateEnquete: e.target.value
        })
    }

    handleDateEnqueteFin = (e) => {
        this.setState({
            dateEnqueteFin: e.target.value
        })
    }
 
/*
    afficherRegion = (e) => {

        PaysService.getAllPays().then((res) => {
            this.setState({
                pays: res.data
            })
        });

        const { paysCollecte } = this.state
        let region = document.getElementById("region");
        let dep = document.getElementById("departement");
        let new_pays = document.getElementById("nouveau_pays");

        RegionService.getRegionByPays(paysCollecte).then((res) =>
            this.setState({
                region: res.data
            })
        );
        if (paysCollecte !== 'autre' && paysCollecte !== '') {
            region.classList.remove("invisible");
        }

        else {
            region.classList.add("invisible");
            dep.classList.add("invisible");
        }
    }
    */

    //separateur

 /*
    afficherDepartement = (e) => {
        const { regionCollecte } = this.state
        let dep = document.getElementById("departement");
        DepartementService.getDepartmtByRegion(regionCollecte).then((res) =>
            this.setState({
                departement: res.data
            })
        );
        if (regionCollecte !== '') {
            dep.classList.remove("invisible");
        }
        else {
            dep.classList.add("invisible");
        }
    }
   */ 
    
    setCookieFunction = (data) => {
        localStorage.setItem('nomBd', JSON.stringify(data));

    }

    componentDidMount() {
//pour mots cles 
        MotCleService.getAllMotCle().then((res) => {

            this.setState({
                tabKeywords: res.data
            })
            for (let i = 0; i < this.state.tabKeywords.length; i++) {
                let mot_cle = { nom: this.state.tabKeywords[i].nom }
                this.state.KeyWords.push(mot_cle)
              
            }
        })
        //pour pays 
        PaysService.getAllPays().then((res) => {

            this.setState({
                tabPays: res.data
            })
            for (let i = 0; i < this.state.tabPays.length; i++) {
                let pays = { nom: this.state.tabPays[i].nom }
                this.state.Pays.push(pays)
                
            }
        })
//pour region
RegionService.getAllRegion().then((res) => {
    this.setState({
        tabRegion: res.data
    })
    for (let i = 0; i < this.state.tabRegion.length; i++) {
        let region = { nom: this.state.tabRegion[i].nom }
        this.state.region.push(region)  
    }
})
//
//pour departement
DepartementService.getAllDepartement().then((res) => {
    this.setState({
        tabDepartem: res.data
    })
    for (let i = 0; i < this.state.tabDepartem.length; i++) {
        let departement = { nom: this.state.tabDepartem[i].nom }
        this.state.departement.push(departement)
        
    }
})
//
        this.setCookieFunction(this.state.nom)

        ThematiqueService.getAllThematique().then((res) => {
            this.setState({
                listeThematique: res.data
            })

        });

        PaysService.getAllPays().then((res) => {
            this.setState({
                pays: res.data
            })
        });

        if (this.state.idbd == -1) {
            return
        } else {
            BDService.getBDById(this.state.idbd).then((res) => {
                let bd = res.data;
                this.setState({
                    description: bd.description,
                    depCollecte: bd.departement,
                    regionCollecte: bd.region,
                    paysCollecte: bd.pays,  
                    dateEnquete:bd.anneeDebutEnquete,
                    dateEnqueteFin:bd.anneeFinEnquete,
                    nom: bd.nom,
                    thematique: bd.thematique,
                    acces:bd.acces
                    
                })
            });

        }

    }

   /* fermerAjoutPays() {
        let pays = document.getElementById("pays");
        let new_pays = document.getElementById("nouveau_pays");
        new_pays.classList.add("invisible");
        pays.classList.remove("invisible");
    }*/

   /* addPays() {
        let pays = document.getElementById("pays");
        let region = document.getElementById("region");
        let departement = document.getElementById("departement");
        let new_pays = document.getElementById("nouveau_pays");
        new_pays.classList.remove("invisible");
        pays.classList.add("invisible");
        region.classList.add("invisible");
        departement.classList.add("invisible");

    }

    handleNewPays = (e) => {
        this.setState({
            nouveau_pays: e.target.value
        })
    }

    validerAddPays = (e) => {
        let pays = {
            nom: this.state.nouveau_pays
        }
        PaysService.addPays(pays);
        let payss = document.getElementById("pays");
        let new_pays = document.getElementById("nouveau_pays");
        new_pays.classList.add("invisible");
        payss.classList.remove("invisible");

    }*/

    versSesBD = (id) => {
        this.props.history.push(`/listebd/${id}/#`);
    }

    saveBD = (e) => {
        e.preventDefault();

        const isValid = this.formValidation();
        if (isValid) {
            if(this.state.idbd != -1){
                ThematiqueService.getThematiqueByNom(this.state.thematique).then((res) => {
                    this.setState({
                        new_thematique: res.data.idThematique
                    })
                    let bd = {
                        description: this.state.description,
                        departement: this.state.departSelected,
                        region: this.state.regionSelected,
                        pays: this.state.paysSelected,
                        anneeDebutEnquete: this.state.dateEnquete,
                        anneeFinEnquete: this.state.dateEnqueteFin,
                        nom: this.state.nom,
                        acces:this.state.acces,
                        thematique: this.state.new_thematique,
                        mots_cles: this.state.subtype,
                        dateImportation:new Date(),
                        
                    }

                    BDService.updateBase(this.state.idbd,bd).then((res)=>{
                        window.location.reload(true);

                        const{subtype,KeyWords,newKeywords,paysSelected,pays,newPays,regionSelected,region,newRegion,departSelected,newDepartem,departement}=this.state
                        // pour mot cle 
                         let tab=[]
                         
                         KeyWords.forEach(element => {   
                             tab.push(element.nom.toLowerCase() )
                         });
                         subtype.forEach(element => {
                             if((!tab.includes(element.nom.toLowerCase())) && (!newKeywords.includes(element.nom.toLowerCase()))){
                                 newKeywords.push(element.nom.toLowerCase())
                             }
                         });
                             this.state.newKeywords.forEach(element => {
                                 let motlce={nom:element}
                                 console.log(motlce)
                                 MotCleService.addMotCle(motlce)
                             })
     
                              // pour pays
                         let paystab=[] 
                         pays.forEach(element => {   
                             paystab.push(element.nom.toLowerCase() )
                         });
                         paysSelected.forEach(element => {
                             if((!paystab.includes(element.nom.toLowerCase())) && (!newPays.includes(element.nom.toLowerCase()))){
                                 newPays.push(element.nom.toLowerCase())
                             }
                         });
                             this.state.newPays.forEach(element => {
                                 let pay={nom:element}   
                                 PaysService.addPays(pay)
                             })
     
                             //pour region
                             let regiontab=[] 
                             region.forEach(element => {   
                                 regiontab.push(element.nom.toLowerCase() )
                             });
                             regionSelected.forEach(element => {
                                 if((!regiontab.includes(element.nom.toLowerCase())) && (!newRegion.includes(element.nom.toLowerCase()))){
                                     newRegion.push(element.nom.toLowerCase())
                                 }
                             });
                                 this.state.newRegion.forEach(element => {
                                     let regio={nom:element}    
                                     RegionService.addRegion(regio)
                                 })
                         //pour departement
                             let departTab=[] 
                             departement.forEach(element => {   
                                 departTab.push(element.nom.toLowerCase() )
                             });
                             departSelected.forEach(element => {
                                 if((!departTab.includes(element.nom.toLowerCase())) && (!newDepartem.includes(element.nom.toLowerCase()))){
                                     newDepartem.push(element.nom.toLowerCase())
                                 }
                             });
                                 this.state.newDepartem.forEach(element => {
                                     let depar={nom:element}
                                     DepartementService.addDepartement(depar)
                                 })
                              


                   })
            });
               
            }
        else  {
            ThematiqueService.getThematiqueByNom(this.state.thematique).then((res) => {
                this.setState({
                    new_thematique: res.data.idThematique
                })
                //console.log(this.state.new_thematique);
                let bd = {
                    description: this.state.description,
                    departement: this.state.departSelected,
                    region: this.state.regionSelected,
                    pays: this.state.paysSelected,
                    anneeDebutEnquete: this.state.dateEnquete,
                    anneeFinEnquete: this.state.dateEnqueteFin,
                    nom: this.state.nom,
                    acces: true,
                    thematique: this.state.new_thematique,
                    mots_cles: this.state.subtype,
                    dateImportation:new Date(),
                    acces:this.state.acces

                }
                BDService.addBD(bd).then(() => {
                    const{subtype,KeyWords,newKeywords,paysSelected,pays,newPays,regionSelected,region,newRegion,departSelected,newDepartem,departement}=this.state
                   // pour mot cle 
                    let tab=[]
                    
                    KeyWords.forEach(element => {   
                        tab.push(element.nom.toLowerCase() )
                    });
                    subtype.forEach(element => {
                        if((!tab.includes(element.nom.toLowerCase())) && (!newKeywords.includes(element.nom.toLowerCase()))){
                            newKeywords.push(element.nom.toLowerCase())
                        }
                    });
                        this.state.newKeywords.forEach(element => {
                            let motlce={nom:element}
                            console.log(motlce)
                            MotCleService.addMotCle(motlce)
                        })

                         // pour pays
                    let paystab=[] 
                    pays.forEach(element => {   
                        paystab.push(element.nom.toLowerCase() )
                    });
                    paysSelected.forEach(element => {
                        if((!paystab.includes(element.nom.toLowerCase())) && (!newPays.includes(element.nom.toLowerCase()))){
                            newPays.push(element.nom.toLowerCase())
                        }
                    });
                        this.state.newPays.forEach(element => {
                            let pay={nom:element}   
                            PaysService.addPays(pay)
                        })

                        //pour region
                        let regiontab=[] 
                        region.forEach(element => {   
                            regiontab.push(element.nom.toLowerCase() )
                        });
                        regionSelected.forEach(element => {
                            if((!regiontab.includes(element.nom.toLowerCase())) && (!newRegion.includes(element.nom.toLowerCase()))){
                                newRegion.push(element.nom.toLowerCase())
                            }
                        });
                            this.state.newRegion.forEach(element => {
                                let regio={nom:element}    
                                RegionService.addRegion(regio)
                            })
                    //pour departement
                        let departTab=[] 
                        departement.forEach(element => {   
                            departTab.push(element.nom.toLowerCase() )
                        });
                        departSelected.forEach(element => {
                            if((!departTab.includes(element.nom.toLowerCase())) && (!newDepartem.includes(element.nom.toLowerCase()))){
                                newDepartem.push(element.nom.toLowerCase())
                            }
                        });
                            this.state.newDepartem.forEach(element => {
                                let depar={nom:element}
                                DepartementService.addDepartement(depar)
                            })
                            //

                this.openModel()
                        //.then(this.openModel());
                    
                    //this.versSesBD(this.state.new_thematique);

                });
            });


        }
        } 
}
    fermerAjoutEntite = () => {
        //this.closeEntite()
        this.versSesBD(this.state.new_thematique);
    }

    openModel = () => {
        this.setState({
            addModalShow: true
        });
    }

    openEntiteModal = () => {

        this.setState({
            addEntiteShow: true,
            addModalShow: false
        });

    }


//mots cles
    setSubtype = (attr) => {
        for (let i = 0; i < attr?.length; i++) {
            var index = attr.indexOf(attr[i]);
            if (typeof attr[i] === "string") {
                let motCle = { nom: attr[i].toLowerCase() }
                attr.push(motCle)
                if (index !== -1) {
                    attr.splice(index, 1);
                }
            }
        }
        let jsonObject = attr.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        attr = Array.from(uniqueSet).map(JSON.parse);
        this.setState({
            subtype: attr
        })
    }
//pour pays
    setSelectedPays = (attr) => {
        for (let i = 0; i < attr?.length; i++) {
            var index = attr.indexOf(attr[i]);
            if (typeof attr[i] === "string") {
                let pays = { nom: attr[i].toLowerCase() }
                attr.push(pays)
                if (index !== -1) {
                    attr.splice(index, 1);
                }
            }
        }
        let jsonObject = attr.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        attr = Array.from(uniqueSet).map(JSON.parse);
        this.setState({
            paysSelected: attr
        })
    }

    //pour departement
    setSelectedDepartement = (attr) => {
        for (let i = 0; i < attr?.length; i++) {
            var index = attr.indexOf(attr[i]);
            if (typeof attr[i] === "string") {
                let dep = { nom: attr[i].toLowerCase() }
                attr.push(dep)
                if (index !== -1) {
                    attr.splice(index, 1);
                }
            }
        }
        let jsonObject = attr.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        attr = Array.from(uniqueSet).map(JSON.parse);
        this.setState({
            departSelected: attr
        })
    }

    //
 //pour region 
 setSelectedRegion = (attr) => {
    for (let i = 0; i < attr?.length; i++) {
        var index = attr.indexOf(attr[i]);
        if (typeof attr[i] === "string") {
            let region = { nom: attr[i].toLowerCase() }
            attr.push(region)
            if (index !== -1) {
                attr.splice(index, 1);
            }
        }
    }
    let jsonObject = attr.map(JSON.stringify);
    let uniqueSet = new Set(jsonObject);
    attr = Array.from(uniqueSet).map(JSON.parse);
    this.setState({
        regionSelected: attr
    })
}

    render() {
        const { KeyWords, subtype } = this.state

        let openAddEntite = () => {
            this.openEntiteModal()
        }



        let closeModel = () => {
            this.setState({
                addModalShow: false
            });
            this.versSesBD(this.state.new_thematique);
        }

        let closeEntite = () => {
            this.setState({
                addEntiteShow: false
            });

        }

        const {errors, listeThematique, pays, region, departement ,regionSelected,paysSelected,departSelected} = this.state
        return (
            <div className='profil_user '>
                <NavBarComponent />
                <div className='row col-lg-12'>
                    <Aside  />
                    <div className='col-lg-10 card-body'>

                        <div className='container card card-body shadow  '>
                            <div>
                                <div className=''>

                                    <AddEntite
                                        show={this.state.addEntiteShow}
                                        onHide={closeEntite}
                                        bd={this.state.nom}
                                        fermerAjoutEntite={this.fermerAjoutEntite}
                                    />

                                    <ModalConfirm
                                        show={this.state.addModalShow}
                                        onHide={closeModel}
                                        openEntite={openAddEntite}
                                    />

                                    <div className='row'>
                                        <div className='form-group label_et_input col-lg-6'>
                                            <label  >Nom de la base de données </label>
                                            <input value={this.state.nom} onChange={this.handleNomBD} type="text" className='form-control' />
                                            <i className='texte_champs_vide'>{errors.nomerror}</i>
                                        </div>

                                        <div className='form-group label_et_input col-lg-6'>
                                            <label >Thématique </label>

                                            <select onChange={this.handleThematique} className='form-control'>
                                                <option value=''>--choisir--</option>
                                                {
                                                    listeThematique.map(listetheme =>

                                                        (listetheme.visibility) && (

                                                            <option  >{listetheme.nom}</option>
                                                        )
                                                    )

                                                }
                                            </select>
                                            <i className='texte_champs_vide'>{errors.thematiqueerror}</i>
                                        </div>

                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='form-group label_et_input col-lg-6'>
                                        <label>Année de debut</label>
                                        <input value={this.state.dateEnquete} onChange={this.handleDateEnquete} type="number" className='form-control' />
                                    </div>
                                    <div className='form-group label_et_input col-lg-6'>
                                        <label>Année de fin</label>
                                        <input value={this.state.dateEnqueteFin} onChange={this.handleDateEnqueteFin} type="number" className='form-control' />
                                    </div>
                                    <div className='form-group label_et_input col-lg-6'>
                                        <label>Accès </label>
                                        <select value={this.state.acces}  className='form-control' onChange={this.handleAcces}>
                                            <option value=''>--choisir--</option>
                                            <option value={true}>Public</option>
                                            <option value={false}>Privé</option>
                                        </select> 
                                        <i className='texte_champs_vide'>{errors.accesserror}</i>

                                    </div>
                                    <div className='form-group label_et_input col-lg-6'>
                                        <div style={{ width: 500 }}>
                                            <Autocomplete
                                                multiple
                                                id="tags-standard"
                                                freeSolo
                                                value={subtype}
                                                filterSelectedOptions
                                                options={KeyWords}

                                                onChange={(e, attr) => this.setSubtype(attr)}
                                                //onKeyDown={(e, attr) => setSubtype(attr)}
                                                getOptionLabel={(option) => option.nom}
                                                renderInput={(params) => (
                                                    <div >

                                                        <TextField
                                                            {...params}
                                                            variant="standard"
                                                            placeholder="Mots Clés..."
                                                            margin="normal"

                                                        />
                                                    </div>
                                                    

                                                )}
                                            />

                                        </div>
                                    </div>


                                </div>

                                <div className='row'>

                                </div>
                                <div className='row'>

                                    <div className='form-group label_et_input col-lg-6'>
                                        <fieldset class="border card-body ">
                                            <legend class="zone w-auto">Zone de Collecte</legend>
                                            <div id="pays" className='pays'>
                                            <div style={{ width: 460 }}>
                                            <Autocomplete
                                                multiple
                                                id="tags-standard"
                                                freeSolo
                                                value={paysSelected}
                                                filterSelectedOptions
                                                options={pays}

                                                onChange={(e, attr) => this.setSelectedPays(attr)}
                                                //onKeyDown={(e, attr) => setSubtype(attr)}
                                                getOptionLabel={(option) => option.nom}
                                                renderInput={(params) => (
                                                    <div >

                                                        <TextField
                                                            {...params}
                                                            variant="standard"
                                                            placeholder="Pays..."
                                                            margin="normal"

                                                        />
                                                    </div>

                                                )}
                                            />

                                        </div>
                                                {/*<button onClick={this.addPays} className='ajoutPays' href=''>nouveau pays?</button>*/}
                                            </div>
                                            {/*
                                            <div id="nouveau_pays" className='invisible nouveau_pays form-group '>

                                                <div className='container-fluid'>
                                                    <button onClick={this.fermerAjoutPays} className='row d-flex align-item-right fermer '> <bi className="bi bi-x"></bi></button>
                                                    <label className='row'>Ajouter un nouveau pays </label>
                                                </div>
                                                <input onChange={this.handleNewPays} type="text" placeholder='Mettez ici le nom du pays...' className='form-control' />
                                                <br /> <button onClick={this.validerAddPays} className='d-flex align-item-center btn btn-primary'>Valider</button>
                                            </div>
                                            */}


                                            {/*region*/}

                                            <div id="region" className=''>
                                            <div style={{ width: 460 }}>
                                            <Autocomplete
                                                multiple
                                                id="tags-standard"
                                                freeSolo
                                                value={regionSelected}
                                                filterSelectedOptions
                                                options={region}

                                                onChange={(e, attr) => this.setSelectedRegion(attr)}
                                                //onKeyDown={(e, attr) => setSubtype(attr)}
                                                getOptionLabel={(option) => option.nom}
                                                renderInput={(params) => (
                                                    <div >

                                                        <TextField
                                                            {...params}
                                                            variant="standard"
                                                            placeholder="Region..."
                                                            margin="normal"

                                                        />
                                                    </div>

                                                )}
                                            />

                                        </div>  
                                            </div>
                                            <div id="departement" className=''>
                                            <div style={{ width: 460 }}>
                                            <Autocomplete
                                                multiple
                                                id="tags-standard"
                                                freeSolo
                                                value={departSelected}
                                                filterSelectedOptions
                                                options={departement}

                                                onChange={(e, attr) => this.setSelectedDepartement(attr)}
                                                //onKeyDown={(e, attr) => setSubtype(attr)}
                                                getOptionLabel={(option) => option.nom}
                                                renderInput={(params) => (
                                                    <div >

                                                        <TextField
                                                            {...params}
                                                            variant="standard"
                                                            placeholder="Departement..."
                                                            margin="normal"

                                                        />
                                                    </div>

                                                )}
                                            />

                                        </div>  
                                            </div>
                                        </fieldset>


                                    </div>


                                    <div className='form-group label_et_input col-lg-6'>
                                        <label>Description</label>
                                        <textarea value={this.state.description} onChange={this.handleDescription} type="text" className='form-control py-5' />
                                    </div>
                                </div>
                                <div className='row'>

                                </div>
                                <div className='d-flex btn_div justify-content-right col-lg-6'>
                               <div className='card-body'>
                               <div className='card-body'>
                                    <a className='btn div_envoi '>
                                        <span className=' btn_envoi' onClick={this.saveBD}>Valider</span>
                                    </a>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div id="Entite" className='Entite invisible'>

                            </div>

                        </div>


                    </div>
                </div>


            </div>



        );
    }
}

export default withRouter(AddBd);