import React, { Component } from 'react';
import EntiteService from '../../service/EntiteService';
import Aside from '../espace_user/Aside';
import NavBarComponent from '../header/NavBarComponent';
import ThematiqueListeCss from '../../css/thematiques/ListeEntite.css'
import MenuBd from './MenuBd';
import UserService from '../../service/UserService';
import AddEntite from './addEntite';
import BDService from '../../service/BDService';
import { Collapse, Modal } from 'react-bootstrap';
import IndicateurService from '../../service/IndicateurService';
import Indicateur from './Indicateur';
import ModalChoix from '../Avous/ModalChoix';
import ModalInfo from '../Avous/ModalInfo';

class ListeEntites extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idBD: this.props.match.params.id,
            listeEntites: [],
            idUser: parseInt(localStorage.getItem('idUser')),
            user: '',
            addEntiteShow: false,
            nom: "",
            nbreEntites: 0,
            nbre: 0,
            modalChoixShow: false,
            entite_bd: null,
            modalShow: false,

        }
        this.showHide = this.showHide.bind(this);
    }

    closeModelChoix = () => {
        this.setState({
            modalChoixShow: false
        });

    }

    openModal = (entite) => {
        this.setState({
            modalChoixShow: true,
            entite_bd: entite
        });
    }

    componentDidMount() {
        EntiteService.getNbreEntiteByBD(this.state.idBD).then((res) => {
            this.setState({
                nbreEntites: res.data
            })
        });

        UserService.getUserById(this.state.idUser).then((res) => {
            this.setState({
                user: res.data
            })

        }
        );
        EntiteService.getEntiteByBd(this.state.idBD).then((res) => {
            this.setState({
                listeEntites: res.data
            })

        });
        BDService.getBDById(this.state.idBD).then((res) => {
            this.setState({
                nom: res.data.nom
            })
        });
    }

    fermerAjoutEntite = () => {
        this.setState({
            addEntiteShow: false
        });
        window.location.reload(true);
    }

    openEntiteModal = (e) => {
        e.preventDefault();
        
        //localStorage.setItem('entiteBd', JSON.stringify(this.state.entite_bd));
        this.setState({
            addEntiteShow: true
        });

    }

    openInscrireModal = (e) => {
        this.setState({
            modalShow: true
        });

    }
    inscrire = () => {
        this.props.history.push('/signUp/-1')
    }


    showHide = (entite) => {
        let btn_ouvrir = document.getElementById(entite.target.id);
        let collapse = document.getElementById(entite.target.name);

        if (btn_ouvrir.classList.contains("bi-caret-down-fill")) {
            btn_ouvrir.classList.remove("bi-caret-down-fill");
            btn_ouvrir.classList.add("bi-caret-up-fill");
            //
            collapse.classList.remove("invisible");
        } else {
            btn_ouvrir.classList.remove("bi-caret-up-fill");
            btn_ouvrir.classList.add("bi-caret-down-fill");
            //
            collapse.classList.add("invisible");
        }

    }

    saveIndicateurs = (e) => {
        e.preventDefault();
        //console.log("save")
    }
    //
    envoiDemande = (idEntite, idBD) => {
        if (this.state.user == '') {
            this.openInscrireModal();
        }else{
        this.props.history.push(`/nouvelleDemande/${idEntite}_-${idBD}`)}

    }
    closeModel=()=>{
        this.setState({
            modalShow: false
        });
    }

    
    render() {
        let closeEntite = () => {
            this.setState({
                addEntiteShow: false
            });

        }
        const { listeEntites,modalShow } = this.state
        return (
            <div>
                <NavBarComponent />
                <ModalInfo
                    show={modalShow}
                    closeModel={this.closeModel}
                    inscrire={this.inscrire}

                />
                <MenuBd active={3}bd={this.state.idBD} nbreEntites={this.state.nbreEntites} />
                <div className=' card-body '>

                    <div id="Entite" className='Entite '>
                        <AddEntite
                            show={this.state.addEntiteShow}
                            onHide={closeEntite}
                            bd={this.state.nom}
                            fermerAjoutEntite={this.fermerAjoutEntite}
                        />

                    </div>

                    {(this.state.user.profil == "admin") && (           
                    <a href="#" onClick={this.openEntiteModal}><span><bi className="bi  btn-add-thematique  bi-plus-circle-fill"><span className='ajout_theme'>Ajouter  volet</span> </bi></span></a>
                    )}
                    <div className='col-lg-12  d-flex justify-content-center  row '>

                        {

                            listeEntites.map(listeentite =>

                                <div key={listeentite.nom} className=' shadow entite_nom_desc col-lg-10   '>
                                    <div className='  py-3 d-flex container-fluid d-flex justify-content-between nom_entite '>
                                        <div>{listeentite.nom}</div>
                                        <div className='demande'>
                                            <a className='btn div_envoi_ '>
                                                <span onClick={() => this.envoiDemande(listeentite.idEntite, listeentite.idBD)} className='btn_envoi_ '><bi className="bi bi-shift-fill"></bi></span>
                                            </a>
                                        </div>
                                    </div>

                                    <div className=' d-flex container-fluid description '>
                                        {listeentite.description}
                                    </div>

                                    <div className='row  d-flex justify-content-between indicateurs'>
                                        <span className='indicateur '>Indicateurs</span>
                                        <div className='btn_plus_indicateurs'>
                                            <a
                                                onClick={this.showHide}
                                                className="btn-primary btn bi-caret-down-fill"
                                                role="button"
                                                aria-expanded="false"
                                                id={listeentite.idEntite + listeentite.nom}
                                                name={listeentite.idEntite}
                                            >

                                            </a>
                                        </div>
                                    </div>

                                    <div  >

                                        <Collapse id={listeentite.idEntite} className='invisible container collapse' in={true} >
                                            <div className=" card-body">

                                                <Indicateur entite={listeentite.idEntite} />

                                            </div>
                                        </Collapse>
                                    </div>
                                </div>

                            )
                        }
                    </div>
                </div>

                <ModalChoix
                    show={this.state.modalChoixShow}
                    closeModelChoix={this.closeModelChoix}
                // entite_bd={this.state.entite_bd}

                />
            </div>
        );
    }
}

export default ListeEntites;