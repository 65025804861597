import React, { Component } from 'react';

class RetrieveMessage extends Component {
   
    constructor(props) {
        super(props);
        this.state = {
            idUser: parseInt(localStorage.getItem('idUser')),
            entite_bd: this.props.entite_bd,
            message: null

        }

    }
    async componentDidMount() {
        let mesg= this.state.entite_bd.split("_-")[2]  
            this.setState({
                message: mesg
            })
    }

    render() {
        return (
            <span className='msg_demande_tableau'>{this.state.message}</span>
        );
    }
}

export default RetrieveMessage;