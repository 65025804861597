import React, { Component } from 'react';
import loginCSS from '../../css/login/loginCSS.css';
import { withRouter } from 'react-router-dom';
import SocieteService from '../../service/SocieteService';
import UserService from '../../service/UserService';
import validator from 'validator';

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idpage: this.props.match.params.id,
            user:'',

            mail: '',
            mdp: '',
            mdp_conf: '',
            sexe: '',
            prenom: '',
            nom: '',
            adresse_user: '',
            ville_user: '',
            telephone1: '',
            telephone2: '',
            profession: '',
            pays_user: '',
            verification: '',
            date_inscription: '',
            date_de_derniere_connexion: '',
            profil: 'visiteur',

            type_user: 'physique',
            validation_condition: '',

            nom_structure: '',
            description: '',
            courriel: '',
            telephone: '',
            adresse_structure: '',
            boite_postale: '',
            ville: '',
            pays_structure: '',
            site_web: '',
            type_societe: '',
            errors: {}



        }
    }

    formValidation = () => {

        const { mail, mdp, mdp_conf, prenom, nom, profession, telephone1, telephone2, telephone, nom_structure, courriel, validation_condition, type_user } = this.state;
        let isValid = true;
        const errors = {}
        if (!validator.isEmail(mail)) {
            errors.emailerror = "Veuillez entrer un e-mail valide";
            isValid = false;
        }



        if (mdp.trim().length < 8) {
            errors.passworderror = "Votre mot de passe doit contenir 8 caractères";
            isValid = false;
        }

        if (prenom.trim().length < 2) {
            errors.prenomerror = "Mettez un prenom correct";
            isValid = false;
        }

        if (nom.trim().length < 2) {
            errors.nomerror = "Mettez un nom correct";
            isValid = false;
        }



        if (profession.trim() == '') {
            errors.professionerror = "Il faut choisir une profession";
            isValid = false;
        }


        var ma_regexp = /^(77|78|75|76|70|33)[0-9]{7}$/;
        if (!ma_regexp.test(telephone1)) {
            errors.telephonerror = "Le numero de telephone est incorrect";
            isValid = false;
        }
        if (telephone2 != '') {
            if (!ma_regexp.test(telephone2)) {
                errors.telephonerror = "Le numero de telephone est incorrect";
                isValid = false;
            }
        }


        if (type_user == "morale") {
            if (!ma_regexp.test(telephone)) {
                errors.telephonerror = "Le numero de telephone est incorrect";
                isValid = false;
            }

            if (nom_structure.trim().length < 2) {
                errors.nomstructureerror = "Mettez un nom de structure correct";
                isValid = false;
            }
            if (!validator.isEmail(courriel)) {
                errors.courrielerror = "Veuillez entrer un e-mail valide";
                isValid = false;
            }
        }


        if (mdp !== mdp_conf) {
            errors.passwordconferror = "Les deux mots de passe ne sont pas conformes";
            isValid = false;
        }

        if (!validation_condition) {
            errors.validationerror = "Accepter les conditions";
            isValid = false;
        }
        this.setState({ errors });
        return isValid;
    }

    Handle_Personne_Morale = (e) => {
        this.setState({
            type_user: e.target.value

        })
        let personne_morale = document.getElementById("personne_morale");
        personne_morale.classList.remove("invisible");
        //console.log(this.state.idpage);
    }

    Handle_Personne_Physique = (e) => {
        this.setState({
            type_user: e.target.value

        })
        let personne_morale = document.getElementById("personne_morale");
        personne_morale.classList.add("invisible");
    }

    setCookieFunction = (data) => {
        localStorage.setItem('idUser', JSON.stringify(data));    
    }
    saveUser = (event) => {
        event.preventDefault();
        const isValid = this.formValidation();
        if (isValid) {

            let date = new Date()
            if (this.state.type_user == "physique") {
                let user = {
                    profil: this.state.profil,
                    prenom: this.state.prenom,
                    type: this.state.type_user,
                    nom: this.state.nom,
                    telephone2: this.state.telephone2,
                    telephone1: this.state.telephone1,
                    adresse: this.state.adresse_user,
                    pays: this.state.pays_user,
                    sexe: this.state.sexe,
                    profession: this.state.profession,
                    username: this.state.mail,
                    password: this.state.mdp,
                    verification: 1,
                    date_inscription: date,


                }
                UserService.addUser(user).then((res1) => {
                    
                    UserService.getUserByEmailAndPwd(this.state.mail, this.state.mdp).then((res) => {
                        this.setState({
                            user: res.data
                        })
                        this.setCookieFunction(this.state.user.idUser);
                        this.props.history.push(`/connect/${this.state.user.username}`)
                        window.location.reload(true);
                       //console.log(this.state.user);
                        
                    })
                });
            } else {
                let societe = {
                    nom: this.state.nom_structure,
                    description: this.state.description,
                    courriel: this.state.courriel,
                    telephone: this.state.telephone,
                    adresse: this.state.adresse_structure,
                    boite_postale: this.state.boite_postale,
                    ville: this.state.ville,
                    pays: this.state.pays_structure,
                    site_web: this.state.site_web,
                    type: this.state.type_societe
                }

                let user = {
                    profil: this.state.profil,
                    prenom: this.state.prenom,
                    type: this.state.type_user,
                    nom: this.state.nom,
                    telephone2: this.state.telephone2,
                    telephone1: this.state.telephone1,
                    adresse: this.state.adresse_user,
                    pays: this.state.pays_user,
                    sexe: this.state.sexe,
                    profession: this.state.profession,
                    username: this.state.mail,
                    password: this.state.mdp,
                    date_inscription: this.state.date_inscription,
                    societe: societe,
                    verification: this.state.verification,
                    date_inscription: date,
                }
                UserService.addUser(user).then((res) => {
                    SocieteService.addSociete(societe).then((res) => {
                        UserService.getUserByEmailAndPwd(this.state.mail, this.state.mdp).then((res) => {
                            this.setState({
                                user: res.data
                            })
                            this.setCookieFunction(this.state.user.idUser);
                            this.props.history.push(`/connect/${this.state.user.username}`)
                            window.location.reload(true);
                         
                            
                        })
                    }

                    );
                });
            }
        }
    }


    HandleNom = (e) => {
        this.setState({
            nom: e.target.value
        })
    }

    HandlePays_user = (e) => {
        this.setState({
            pays_user: e.target.value
        })
    }
    HandleProfession = (e) => {
        this.setState({
            profession: e.target.value
        })
    }
    HandleTelephone2 = (e) => {
        this.setState({
            telephone2: e.target.value
        })
    }

    HandleProfil = (e) => {
        this.setState({
            profil: e.target.value
        })
    }
    HandleTypeSociete = (e) => {
        this.setState({
            type_societe: e.target.value
        })
    }

    HandleTelephone1 = (e) => {
        this.setState({
            telephone1: e.target.value
        })
    }
    HandleVilleUser = (e) => {
        this.setState({
            ville_user: e.target.value
        })
    }
    HandleAdresseUser = (e) => {
        this.setState({
            adresse_user: e.target.value
        })
    }
    HandlePrenom = (e) => {
        this.setState({
            prenom: e.target.value
        })
    }

    HandleSexe_Masculin = (e) => {
        this.setState({
            sexe: e.target.value
        })
    }

    HandleSexe_Feminin = (e) => {
        this.setState({
            sexe: e.target.value
        })
    }
    HandleMdp_conf = (e) => {
        this.setState({
            mdp_conf: e.target.value
        })
    }
    HandleMdp = (e) => {
        this.setState({
            mdp: e.target.value
        })
    }
    HandleMail = (e) => {
        this.setState({
            mail: e.target.value
        })
    }


    HandleValidation_condition = (e) => {
        this.setState({
            validation_condition: e.target.checked
        })
    }


    HandleSite_web = (e) => {
        this.setState({
            site_web: e.target.value
        })
    }
    HandlePays_structure = (e) => {
        this.setState({
            pays_structure: e.target.value
        })
    }
    HandleVille = (e) => {
        this.setState({
            ville: e.target.value
        })
    }
    HandleBoite_postale = (e) => {
        this.setState({
            boite_postale: e.target.value
        })
    }
    HandleAdresse_structure = (e) => {
        this.setState({
            adresse_structure: e.target.value
        })
    }
    HandleTelephone_Societe = (e) => {
        this.setState({
            telephone: e.target.value
        })
    }
    HandleCourriel = (e) => {
        this.setState({
            courriel: e.target.value
        })
    }
    HandleDescription = (e) => {
        this.setState({
            description: e.target.value
        })
    }
    HandleNom_structure = (e) => {
        this.setState({
            nom_structure: e.target.value
        })
    }


    render() {
        const { errors } = this.state;
        return (
            <div className='card-body grand_div_signUp'>
                {/*------------------------------Informations d'accés-------------------------*/}
                <div className='container shadow card '>
                    <div className='acces container '>
                        <h3 className='titre '>1-Informations d'accés au compte </h3>
                        <div className='row'>
                            <div className='form-group  label_et_input col-lg-6'>
                                <label>Adresse mail </label><i className='etoile_rouge'>*</i>
                                <input onChange={this.HandleMail} type="text" className='form-control' />
                                <i className='texte_champs_vide'>{errors.emailerror}</i>
                            </div>
                            <div className='form-group  label_et_input col-lg-6'>
                                {/*  <label>Profil </label>
                       <select onChange={this.HandleProfil} className='form-control'>
                            <option value="user_lartes">Utilsateur Lartes</option>
                            <option value="agent">Agent Lartes </option>
                            <option value="visiteur">Visiteur  </option>
                       </select>
                */}
                            </div>
                        </div>

                        <div className='row'>
                            <div className='form-group label_et_input col-lg-6'>
                                <label>Mot de passe </label><i className='etoile_rouge'>*</i>
                                <input onChange={this.HandleMdp} type="password" className='form-control' />
                                <i className='texte_champs_vide'>{errors.passworderror}</i>
                            </div>


                            <div className='form-group label_et_input col-lg-6'>
                                <label>Confirmer votre mot de passe </label><i className='etoile_rouge'>*</i>
                                <input onChange={this.HandleMdp_conf} type="password" className='form-control' />
                                <i className='texte_champs_vide'>{errors.passwordconferror}</i>
                            </div>
                        </div>

                        <div className='form-group row label_et_input col-lg-6'>


                        </div>
                    </div>
                </div>
                <br />
                {/*------------------------------Personne Physique-------------------------*/}
                <div className='container shadow  card '>
                    <div className='personne_physique container '>
                        <h3 className='titre '>2-Responsable du compte</h3>

                        <div className='row'>
                            <div className='form-group label_et_input col-lg-3'>
                                <input type="radio" onChange={this.HandleSexe_Masculin} value='Homme' name='sexe' className='' /><label className='sexe'>M. </label>
                            </div>

                            <div className='form-group label_et_input col-lg-3'>
                                <input onChange={this.HandleSexe_Feminin} value="Femme" type="radio" name='sexe' className='' /><label className='sexe'>Mme </label>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='form-group label_et_input col-lg-6'>
                                <label>Prenom(s) </label><i className='etoile_rouge'>*</i>
                                <input onChange={this.HandlePrenom} type="text" className='form-control' />
                                <i className='texte_champs_vide'>{errors.prenomerror}</i>
                            </div>

                            <div className='form-group label_et_input col-lg-6'>
                                <label>Nom </label><i className='etoile_rouge'>*</i>
                                <input onChange={this.HandleNom} type="text" className='form-control' />
                                <i className='texte_champs_vide'>{errors.nomerror}</i>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='form-group label_et_input col-lg-6'>
                                <label>Adresse</label>
                                <input onChange={this.HandleAdresseUser} type="text" className='form-control' />
                            </div>

                            <div className='form-group label_et_input col-lg-6'>
                                <label>Ville</label>
                                <input onChange={this.HandleVilleUser} type="text" className='form-control' />
                            </div>

                        </div>

                        <div className='row'>
                            <div className='form-group label_et_input col-lg-6'>
                                <label>Pays</label>

                                <select onChange={this.HandlePays_user} className='form-control'>
                                    <option value="">--choisir--</option>
                                    <option value="Benin">Benin</option>
                                    <option value="Burkina Faso ">Burkina Faso </option>
                                    <option value="Cap-Vert">Cap-Vert</option>
                                    <option value="Côte d'Ivoire"> Côte d'Ivoire</option>
                                    <option value="Gambie">Gambie </option>
                                    <option value="Ghana">Ghana </option>
                                    <option value="Guinée ">Guinée </option>
                                    <option value="Guinée-Bissau">Guinée-Bissau</option>
                                    <option value="Liberia">Liberia</option>
                                    <option value="Mali ">Mali </option>
                                    <option value="Mauritanie">Mauritanie</option>
                                    <option value="Niger ">Niger </option>
                                    <option value=" Nigeria"> Nigeria</option>
                                    <option value="Sénégal ">Sénégal </option>
                                    <option value="Sierra Leone">Sierra Leone</option>
                                    <option value="Togo">Togo</option>

                                </select>
                            </div>

                            <div className='form-group label_et_input col-lg-6'>
                                <label>Profession</label><i className='etoile_rouge'>*</i>
                                <select onChange={this.HandleProfession} type="text" className='form-control'>
                                    <option value=''>--choisir--</option>
                                    <option>Etudiant</option>
                                    <option>Chercheur</option>
                                    <option>Enseignant</option>
                                    <option>Autre</option>
                                </select>
                                <i className='texte_champs_vide'>{errors.professionerror}</i>
                            </div>
                        </div>



                        <div className='row'>
                            <div className='form-group label_et_input col-lg-6'>
                                <label>Numéro de Téléphone </label><i className='etoile_rouge'>*</i>
                                <input onChange={this.HandleTelephone1} className='form-control' />
                                <i className='texte_champs_vide'>{errors.telephonerror}</i>
                            </div>

                            <div className='form-group label_et_input col-lg-6'>
                                <label>Deuxième Numéro(facultatif)</label>
                                <input onChange={this.HandleTelephone2} type="text" className='form-control' />
                                <i className='texte_champs_vide'>{errors.telephonerror}</i>
                            </div>


                        </div>
                    </div>
                </div>
                <br />

                {/*-------------------------------Agissant pour------------------------------------*/}
                <div className='container shadow  card '>
                    <div className='type_user container '>
                        <h3 className='titre '>3-Type d'utilisateur </h3>
                        <div className='row'>
                            <div className='form-group label_et_input col-lg-4'>
                                <input onChange={this.Handle_Personne_Physique} type="radio" value="physique" name='type_user' className='label_et_input' /><label className='sexe'>Personne physique</label>
                            </div>

                            <div className='form-group label_et_input col-lg-4'>
                                <input onChange={this.Handle_Personne_Morale} type="radio" value="morale" name='type_user' className='label_et_input' /><label className='sexe'>Personne morale</label>
                            </div>
                        </div>
                    </div>

                    {/*---------------------personne morale ----------------------*/}
                    <div id='personne_morale' className=' personne_morale invisible'>
                        <div className='row'>
                            <div className='form-group label_et_input col-lg-6'>
                                <label>Nom de la structure </label><i className='etoile_rouge'>*</i>
                                <input onChange={this.HandleNom_structure} type="text" className='form-control' />
                                <i className='texte_champs_vide'>{errors.nomstructureerror}</i>
                            </div>

                            <div className='form-group label_et_input col-lg-6'>
                                <label>Type</label>
                                <select onChange={this.HandleTypeSociete} type="text" className='form-control'>
                                    <option value="">--choisir--</option>
                                    <option>Ministère</option>
                                    <option>Direction nationale</option>
                                    <option>Institution/Coopération</option>
                                    <option>Fondation</option>
                                    <option>Cabinet d'Expertise privé</option>
                                    <option>Institution privée</option>
                                    <option>Association </option>
                                    <option>Syndicat</option>
                                    <option>Coopérative </option>
                                    <option>Mutuelle </option>
                                    <option>Entreprise privée </option>
                                    <option>Entreprise sociale </option>
                                    <option>Université </option>
                                    <option>Structure de formation </option>
                                    <option>Organisme Nations Unies </option>
                                    <option>Institution/Organisme de conseil </option>
                                    <option>ONG </option>
                                    <option>Structure de formation </option>
                                    <option>Institution/Organisme  parapublique</option>
                                    <option>Think Tank</option>
                                    <option>Organisation patronale</option>
                                    <option>Autre</option>



                                </select>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='form-group label_et_input col-lg-6'>
                                <label>Courriel </label><i className='etoile_rouge'>*</i>
                                <input onChange={this.HandleCourriel} type="text" className='form-control' />
                                <i className='texte_champs_vide'>{errors.courrielerror}</i>
                            </div>

                            <div className='form-group label_et_input col-lg-6'>
                                <label>Téléphone </label><i className='etoile_rouge'>*</i>
                                <input onChange={this.HandleTelephone_Societe} type="text" className='form-control' />
                                <i className='texte_champs_vide'>{errors.telephonerror}</i>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='form-group label_et_input col-lg-6'>
                                <label>Adresse</label>
                                <input onChange={this.HandleAdresse_structure} type="text" className='form-control' />
                            </div>

                            <div className='form-group label_et_input col-lg-6'>
                                <label>Boite Postale</label>
                                <input onChange={this.HandleBoite_postale} type="text" className='form-control' />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='form-group label_et_input col-lg-6'>
                                <label>Ville</label>
                                <input onChange={this.HandleVille} type="text" className='form-control' />
                            </div>

                            <div className='form-group label_et_input col-lg-6'>
                                <label>Pays</label>
                                <select onChange={this.HandlePays_structure} type="text" className='form-control' >
                                    <option value="">--choisir--</option>
                                    <option value="Benin">Benin</option>
                                    <option value="Burkina Faso ">Burkina Faso </option>
                                    <option value="Cap-Vert">Cap-Vert</option>
                                    <option value="Côte d'Ivoire"> Côte d'Ivoire</option>
                                    <option value="Gambie">Gambie </option>
                                    <option value="Ghana">Ghana </option>
                                    <option value="Guinée ">Guinée </option>
                                    <option value="Guinée-Bissau">Guinée-Bissau</option>
                                    <option value="Liberia">Liberia</option>
                                    <option value="Mali ">Mali </option>
                                    <option value="Mauritanie">Mauritanie</option>
                                    <option value="Niger ">Niger </option>
                                    <option value=" Nigeria"> Nigeria</option>
                                    <option value="Sénégal ">Sénégal </option>
                                    <option value="Sierra Leone">Sierra Leone</option>
                                    <option value="Togo">Togo</option>


                                </select>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='form-group label_et_input col-lg-6'>
                                <label>Présentation de la structure  </label>
                                <textarea onChange={this.HandleDescription} type="text" className='py-3 form-control' />
                            </div>


                            <div className='form-group label_et_input col-lg-6'>
                                <label>Site Web</label>
                                <input onChange={this.HandleSite_web} type="text" className='form-control' />
                            </div>

                        </div>
                    </div>
                </div>
                <br />

                {/*------------------------------Validation de la licence -------------------------*/}
                <div className='container card  shadow '>
                    <div className='validation_licence container '>
                        <h3 className='titre '>4-Validation des conditions d'utilisation </h3>
                        <div className='form-group label_et_input col-lg-12'>
                            <input onChange={this.HandleValidation_condition} className='checkbox' type="checkbox" />
                            <span className=''>En m'inscrivant, je reconnais avoir lu et accepté les
                                <a href='#'> Conditions Générales d'Utilisation </a>des données
                            </span>
                            <br />
                            <i className='texte_champs_vide'>{errors.validationerror}</i>
                        </div>
                    </div>
                </div>

                <br />
                <br />

                {/*-------------------------------------------------------*/}

                <div className='container py-2  invisible tous_les_champs'>Veuillez remplir tous les champs avant de continuer s'il vous plait!</div>
                <br />
                <div className='d-flex  btn_div justify-content-left'>
                    <button onClick={this.saveUser} className=' bouton col-lg-12 btn_inscriree '>Terminer mon inscription</button>

                </div>

                <br />
                <br />
                <br />
            </div>

        );
    }
}

export default withRouter(SignUp);