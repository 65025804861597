import '../../css/Avous/AvousCss.css'
import mains from '../../assets/mains.png'
import ModalInfo from './ModalInfo';
import { Component, useState } from "react";
import { withRouter } from 'react-router-dom';
import UserService from '../../service/UserService';
import ModalChoix from './ModalChoix';

class Avous extends Component {

    constructor(props) {
        super(props)
        this.state = {
            addModalShow: false,
            modalChoixShow: false,
            idUser: parseInt(localStorage.getItem('idUser')),
            user: null
        }
    }
    componentDidMount() {
        UserService.getUserById(this.state.idUser).then((res) => {
            this.setState({
                user: res.data
            })
        }
        );
    }

    openModalInfo = () => {
        if (this.state.user == null) {
            this.setState({
                addModalShow: true
            });

        }
        else {
            this.setState({
                modalChoixShow: true
            });
        }
    }

    closeModel = () => {
        this.setState({
            addModalShow: false
        });

    }



    closeModelChoix = () => {
        this.setState({
            modalChoixShow: false
        });

    }


    inscrire = () => {
        this.props.history.push('/signUp/-1')
    }

    render() {
        const { addModalShow, modalChoixShow } = this.state


        return (
            <div className='contain_tout '>
                <ModalInfo
                    show={addModalShow}
                    onHide={this.state.closeModel}
                    closeModel={this.closeModel}
                    inscrire={this.inscrire}

                />

                <ModalChoix
                    show={modalChoixShow}
                    onHide={this.state.closeModelChoix}
                    closeModelChoix={this.closeModelChoix}

                />
                <h1  className='avous'>ESPACE DEMANDES</h1> <br />
                <div className='row  col-lg-12'>


                    <div className='texte_  card-body col-lg-6'>

                        <p className='p_demande'>A travers cette rubrique, vous avez la possibilté de faire des demandes sur les indicateurs de votre choix  . <br />
                            Si vous n'avez pas de compte, inscrivez-vous et faites votre demande !
                        </p>
                        <a className='btn div_demande ' >
                            <span onClick={this.openModalInfo} className=' btn_demande' >Faire une demande</span>
                        </a>
                    </div>

                    <div className='row image_ col-lg-6'>
                        <img className='col-lg-12' src={mains} />

                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Avous);