import React, { Component } from 'react';
import UserService from '../../service/UserService';
import { withRouter } from 'react-router-dom';
import DemandeService from '../../service/DemandeService';

class Aside extends Component {
   state = {
      idUser: parseInt(localStorage.getItem('idUser')),
      user: '',
      active_menu: {
         backgroundColor: '#044b9e',
         paddingTop: '12px',


      },
      inactive_menu: {},
      nbreNotif: 0

   }

   componentDidMount() {
      console.log(this.state.idUser.toString())
      DemandeService.getNotification("repondu", 'personne', this.state.idUser.toString()).then((res) => {
         this.setState({
            nbreNotif: res.data
         })
      });

      UserService.getUserById(this.state.idUser).then((res) => {
         this.setState({
            user: res.data
         })

      }
      );
   }
   render() {
      const { idUser, active_menu, inactive_menu, nbreNotif } = this.state

      return (
         <aside className='container  col-lg-2  aside_menu'>

            <div className=' span_menu'>
               <i className='bi bi-list'></i><span className='menu_'>Menu</span>
            </div>

            <hr className='separateur' />


            {/*visiteur et user Lartes*/}
            {(this.state.user.profil == "visiteur" || this.state.user.profil == "user_lartes") &&
               (<a style={this.props.active == 0 ? active_menu : inactive_menu} className='row item_menu_user_' href={`/user/${idUser}`}><bi className="bi  items  bi-person-circle">
               </bi><span className='item'>Mon profil</span></a>)}

            {(this.state.user.profil == "visiteur" || this.state.user.profil == "user_lartes") &&
               (<a style={this.props.active == 3 ? active_menu : inactive_menu} className='row item_menu_user_' href={`/notifications/${idUser}`}><bi className="bi  items  bi-bell-fill">
               </bi> <span className='item'>Notifications</span>{(nbreNotif != 0) && (<bi className="nbre_notif">{nbreNotif}</bi>)}</a>)}

            {(this.state.user.profil == "visiteur" || this.state.user.profil == "user_lartes") &&
               (<a style={this.props.active == 1 ? active_menu : inactive_menu} className='row item_menu_user_' href={`/listeDemande/${idUser}`}><bi className="bi  items  bi-arrow-up-right-circle">
               </bi><span className='item'>Mes Demandes</span></a>)}

            {(this.state.user.profil == "visiteur" || this.state.user.profil == "user_lartes") &&
               (<a style={this.props.active == 2 ? active_menu : inactive_menu} className='row item_menu_user_' href={`/listeQuestion/${idUser}`}><bi className="bi  items  bi-question-circle">
               </bi><span className='item'>Mes Questions</span></a>)}

            {/*agent et admin*/}
            {(this.state.user.profil == "agent" || this.state.user.profil == "admin") &&
               (<a style={this.props.active == 0 ? active_menu : inactive_menu} className='row item_menu_user_' href={`/user/${idUser}`}><bi className="bi  items  bi-graph-up">
               </bi><span className='item'>Inventaire</span></a>)}

            {(this.state.user.profil == "agent" || this.state.user.profil == "admin") &&
               (<a style={this.props.active == 1 ? active_menu : inactive_menu} className='row item_menu_user_' href={`/listeDemande/-1`}><bi className="bi  items  bi-arrow-up-right-circle">
               </bi><span className='item'>Demandes</span></a>)}

            {(this.state.user.profil == "agent" || this.state.user.profil == "admin") &&
               (<a style={this.props.active == 2 ? active_menu : inactive_menu} className='row item_menu_user_ ' href={`/listeQuestion/-1`}><bi className="bi  items  bi-question-circle">
               </bi><span className='item'>Questions</span></a>)}

            {/*admin*/}
            



            {(this.state.user.profil == "admin") &&
               (
                  <nav style={this.props.active == 5 ? active_menu : inactive_menu} id="navbar" class="navbar  ">
                     <ul id='ulnav'>

                        <li className='menu_deroull  '><a className="row menu_deroulant" href="/#"><bi className="bi  items bi-plus-circle-fill">
                        </bi><span className='item'>Ajout<bi class=" bi bi_togle dropdown-toggle" ></bi></span></a>
                           <ul className='sousmenu'>
                              <li><a  href={`/addBd/-1`}>Base de données</a></li>
                              <li><a   href={`/useradmin/-1`}>Utilisateur</a></li>
                              
                           </ul>
                        </li>
                     </ul></nav>)}

           




         </aside>
      );
   }
}

export default withRouter(Aside);