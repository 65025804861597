import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import BDService from '../../service/BDService';
import EnqueteService from '../../service/EnqueteService';
import EntiteService from '../../service/EntiteService';

class Datas extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nom_entite: null,
            nom_bd: null
        }
    }
    //methode pour enregistrer le contenu du formulaire en tant que table dans la base
    send_data(event) {
        event.preventDefault()
        var myForm = document.getElementById(event.target.id);
        const formData = new FormData(myForm);
        EnqueteService.addEnquete(formData);
    }


    componentDidMount() {
        EntiteService.getEntiteById(this.props.entite).then((res) => {
            this.setState({
                nom_entite: res.data.nom
            })
            BDService.getBDById(res.data.idBD).then((res2) => {
                this.setState({
                    nom_bd: res2.data.nom
                })
            })
        });
    }
    render() {
        const { nom_bd, nom_entite } = this.state
        return (
            <div className=' '>
                <div className='btns-import  '>
                    <div className='form-group '>
                        <label for='my-file' className='label_indicateurs'>Importer l'enquete</label>
                        <form key={this.props.entite} encType="multipart/form-data" id={"entite_" + this.props.entite} onSubmit={this.send_data}>
                            <input
                                key={this.props.entite}
                                type="file"
                                name='les_fichiers'
                                required
                            />
                            <input
                                key={this.props.entite}
                                type="hidden"
                                name='tablename'
                                //defaultValue={this.props.entite}
                                defaultValue={"enquete" + "-" + this.props.entite + "-" + nom_entite + "-" + nom_bd}
                            />
                            <input type="submit" value="Ajouter" />
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(Datas);