import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import DemandeService from '../../service/DemandeService';
import EntiteService from '../../service/EntiteService';
import IndicateurService from '../../service/IndicateurService';
import NavBarComponent from '../header/NavBarComponent';
import DmdModalConfirm from './DmdModalConfirm';
import ModalChoix from './ModalChoix';

class SendDemande extends Component {
    constructor(props) {
        super(props)
        this.state = {
            entite_bd: this.props.match.params.id,
            idUser: parseInt(localStorage.getItem('idUser')),
            nomBd: localStorage.getItem('nomBd'),
            nom_bd: null,
            idEntite: null,
            indicateurs: [],
            selectedIndicators: [],
            oneIndicatorSelectecd: '',
            modalChoixShow: false,
            message:null,
            errors:{},
            addModalShow: false
            


        }
    }

    componentDidMount() {

        this.state.idEntite = parseInt(this.state.entite_bd.split("_-")[0])
        this.state.nom_bd = this.state.entite_bd.split("_-")[1]
        this.state.message = this.state.entite_bd.split("_-")[2]

        IndicateurService.getIndicateursByEntite(this.state.idEntite).then((res) => {
            this.setState({
                indicateurs: res.data
            })
        });

    }


    handleSelection = (e) => {
        if (e.target.checked) {
            this.state.selectedIndicators.push(e.target.value)
        } else {
            var index = this.state.selectedIndicators.indexOf(e.target.value);
            if (index !== -1) {
                this.state.selectedIndicators.splice(index, 1);
            }
        }
    }

    formValidation=()=>{

        const{ selectedIndicators}=this.state;
        let isValid=true;
        const errors={}
        if( selectedIndicators.length==0){
            //errors.tabLength="Le nom de la thématique ne doit pas être vide ";
            isValid=false;
        }
        this.setState({errors});
        return isValid;
    }
    closeModelChoix = () => {
        this.setState({
            modalChoixShow: false
        });

    }

    openModal = () => {
        this.setState({
            modalChoixShow: true
        });
    }

    saveDemande = (event) => {
        event.preventDefault();
        const isValid=this.formValidation();
        if(isValid){
        let demande = {
            entite: this.state.entite_bd,
            dateEnvoi: new Date(),
            type: "requete",
            user: this.state.idUser,
            indicateurs: this.state.selectedIndicators,
            etat: "non repondu",
            message:this.state.message,
            viewbyUser:'personne',
            viewbyAgent:'personne'

        }

        DemandeService.addDemande(demande).then((res) => {
            let succes = document.getElementById("succes");
            succes.classList.remove("invisible");
            setTimeout(() => {
                succes.classList.add("invisible");
                this.props.history.goBack();
            }, 2000);
            

        });
    }else{
        this.setState({
            addModalShow: true
        });
    }
    }
    non=()=>{
        this.setState({
            addModalShow: false
        });
    }
    

    render() {
        const{addModalShow}=this.state;
        return (
            <div className='row card-body '>
                <DmdModalConfirm
                    show={addModalShow}
                    non={this.non}
                />
                <div className='col-lg-7  etape2ettext container'>

                          <div id="succes" className='succes  invisible'>       
                            <div className='message_succes'>
                                Votre demande a été bien envoyé . Nous vous répondrons très bientôt
                            </div>
                        </div>  

                    <h1 className='etape2'>Étape 2</h1>
                    <div className='text_demande'>Les volets des enquêtes sont représentés par 
                        plusieurs indicateurs. De ce fait, pour finaliser sa demande, vous devez 
                        faire un choix sur ces indicateurs .
                        Pour ce volet que vous venez de choisir, nous avons ici ses indicateurs .
                        Cliquer sur les cases à cocher afin de faire vos choix !
                        <br/><b>NB : </b>Il vous est permis d'envoyer d'autant de demande que vous souhaitez. N'hesitez surtout
                        pas à retourner au niveau de votre espace demande pour en refaire d'autres .
                        <br/> 
                    </div>
                </div>
            <div className='card-body container-fluid shadow col-lg-4' >
                <ModalChoix
                    show={this.state.modalChoixShow}
                    onHide={this.state.closeModelChoix}
                    closeModelChoix={this.closeModelChoix}

                />
                <div>
                     {/*
                    <a className='btn div_envoi_ '>
                        <span onClick={this.openModal} className=' btn_envoi_'><bi className="bi bi-arrow-left"></bi><bi className="bi bi-dash"></bi>Retour à l'étape 1</span>
                    </a>*/}
                </div>
                <div className='  container col-md-12' >

                    {

                        this.state.indicateurs.map(listeindicateurs =>

                            <div className='col-lg-12 container  '>
                                <div className=' row col-lg-12 divIndicateur'>
                                    <input onChange={this.handleSelection} className='col-lg-2 card-body unIndicateur ' value={listeindicateurs.libelle} type="checkbox" />
                                    <span className='card-body libelle col-lg-10'>{listeindicateurs.libelle}</span>
                                </div>
                            </div>
                        )

                    }
                </div>
               
                <a className='btn div_demande' >
                    <span onClick={this.saveDemande} className=' btn_demande' >Envoyer </span>
                </a>
            </div>
            </div>
        );
    }
}

export default withRouter(SendDemande);