import React, { Component, Fragment } from 'react';
import FooterCss from '../../css/footer/FooterCss.css';


class FooterComponent extends Component {
  //constructeur 
  constructor(props) {
    super(props);
    this.state = {
      is_visible: false
    }
  }
  //au chargement de la page 
  componentDidMount() {
    var scrollComponent = this;
    document.addEventListener("scroll", function (e) {
      scrollComponent.toggleVisibility();

    });
  }
  //affichage ou non du bouton back-to-top  
  toggleVisibility() {
    if (window.pageYOffset > 300) {
      this.setState({
        is_visible: true
      });
    } else {
      this.setState({
        is_visible: false
      });
    }
  }

  render() {
    const { is_visible } = this.state;
    return (

      <Fragment>


        <footer id="footer">
          <div class="footer-top">
            <div class="container">
              <div class="row">

                <div class="col-lg-5 col-md-6">
                  <div class="footer-info">
                    <h3>Lartes-IFAN<span></span></h3>
                    <p>
                     <strong> Adresse :</strong> Université Cheikh Anta DIOP - Camp Jérémy <br />
                      <strong>Téléphone : </strong>  +221 33 825 96 14<br />
                      <strong>Email : </strong> infos@lartes-ifan.org<br />
                      <strong>Site Web : </strong><a target="_blank"  class='lien_footer' href="https://lartes-ifan.org/">Lartes-IFAN</a><br />

                    </p>

                  </div>
                </div>

                <div class="col-lg-3 col-md-6 footer-links">
                  <ul>
                    <h3>Navigation</h3>
                    <li ><i class="bi  bi-chevron-right"></i> <a href="#">Accueil</a></li>
                    <li><i class="bi bi-chevron-right"></i> <a href="/aide">Aide sur l'Observatoire</a></li>
                    <li><i class="bi bi-chevron-right"></i> <a href="#statistique">Statistiques</a></li>
                    <li><i class="bi bi-chevron-right"></i> <a href="#actualite">Actualités</a></li>
                    <li><i class="bi bi-chevron-right"></i> <a href="#demande">Demandes</a></li>
                    <li><i class="bi bi-chevron-right"></i> <a href="#contact">Nous contacter</a></li>
                  </ul>
                </div>

                <div class="col-lg-3 social-links mt-3">
                  <h3>Réseaux sociaux </h3>
                  <a target="_blank"   href="https://twitter.com/Lartes_ifan" class="twitter"><i class="bi bi-twitter"></i></a>
                  <a target="_blank"  href="https://www.facebook.com/LARTESIFAN" class="facebook"><i class="bi bi-facebook"></i></a>
                  <a target="_blank"  href="https://www.linkedin.com/company/laboratoire-de-recherche-sur-les-transformations-economiques-et-sociales-lartes-ifan/" class="linkedin"><i class="bi bi-linkedin"></i></a>
                </div>

              </div>
            </div>
          </div>

          <div class="container">
            <div class="copyright">
              &copy; Copyright <strong><span className=''>lartes</span></strong>. All Rights Reserved
            </div>
            <div class="credits">
              Fait par <a target="_blank"  class='lien_footer' href="https://ndeyashuportfolio.herokuapp.com/">Ndeye Astou THIAM</a>
            </div>
          </div>
        </footer>

        {/*Bouton back to top*/}
        <div>
          {is_visible && (
            <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
          )}
        </div>
      </Fragment>

    );
  }
}

export default FooterComponent;