import axios from "axios"

const url_indicateur = "http://localhost:9090/ENQUETE-SERVICE/indicateur"
//const url_thematique="http://192.168.0.53:8080/indicateur"
class IndicateurService {
  getNbreIndByEntite(entite) {
    return axios.get(url_indicateur + "/count" + "/" + entite);
  }

  getIndicateursByEntite(entite) {
    return axios.get(url_indicateur + "/" + entite)
  }

  getAllIndicateur() {
    return axios.get(url_indicateur + '/all');
  }

  addIndicateur(ind) {
    return axios.post(url_indicateur + "/add", ind);
  }


  getIndicateurById(id) {
    return axios.get(url_indicateur + "/search" + "/" + id);
  }

  updateindicateur(indicateurId, indicateur) {
    return axios.put(url_indicateur + '/update/' + indicateurId, indicateur)
  }

}
export default new IndicateurService()