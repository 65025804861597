import React, { Component } from 'react';
import EntiteService from '../../service/EntiteService';
import IndicateurService from '../../service/IndicateurService';
import UserService from '../../service/UserService';
import Datas from './Datas';
import Papa from './Papa';

class Indicateur extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idUser: parseInt(localStorage.getItem('idUser')),
      user: '',
      nbreIndicateurs: 0,
      listeIndicateurs: [],
      identite: parseInt(this.props.entite),
    }

  }

  componentDidMount() {
    UserService.getUserById(this.state.idUser).then((res) => {
      this.setState({
        user: res.data
      })

    }
    );

    IndicateurService.getNbreIndByEntite(this.state.identite).then((res) => {
      this.setState({
        nbreIndicateurs: res.data
      })
    });

    IndicateurService.getIndicateursByEntite(this.state.identite).then((res) => {
      this.setState({
        listeIndicateurs: res.data
      })
    });
  }

  Indicateurs = () => {
    
      return<div> <div className='no-data container card-body  justify-content-center'>

        <div className='texte_no-indicateur'>
          <p > </p>
        </div>
{(this.state.nbreIndicateurs==0)&&(
        <div>
          <bi className="bi pie-chart bi-stickies "></bi>
        </div>
)}
        <div className='container'>
          {(this.state.user.profil == "admin") && (<div className=''><Papa entite={this.state.identite} /></div>)}
          <hr/>
          {/*{(this.state.user.profil == "admin") && (<div className=''><Datas entite={this.state.identite} /></div>)}*/}
        </div>


      </div>
    <div className=' container card-body  justify-content-center' >
        <div className='with-data texte_no-indicateur'>
          <span className='nbre_bd_thematiq'> {this.state.nbreIndicateurs}  </span>indicateur(s)
        </div>

        {

          this.state.listeIndicateurs.map(indicateurs =>
            <div className='indicateur_ '>
                <div className='col-lg-6'> <bi className="bi bi-dash"></bi> <span>{indicateurs.libelle}</span></div>
            </div>

          )
        }


      </div>
    
    </div>
  }
  render() {
    return (
      <div>
        {this.Indicateurs()}
      </div>
    );
  }
}

export default Indicateur;