import React, { Component } from 'react';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import BDService from '../../service/BDService';
import EntiteService from '../../service/EntiteService';
import ConvertDate from '../Avous/ConvertDate';
import IndicateursDetails from '../Avous/IndicateursDetails';
import MenuBd from './MenuBd';

class DetailsBD extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            la_bd: '',
            nbreEntites: 0

        }
    }

    componentDidMount() {
        const { id } = this.state
        EntiteService.getNbreEntiteByBD(this.state.id).then((res) => {
            this.setState({
                nbreEntites: res.data
            })
        });
        BDService.getBDById(id).then((res) => {
            this.setState({
                la_bd: res.data
            })
        });
    }

    render() {
        const { la_bd } = this.state
        return (
            <div>
                <MenuBd active={1}bd={this.state.id} nbreEntites={this.state.nbreEntites} />
                <div className='detail  rowcontainer'>

                    <div className='descrption card-body container'>
                        <div className='card card-body'>
                            <thead className='entete' >Description</thead>
                            <p className='la_description'>{la_bd.description}</p>
                        </div>
                    </div>
                    <div className=' col-lg-12 div_top_detail '>
                    <div className='row d-flex justify-content-around'>
                        <div className='col-lg-8 row d-flex justify-content-around '>
                           {(la_bd.acces)&&(<div><span  className='text_fichier'>Accès<bi className='bi'></bi></span><span className='detail_bd'>Public</span></div>)} 
                           {(!la_bd.acces)&&(<div><span className='text_fichier'>Accès</span><span className='detail_bd'>Privé</span></div>)} 
                           <div className='row col-lg-6 detail_date d-flex justify-content-around'><bi className="bi bi-calendar3"></bi>Date  de créaction <bi className="bi bi-caret-right-fill"></bi> <ConvertDate key={la_bd.idBD} date={new Date(la_bd.dateImportation)} /></div>
                        </div>
                        <div className='col-lg-3 mots_cles descrption card-body container'>
                        <h3 className='entete mots' >Mots Clés</h3> 
                        <div className='shadow card-body '>
                         
                        {

                     la_bd.mots_cles?.map(liste =><div className='nom_thematique'>{liste.nom}</div>)
                            
                        }
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(DetailsBD);