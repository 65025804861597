import React, { Component } from 'react';

class TopBar extends Component {
    render() {
        return (
            <div>

                {/* ======= Top Bar ======= */}

                <section id="topbar" class="d-flex align-items-center">
                    <div class="container d-flex justify-content-center justify-content-md-between">
                        <div class="contact-info d-flex align-items-center">
                        <i class="bi bi-geo-alt-fill "></i>Université Cheikh Anta DIOP - Camp Jérémy
                            <i class="bi bi-envelope-fill phone-icon phone-icon"></i>infos@lartes-ifan.org
                            <i class="bi bi-telephone-fill phone-icon phone-icon"></i> Bureau : +221 33 825 96 14
                         

                        </div>
                        <div class="social-links d-none d-md-block">

                            <a target="_blank"  href="https://twitter.com/Lartes_ifan" class="twitter"><i class="bi bi-twitter"></i></a>
                            <a target="_blank"  href="https://www.facebook.com/LARTESIFAN" class="facebook"><i class="bi bi-facebook"></i></a>
                            <a target="_blank"  href="https://www.linkedin.com/company/laboratoire-de-recherche-sur-les-transformations-economiques-et-sociales-lartes-ifan/" class="linkedin"><i class="bi bi-linkedin"></i></a>
                        </div>
                    </div>
                </section>
                {/*========== End Top Bar ==========*/}
            </div>
        );
    }
}

export default TopBar;


