import axios from 'axios';
import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import DemandeService from '../../service/DemandeService';
import UserService from '../../service/UserService';
import Aside from '../espace_user/Aside';
import NavBarComponent from '../header/NavBarComponent';
import ConvertDate from './ConvertDate';
import IndicateursDetails from './IndicateursDetails';
import RetrieveBD from './RetrieveBD';
import RetrieveEntite from './RetrieveEntite';
import RetrieveUser from './RetrieveUser';

class DetailsDemande extends Component {
    constructor(props) {
        super(props)
        this.state = {
            demande: this.props.match.params.id,
            idUser: localStorage.getItem('idUser'),
            demandedetail: '',
            user: ''
        }
        this.handleDowloadFile = this.handleDowloadFile.bind(this);
    }
    async componentDidMount() {
        const { demande } = this.state
        UserService.getUserById(this.state.idUser).then((res) => {
            this.setState({
                user: res.data
            })

        }
        );
        DemandeService.getDemandeById(demande).then((res) => {
            this.setState({
                demandedetail: res.data
            })
        });
    }
    handleDowloadFile(filename) {
        //const fd = new FormData();
        //fd.append('path',`${filename}`);
        axios.post(`http://localhost/download.php?path=${filename}`
        ).then(res => {
            console.log(res);
        }
        );

    }

    render() {
        const { idUser, demandedetail } = this.state
        return (
            <div>
                <NavBarComponent />
                <div className='row col-lg-12'>

                    <Aside active={1} />
                    <div className='col-lg-10 container-fluid details'>
                        {(this.state.user.profil == "visiteur") && (<a href={`/listeDemande/${idUser}`} className='  div_demande_ ' >
                            <span onClick={this.openModalDemande} className='bg-danger btn ' > <bi className="bi plus bi-x"></bi></span>
                        </a>)}

                        {(this.state.user.profil == "agent" || this.state.user.profil == "admin") && (<a href={`/listeDemande/-1`} className='  div_demande_ ' >
                            <span onClick={this.openModalDemande} className='bg-danger btn ' > <bi className="bi plus bi-x"></bi></span>
                        </a>)}


                        <div className='container-fluid shadow card-body card'>
                            <div className='card-body'>
                                <div className='card-body'>
                                    <div className='row card-body d-flex justify-content-between'>
                                        <div className='row detail_date d-flex justify-content-around'><bi className="bi bi-calendar3"></bi>Date  de l'envoi <bi className="bi bi-caret-right-fill"></bi> <ConvertDate key={demandedetail.idDemande} date={new Date(this.state.demandedetail.dateEnvoi)} /></div>
                                        {(demandedetail.dateReponse !== null) && (<div className='row  d-flex justify-content-around detail_date'><bi className="bi bi-calendar3"></bi>Date de la réponse <bi className="bi bi-caret-right-fill"></bi> <ConvertDate key={demandedetail.idDemande} date={new Date(this.state.demandedetail.dateReponse)} /> </div>)}
                                    </div>
                                    {(demandedetail.entite !== null) && (<div className='row detail_bd'><span className='text_bd'>Base de données </span><RetrieveBD key={demandedetail.idDemande} entite_bd={demandedetail.entite} /></div>)}
                                    {(demandedetail.entite !== null) && (<div className='row detail_entite'><span className='text_entite'>Volet </span><RetrieveEntite key={demandedetail.idDemande} entite_bd={demandedetail.entite} /></div>)}

                                    <hr />
                                    {(demandedetail.objet !== null) && (<div className='row detail_bd'><span className='text_bd'>Objet</span><div key={demandedetail.idDemande} />{demandedetail.objet} </div>)}
                                    <br /> {(demandedetail.message !== null) && (<div className='row detail_msg d-flex justify-content-between'><span className='text_msg  '>Message envoyé</span><div key={demandedetail.idDemande} />{demandedetail.message} </div>)}

                                    <hr />
                                    <div className='row  detail_etat'><span className='text_etat'>Etat </span>

                                        {(demandedetail.etat == 'non repondu') && (<td><bi className="bi bi-check2 etat_notreply"></bi></td>)}
                                        {(demandedetail.etat !== 'non repondu') && (<td><bi className="bi bi-check2-all etat"></bi></td>)}
                                    </div>
                                    {(demandedetail.reponse !== null) && (<div className='row detail_msg d-flex justify-content-between'><p className='text_msg '>Réponse</p><div key={demandedetail.idDemande} />{demandedetail.reponse} </div>)}
                                    <hr />
                                    {(demandedetail.fichierReponse !== null) && (<div className='row  detail_fichier'> <span className='text_fichier'>Fichier</span><div key={demandedetail.idDemande} /><a href={"http://localhost/download.php?path=" + demandedetail.fichierReponse}>{demandedetail.fichierReponse} </a></div>)}
                                    <div className='row '>
                                        {(demandedetail.indicateurs !== null) && (<div className='container-fluid col-lg-4 '>
                                            <div className=''><IndicateursDetails key={demandedetail.idDemande} indicateurs={demandedetail.indicateurs} /></div>

                                        </div>)}
                                        <div className='col-lg-8 card-body'>
                                            {(this.state.user.profil == "agent" || this.state.user.profil == "admin") && (
                                                <div className='shadow '>
                                                    <h3 className='h3_indicateur card-body'>Informations sur l'utilisateur</h3>
                                                    <RetrieveUser  key={demandedetail.idDemande} user={demandedetail.user}/>
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(DetailsDemande);