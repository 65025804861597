import React, { Component, Fragment } from 'react';
import NavBarCss from '../../css/header/NavBarCss.css';
import { Link } from 'react-router-dom';
import logo from '../../assets/logoLartes.png';
import observatoire from '../../assets/observatoire.png'
import ThematiqueListeComponent from '../thematiques/ThematiqueListeComponent';
import UserService from '../../service/UserService';
import { Button, Dropdown } from 'bootstrap';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns'

class NavBarComponent extends Component {

  state = {
    idNavbar: '',
    idUser: parseInt(localStorage.getItem('idUser')),
    user: '',
    active : {
      color: '#0d06a1',
      fontSize: '21px',
      borderBottom:'3px solid #F86923'
  
    },
    inactive : {},
    selected:0
  }
  handleClick = (num) => () => {
    this.setState({
      selected:num
    })
    
  };
  //au chargement de la page 
  componentDidMount() {
    //fixer le navbar quand on scrolle 
    var scrollComponent = this;
    document.addEventListener("scroll", function (e) {
      scrollComponent.fixNavBar();

    });

    var scrollComponent = this;
    document.addEventListener("click", function (e) {
      scrollComponent.closeMenu();

    });

    UserService.getUserById(this.state.idUser).then((res) => {
      this.setState({
        user: res.data
      })

    }

    );

  }
  //fixer le navbar au scroll            
  fixNavBar() {

    if (window.pageYOffset > 90) {
      this.setState({
        idNavbar: 'headerscrolled'
      });
    } else {
      this.setState({
        idNavbar: ''
      });
    }
  }

  //gerer la fermeture au niveaux des petits ecrans 
  removeStyleNavBarMobile = () => {
    let navbar = document.getElementById('navbar');
    let iconenav = document.getElementById('iconenav');

    navbar.classList.remove('navbar-mobile');
    navbar.classList.add('navbar');
    iconenav.classList.remove('bi-x');
    iconenav.classList.add('bi-list');

  }
  //gerer l'affichage du menu  au niveaux des petits ecrans 
  addStyleNavBarMobile = () => {
    let navbar = document.getElementById('navbar');
    let iconenav = document.getElementById('iconenav');

    if (navbar.classList.contains('navbar')) {
      navbar.classList.remove('navbar');
      navbar.classList.add('navbar-mobile');

      iconenav.classList.remove('bi-list');
      iconenav.classList.add('bi-x');
    } else {
      navbar.classList.remove('navbar-mobile');
      navbar.classList.add('navbar');

      iconenav.classList.remove('bi-x');
      iconenav.classList.add('bi-list');
    }

  }
  //afficher le menu profil 
  handleProfil() {
    let menu = document.getElementById("menu");
    menu.classList.remove("invisible");
  }
  //fermer le menu utilisateur 
  closeMenu() {
    let menu = document.getElementById("menu");
    const concernedElement = document.querySelector(".menu");

    document.addEventListener("mousedown", (event) => {
      if (!concernedElement.contains(event.target)) {
        menu.classList.add("invisible");

      }
    });
  }
  //Deconnexion
  handleDiscon() {

    localStorage.removeItem('idUser')

  }
  //Afficher le menu utilisateur 
  getMenu() {
    return (
      <menu id="menu" class="menu invisible">

        <ul id='ulnav' className=''>
          <li className=''><a href={`/user/${this.state.idUser}`} class="nav-link scrollto " ><bi className="bi bi-menu bi-person-circle"></bi>Mon espace </a></li>
          <hr />
          <li onClick={this.handleDiscon}><a class="nav-link scrollto" href="/" ><bi className="bi bi-menu bi-box-arrow-right"></bi>Déconnexion</a></li>
        </ul>

      </menu>
    )

  }
  //recuperer l'utilisateur en question 
  getProfilUser() {

    if (this.state.user !== '') {
      if (this.state.user.prenom && this.state.user.nom) {
        return (<div className='profil  '>
          <a class=" " onClick={this.handleProfil} >
            <span class="btn fleche  dropdown-toggle" ><span className='person'><bi className=" bi bi-person-fill"></bi></span>
            </span>
          </a>
          <br />
          <div className='row'>
            <span className='prenom'>{this.state.user.prenom}</span><br />
            <span className='nom_personne'>{this.state.user.nom}</span>
          </div>


          {this.getMenu()}



        </div>)
      } else {
        return (<div className='profil  '>
          <a class=" " onClick={this.handleProfil} >
            <span class="btn fleche  dropdown-toggle" ><span className='person'><bi className=" bi bi-person-fill"></bi></span>
            </span>
          </a>
          <br />
          <div className='row'>
            <span className='prenom'>{this.state.user.profil}</span><br />

          </div>


          {
            this.getMenu()
          }



        </div>)
      }



    }
  }


  render() {
    let { idNavbar ,selected,active,inactive} = this.state
    return (
      <Fragment>
        <head>
          <meta content="width=device-width, initial-scale=1.0" name="viewport" />
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" />
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css" />
        </head>



        {/* ======= Header ======= */}

        <header id={idNavbar} className='header d-flex align-items-center' >
          <div class="container d-flex justify-content-center justify-content-md-between">
            <div className='' >

              <a href="/" class=" logo me-auto "><img src={logo} alt="logo" class="" /></a>
              <a href="/" class=" logo me-auto "><img src={observatoire} alt="logo" class="" /></a>

            </div>
            <nav id="navbar" class="navbar ">
              <ul onClick={this.removeStyleNavBarMobile} id='ulnav' className=''>
                <li style={selected == 0 ? active : inactive} onClick={this.handleClick(0)}><a  class="nav-link scrollto active " href="/">Accueil</a></li>
                <li style={selected == 1 ? active : inactive} onClick={this.handleClick(1)}><a class="nav-link scrollto" href="/#statistique">Statistiques</a></li>
                <li style={selected == 2 ? active : inactive} onClick={this.handleClick(2)}><a class="nav-link scrollto " href="/#actualite">Actualités</a></li>
                <li style={selected == 3 ? active : inactive} onClick={this.handleClick(3)}><a class="nav-link scrollto" href="/#demande">Demandes</a></li>
                <li className='menu_deroul'><a className="nav-link scrollto menu_deroulant" href="/#">A Propos <i class=" dropdown-toggle" ></i></a>
                  <ul className='sousmenu'>
                    <li><a class=""  href="/aide">Aide sur l'Observatoire</a></li>
                    <li><a  class="" target="_blank" href='https://lartes-ifan.org/'>A propos de Lartes-IFAN</a></li>
                  </ul>
                </li>
                {
                  (this.state.user == '') &&
                  (<li ><a class="seconnecter scrollto" href="/login">Se Connecter</a></li>)

                }


              </ul>


              <i id='iconenav' onClick={this.addStyleNavBarMobile} class="bi bi-list mobile-nav-toggle"></i>

            </nav>

            {
              this.getProfilUser()
            }

          </div>
        </header>

        {/*========== End Header ==========*/}
        
      </Fragment>
    );
  }
}

export default NavBarComponent;