import React, { Component } from 'react';
import HeaderCss from '../../css/header/HeaderCss.css'
import NavBarComponent from './NavBarComponent';
import TopBar from './TopBar';
import UnderNavBarCOmponent from './UnderNavBarComponent';

class HeaderComponent extends Component {
    render() {
        return (
            <div id="contenuHeader">
                <TopBar />
                <NavBarComponent />
                {/*<div style={{zIndex:1,height:1200,backgroundColor:"#e2e6ee" }}></div>*/}
                <UnderNavBarCOmponent />


            </div>
        );
    }
}

export default HeaderComponent;