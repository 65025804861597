import React, { Component } from 'react';
import addEntiteCss from '../../css/thematiques/addEntite.css';
import BDService from '../../service/BDService';
import EntiteService from '../../service/EntiteService';
import { Modal, Button } from 'react-bootstrap';

class AddEntite extends Component {
    constructor(props) {
        super(props)
        this.state = {
            nom: "",
            nomBd: localStorage.getItem('nomBd'),
            description: "",
            idBD: "",
            errors:{}
            
        }
    }

    formValidation=()=>{

        const{nom}=this.state;
        let isValid=true;
        const errors={}
        if(nom==""){
            errors.nomerror="Le nom ne doit pas être vide ";
            isValid=false;
        }
        this.setState({errors});
        return isValid;
    }
    handleNomEntite = (e) => {
        this.setState({
            nom: e.target.value
        })
    }

    handleDescriptionEntite = (e) => {
        this.setState({
            description: e.target.value
        })
    }

    saveEntite = (event) => {

        event.preventDefault();
        const isValid=this.formValidation();
        if(isValid){
        BDService.getBDByNom(this.props.bd).then((res) => {
            this.setState({
                idBD: res.data.idBD
            })
            let entite = {
                nom: this.state.nom,
                description: this.state.description,
                idBD: this.state.idBD
            }
    console.log(entite)
            EntiteService.addEntite(entite).then((res) => {
                //this.props.history.push('/#thematique');
                this.setState({
                    description: "",
                    nom: ""
                })
    
            });

        });
       

    }
    }

    
    render() {
        const{errors}=this.state;
        return (

            <div id="Entite" className=' col-lg-6 modal_add_them'>

                <Modal
                    {...this.props}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header >
                        <Modal.Title id="contained-modal-title-vcenter">
                            <span className='indicateurs'>Ajouter un nouveau volet</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className='form-group label_et_input col-lg-11'>
                            <label onClick={()=>console.log("nom  BD : ",this.props.bd)}>Nom du volet </label>
                            <input value={this.state.nom} onChange={this.handleNomEntite} type="text" className='form-control' />
                            <i  className='texte_champs_vides' >{errors.nomerror}</i>
                        </div>

                        <div className='form-group label_et_input col-lg-11'>
                            <label>Description  </label>
                            <textarea value={this.state.description} onChange={this.handleDescriptionEntite} className='py-5 form-control' />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.props.fermerAjoutEntite} >Quitter</Button>
                        <Button variant="primary" onClick={this.saveEntite} >Enregistrer</Button>
                    </Modal.Footer>
                </Modal>
            </div>

        );
    }
}

export default AddEntite;