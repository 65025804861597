import React, { Component } from 'react';
import BDService from '../../service/BDService';
import image from '../../assets/sante.png';
import { withRouter } from 'react-router-dom';
import lapin from '../../assets/lumiere.jpg';
import ThematiqueService from '../../service/ThematiqueService';
import UserService from '../../service/UserService';
import image2 from '../../assets/no-image.png';
import ConvertDate from '../Avous/ConvertDate';

class ListeBDComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            thematique: this.props.match.params.id,
            idUser: parseInt(localStorage.getItem('idUser')),
            listeBD: [],
            user: '',
            nbreBds: 0,
            nom: '',
            description:'',
            icone:''

        }
        this.test = this.test.bind(this);

    }


    test() {

        console.log(this.state.nom);
    }



    componentDidMount() {


        BDService.getBdsByThematique(this.state.thematique).then((res) => {
            this.setState({
                listeBD: res.data
            })
        });

        UserService.getUserById(this.state.idUser).then((res) => {
            this.setState({
                user: res.data
            })

        }
        );

        BDService.getNbreBdsByThematique(this.state.thematique).then((res) => {
            this.setState({
                nbreBds: res.data
            })
        });

        ThematiqueService.getThematiqueById(this.state.thematique).then((res) => {
            this.setState({
                nom: res.data.nom,
                description:res.data.description,
                icone:res.data.icone
            })
        });


    }

    versDetailBD = (id) => {
        this.props.history.push(`/detailbd/${id}`);

    }
UpdateBD=(id)=>{
    this.props.history.push(`/addBd/${id}`);
}
deleteBD=(id)=>{
BDService.deleteDemande(id).then((res1) => {
    this.setState({
        listeBD: this.state.listeBD.filter(bd => bd.idBD !== id),
        
    })

});
}

    render() {
        const { description,icone,thematique, listeBD, thematiqueObject, nom } = this.state
        return (

            <div className='card-body grand-Div shadow  '>
                <div className='liens_retour col-lg-12 d-flex  '>

                    <div className='container'>
                        <div>
                            <a  className='liens_retour' href='#'>
                                <i className='bi bi-chevron-right '></i>
                                Thematiques
                            </a>

                            <a   className='liens_retour' href='#'>
                                <i className='bi bi-chevron-right '></i>
                                {nom}
                            </a>
                         </div>

                        {/*{(this.state.user.profil=="admin")&&(<a href={`/addBd/-1`}><span><bi className="bi  btn-add-thematique  bi-plus"> Ajouter une Base de données</bi></span></a>)}  */}
                    </div>

                </div>


                <div className='contain_liste_bd  card-body '>

                    <div className='thematique_en_cours  '>
                        <div className='row  contain_tout_liste shadow'>
                            <aside className=' card aside col-lg-3 container'>
                                {(icone !== '') && (<img className='image_thematique' src={icone}></img>)}
                                {(icone == '') && (<img className='image_thematique' src={image2}></img>)}
                                <h1 className='thematique'>{nom}</h1>
                                <p className='description_thematiq'>{description}</p>
                                <hr />
                                <h1 className='nbre_bd_thematiq'>{this.state.nbreBds}</h1>
                               {(this.state.nbreBds!=0 && this.state.nbreBds!=1)? <p className='bd_thematiq'>bases de données trouvées  </p>:<p className='bd_thematiq'>base de données trouvées  </p>}
                            </aside>
                            <div className='card container  col-lg-9'>
                                <div className=' petit_header '>
                                   

                                    <div className='col-lg-12 contain_recherche form-group d-flex justify-content-center  row '>
                                        <input className='form-control  card-body  col-lg-10' placeholder='Rechercher des bases... ' />
                                        <a className='btn div_recherche  '>
                                            <span className=' btn_recherche col-lg-2'><i className='bi bi-search '></i></span>
                                        </a>
                                    </div>
                                    {/*
                                    <div className='tri card-body  d-flex align-items-right justify-content-right'>
                                        <span className='py-2 col-sm-5'>Trier par : </span>
                                        <select className='shadow select_trier_par form-control col-sm-6'>
                                            <option>Modifié le </option>
                                            <option>Nom croissant </option>
                                            <option>Nom décroissant</option>
                                            <option>Accés autorisé</option>
                                            <option>Accés non autorisé</option>
                                            <option>Zone de collecte </option>

                                        </select>
                                    </div>
                                    */}


                                </div>
                                <hr />

                                <div className='row card-body liste_bd  d-flex justify-content-between'>

                                    {
                                        listeBD.map(listedb =>

                                        <div id="table" className='col-lg-6 py-5  groupe_bd card  '>
                                        <button className='btns'>
                                        {(this.state.user.profil == "admin") && (<a  onClick={()=>this.UpdateBD(listedb.idBD)}><span><bi className="bi pencil  bi-pencil-fill"></bi></span></a>)}
                                        {(this.state.user.profil == "admin") && (<a  onClick={()=>this.deleteBD(listedb.idBD)} ><span><bi className="bi pencil supprimer  bi-trash-fill"></bi></span></a>)}
                                        </button>

                                        <button  onClick={() => this.versDetailBD(listedb.idBD)} key={listedb.idBD} className='col-lg-12 shadow row uneBD'>
                                           <div>
                                          
                                            {(listedb.acces==false)?<bi className='bi unlock  bi-file-earmark-lock2'></bi>:<bi className='bi lock  bi-unlock-fill'></bi>}      
                                           </div>
                                           <div>
                                            <h3 className='nom_bd'>{listedb.nom}</h3>
                                            <span className='desc_bd '>{listedb.description} </span>
                                            <br/> 
                                            <span className='date'>
                                                <ConvertDate date={listedb.dateImportation}/>
                                            </span>
                                            </div>
                                            <br />
                                        </button>
                                    </div>


                                           

                                        )
                                    }


                                </div>

                            </div>

                        </div>

                    </div>


                </div>
            </div>
        );
    }
}

export default withRouter(ListeBDComponent);