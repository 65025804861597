import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { withRouter } from 'react-router-dom';
import DemandeService from '../../service/DemandeService';
import EntiteService from '../../service/EntiteService';
import UserService from '../../service/UserService';
import Aside from '../espace_user/Aside';
import NavBarComponent from '../header/NavBarComponent';


import HomeUserCSS from '../../css/espace_user/HomeUserCSS.css'
import ConvertDate from '../Avous/ConvertDate';
import RetrieveBD from '../Avous/RetrieveBD';
import RetrieveEntite from '../Avous/RetrieveEntite';
import RetrieveMessage from '../Avous/RetrieveMessage';
import ModalChoix from '../Avous/ModalChoix';
import ModalReply from '../Avous/ModalReply';


class Notifications extends Component {
    state = {
        demandesUser: [],
        utilisateur: this.props.match.params.id,
        allDemandesUser: [],
        idUser: localStorage.getItem('idUser'),
        addModalShow: false,
        addModalReplyhow: false,
        entite: null,
        bd: null,
        entite_bd: null,
        idUser: parseInt(localStorage.getItem('idUser')),
        user: '',
        demande: '',
        dmd:'',
        active_lien : {
            backgroundColor: '#eee',
            fontWeight:'bold'
        },
        inactive_lien : {},
    }
    componentDidMount() {
        const { idUser,  utilisateur } = this.state
        UserService.getUserById(this.state.idUser).then((res) => {
            this.setState({
                user: res.data
            })

        }
        );
        if (utilisateur == -1) {
            DemandeService.getDemandeByType("requete").then((res => {
                this.setState({
                    allDemandesUser: res.data
                })

            }));
        } else {
            DemandeService.getDemandeByUser(idUser).then((res => {
                this.setState({
                    allDemandesUser: res.data
                })

            }));
        }


    }
    

    ViewDetail = (id) => {
        
         DemandeService.getDemandeById(id).then((res) => {
            this.setState({
                dmd: res.data
            })
            let new_demande = {
                message: this.state.dmd.message,
                objet:  this.state.dmd.objet,
                etat:  this.state.dmd.etat,
                type:  this.state.dmd.type,
                user:  this.state.dmd.user,
                indicateurs:  this.state.dmd.indicateurs,
                reponse:  this.state.dmd.reponse,
                dateEnvoi:  this.state.dmd.dateEnvoi,
                dateReponse: this.state.dmd.dateReponse,
                entite:  this.state.dmd.entite,
                fichierReponse:  this.state.dmd.fichierReponse,
                viewbyUser:'user',
                viewbyAgent: this.state.dmd.viewbyAgent
    
            }
            DemandeService.updateDemande(id, new_demande).then((res) => {
                //window.location.reload(true);
    
            })
            this.props.history.push(`/detailDemande/${id}`);
        });
        

    }
   

    render() {
        const{active_lien,inactive_lien}=this.state
       
        return (
            <div>
                <NavBarComponent />
                <div className='row   col-lg-12'>

                    <Aside  active={3}/>
                    <div className=' card-body col-lg-10'>
                       
                        <div className="">
                           <div className='container-fluid col-lg-12'>
                                    {
                                        this.state.allDemandesUser.map(
                                            demandes =>
                                           (demandes.etat=="repondu")&&(     <button  style={demandes.viewbyUser=="personne" ? active_lien : inactive_lien} onClick={()=>this.ViewDetail(demandes.idDemande)} key={demandes.idDemande} className='card-body col-lg-12 d-flex btn_notification '> 
                                                  <div className='col-lg-2'><ConvertDate date={demandes.dateReponse} /></div>
                                                 { (demandes.objet!=null)?(<div className='col-lg-3'>{demandes.objet}</div>):
                                                  <div className='col-lg-3'> <RetrieveBD entite_bd={demandes.entite} /></div>}
                                                  
                                                    <span className='msg_demande'>{demandes.reponse}</span>
                                                    
                                                    
                                                    
                                                </button>
                                                
                                                )
                                        )
                                    }
                           </div>
                        </div>
                    </div>
                    

                </div>
            </div>
        );
    }
}

export default withRouter(Notifications);