import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import '../../css/aide/aideCss.css'

class AideComponent extends Component {
    render() {
        return (
            <div>
                <h1 style={{ height: 600 }}>Aide</h1>
            </div>
        );
    }
}

export default withRouter(AideComponent);