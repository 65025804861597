import axios from "axios"

const url_region = "http://localhost:9090/ENQUETE-SERVICE/region"
//const url_region="http://192.168.0.53:8080/region"
class Region {

    getAllRegion() {
        return axios.get(url_region + '/all')
    }

    getRegionByPays(pays) {
        return axios.get(url_region + "/" + pays)
    }

    addRegion(region) {
        return axios.post(url_region + "/add", region);
    }

    getRegionById(region) {
        return axios.get(url_region + "/search" + "/" + region)
    }
}
export default new Region()