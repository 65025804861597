import React, { Component } from 'react';
import BDService from '../../service/BDService';
import UserService from '../../service/UserService';

class RetrieveUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idUser: parseInt(localStorage.getItem('idUser')),
            nom: "",
            email:""

        }

    }

    async componentDidMount() {
        //let user = this.state.entite_bd.split("_-")[1]
        if (isNaN(parseInt(this.props.user))) {
            this.setState({
                nom: this.props.user.split("_")[0],
                email:this.props.user.split("_")[1]
            })
        } else {
            UserService.getUserById(this.props.user).then((res) => {
                this.setState({
                    nom: res.data.prenom+" "+res.data.nom,
                    email:res.data.username
                })
            });
        }
    }


    render() {
        return (
            <>
                <div className='row card-body'><span className=' text_nom'>Nom </span><span className='valeur_nom'>{this.state.nom}</span></div>
                <div className='row card-body'><span className=' text_nom'>E-mail </span><span className='valeur_nom'>{this.state.email}</span></div>
            </>
        );
    }
}

export default RetrieveUser;