import React, { Component } from 'react';
import EntiteService from '../../service/EntiteService';

class RetrieveEntite extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idUser: parseInt(localStorage.getItem('idUser')),
            entite_bd: this.props.entite_bd,
            entite: null,
            nom_entite: null
        }

    }

    async componentDidMount() {
        EntiteService.getEntiteById(parseInt(this.state.entite_bd.split("_")[0])).then((res) => {
            this.setState({
                nom_entite: res.data.nom
            })
        });
    }


    render() {
        return (
            <td>{this.state.nom_entite}</td>
        );
    }
}

export default RetrieveEntite;