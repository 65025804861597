import React, { Component } from 'react';
import BDService from '../../service/BDService';
import ThematiqueService from '../../service/ThematiqueService';
import UserService from '../../service/UserService';
import ThematiqueListeCss from '../../css/thematiques/ThematiqueListeCss.css'

class MenuBd extends Component {
    constructor(props) {
        super(props)
        this.state = {
            nom_thematique: "",
            id: parseInt(this.props.bd),
            nom_bd: "",
            base_de_donnee: "",
            idUser: parseInt(localStorage.getItem('idUser')),
            user: '',
            active_lien: {
                backgroundColor: '#044b9ess',
                borderTop:'4px solid #044b9e ',
                borderRight:'4px solid #044b9e ',
                borderLeft:'4px solid #044b9e ',
                borderBottom:'none'

             },
             inactive_lien: {},
        }

    }
    componentDidMount() {

       UserService.getUserById(this.state.idUser).then((res) => {
            this.setState({
                user: res.data
            })

        }
        );

        BDService.getBDById(this.state.id).then((res) => {
            this.setState({
                base_de_donnee: res.data
            })
            ThematiqueService.getThematiqueById(this.state.base_de_donnee.thematique).then((res) => {
                this.setState({
                    nom_thematique: res.data.nom
                })
                //console.log(this.state.base_de_donnee.nom)
            });
        });

    }

    render() {
        const{active_lien,inactive_lien}=this.state
        return (
            <div>
                <div className='card-body grand-Div liens_retour col-lg-12 d-flex'>
                    <a onClick={this.retour} className='liens_retour' href='#'>
                        <i className='bi bi-chevron-right '></i>
                        Thematiques
                    </a>
                    <a onClick={this.retour} className='liens_retour' href='#'>
                        <i className='bi bi-chevron-right '></i>
                        <span className='lien_thematique'>{this.state.nom_thematique}</span>
                    </a>
                    <a onClick={this.retour} className='liens_retour' href='#'>
                        <i className='bi bi-chevron-right '></i>
                        <span className='lien_bd'>{this.state.base_de_donnee.nom}</span> 
                    </a>
                </div>
                <div className=' petit_header'>
                    <ul className='ul_petit_header petit_header_bd   row nav-tabs '>
                        <a  style={this.props.active == 1 ? active_lien : inactive_lien}className='active card link-petit-header ' href={`/detailbd/${this.state.id}`}> <li className='  nav-link '><i className='bi bi-file-text icone_'></i>Metadonnées</li></a>  
                        <a  style={this.props.active == 3 ? active_lien : inactive_lien} className='card link-petit-header ' href={`/listeEntites/${this.state.id}`}> <li className=' nav-link '><i className='bi bi-list-ol icone_'></i>Volets (<b className='nbre_bd_thematiq'>{this.props.nbreEntites}</b>) </li></a>
                        <a  style={this.props.active == 2 ? active_lien : inactive_lien} className='card link-petit-header ' href={`/choixEntites/${this.state.id}`}> <li className=' nav-link '><i className='bi bi-bar-chart-line icone_'></i>Explorateur de données</li></a>
                        
                        {/*(this.state.user.profil == "admin")&&( <a className='card link-petit-header ' href="#"> <li className=' nav-link '><i className='bi bi-twitter'></i>Base de  données complète</li></a>)*/}     
                    </ul>
                </div>
            </div>
        );
    }
}

export default MenuBd;