import React, { Component } from 'react';
import BDService from '../../service/BDService';
import EntiteService from '../../service/EntiteService';
import EnqueteService from '../../service/EnqueteService';
import {
    ResponsiveContainer,
    ComposedChart,
    Line,
    Area,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
  } from 'recharts';
import DashMenuIndicateur from './DashMenuIndicateur';
import MenuBd from './MenuBd';


class DashbordPhase3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
             datas:this.props.datas,
             keys:this.props.keys
        }
    }

    componentDidMount() {
        
   console.log(this.state.datas)

            
            
        
      
    }
         


    render() {
const{datas}=this.state

        return (
          <>
          
            <div className='row shadow'>
              <div className='col-lg-9 shadow'>
                 {(datas.length !== 0) && (     
        
        <ResponsiveContainer>
        <ComposedChart
          width={500}
          height={400}
          data={this.props.datas}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="annee" scale="band" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Area type="monotone" dataKey={this.state.indicateurID} fill="#8884d8" stroke="#8884d8" />
          <Bar dataKey={this.state.indicateurID} barSize={20} fill="#413ea0" />
          <Line type="monotone" dataKey={this.state.indicateurName} stroke="#ff7300" />
        </ComposedChart>
      </ResponsiveContainer>
     
            
        )}
         </div>       
         <div className='col-lg-3'>
                   
                    <DashMenuIndicateur entite={this.state.entite}/>
                   
                </div>
            </div>
            </>
             );
    }
}

export default DashbordPhase3;