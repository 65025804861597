import React, { Component } from 'react';
import BDService from '../../service/BDService';

class NbreBds extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idUser: parseInt(localStorage.getItem('idUser')),
            user: '',
            idthematique: parseInt(this.props.thematique),
            nbreBds: 0
        }

    }
    componentDidMount() {
        BDService.getNbreBdsByThematique(this.state.idthematique).then((res) => {
            this.setState({
                nbreBds: res.data
            })
        });
    }
    render() {
        return (

            <div>
                {(this.state.nbreBds === 0 || this.state.nbreBds === 1) && (
                    <div>
                        <span className='nbre_bd'>{this.state.nbreBds}<br /></span>Base de données publiée
                    </div>
                )}

                {!(this.state.nbreBds === 0 || this.state.nbreBds === 1) && (
                    <div>
                        <span className='nbre_bd'>{this.state.nbreBds}<br /></span>Bases de données publiées
                    </div>
                )}
            </div>
        );
    }
}

export default NbreBds;