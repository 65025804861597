import React, { Component } from 'react';
import EntiteService from '../../service/EntiteService';
import NavBarComponent from '../header/NavBarComponent';
import ListeEntites from './ListeEntites';
import MenuBd from './MenuBd';

class DashbordPhase1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listeEntites: [],
            base_de_donnee: this.props.match.params.id,
            nbreEntites:0

        }
    }
    versSesDashbord = (id) => {
        this.props.history.push(`/dashbord2/${id}`);
    }
    componentDidMount() {
        const { base_de_donnee } = this.state
        
        EntiteService.getNbreEntiteByBD(base_de_donnee).then((res) => {
            this.setState({
                nbreEntites: res.data
            })
        });
        EntiteService.getEntiteByBd(base_de_donnee).then((res) => {
            this.setState({
                listeEntites: res.data
            })
        });
    }
    render() {
        const {listeEntites,base_de_donnee,nbreEntites} = this.state
        return (
            <div>
                 <NavBarComponent />
                
                <MenuBd active={2} bd={base_de_donnee} nbreEntites={nbreEntites} />
                <div  className='d-flex justify-content-center card-body row col-md-12' >
                    {
                        listeEntites.map(liste =>
 
                                <div className='col-lg-3 py-5 card-body  groupe  '>    
                                    <button onClick={() => this.versSesDashbord(liste.idEntite)} key={listeEntites.idEntite} className='col-lg-12 une'>
                                        <h3 className='nom_'>{liste.nom}</h3>
                                    </button>
                                </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default DashbordPhase1;