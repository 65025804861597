import React, { Component } from 'react';
import BDService from '../../service/BDService';

class RetrieveBD extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idUser: parseInt(localStorage.getItem('idUser')),
            entite_bd: this.props.entite_bd,
            bd: null

        }

    }

    async componentDidMount() {
        let base = this.state.entite_bd.split("_-")[1]
        if (isNaN(parseInt(base))) {
            this.setState({
                bd: base
            })
        } else {
            BDService.getBDById(base).then((res) => {
                this.setState({
                    bd: res.data.nom
                })
            });
        }
    }


    render() {
        return (
            <h>{this.state.bd}</h>
        );
    }
}

export default RetrieveBD;