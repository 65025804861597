import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';



class ModalConfirm extends Component {
    constructor(props) {
        super(props)
    }
    render() {

        return (
            
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header >
                    <Modal.Title id="contained-modal-title-vcenter">
                        Ajout Reussi
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <p>
                        Vous avez ajouté une nouvelle base de données avec succés  !<br />
                        Si Vous souhaitez ajouter  les volets de l'enquête  dès  maintenant cliquer sur "Continuer"
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={this.props.onHide}>Attendre plus tard</Button>
                    <Button variant="primary" onClick={this.props.openEntite}>Continuer</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ModalConfirm;