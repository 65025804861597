import React, { Component } from 'react';
import addEntiteCss from '../../css/thematiques/addEntite.css';
import BDService from '../../service/BDService';
import EntiteService from '../../service/EntiteService';
import { Modal, Button } from 'react-bootstrap';

class ModalInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            nom: "",
            nomBd: localStorage.getItem('nomBd'),
            description: "",
            idBD: ""
        }
    }

    handleNomEntite = (e) => {
        this.setState({
            nom: e.target.value
        })
    }

    handleDescriptionEntite = (e) => {
        this.setState({
            description: e.target.value
        })
    }

    saveEntite = (event) => {
        event.preventDefault();
        let entite = {
            nom: this.state.nom,
            description: this.state.description,
            idBD: this.state.idBD
        }

        EntiteService.addEntite(entite).then((res) => {
            //this.props.history.push('/#thematique');
            this.setState({
                description: "",
                nom: ""
            })

        });


    }

    componentWillReceiveProps() {
        BDService.getBDByNom(this.props.bd).then((res) => {
            this.setState({
                idBD: res.data.idBD
            })
            //window.location.reload(true);
            //console.log(this.state.idBD)

        });
    }

    render() {
        return (

            <div id="Entite" className=' Entite addEntite col-lg-7'>

                <Modal
                    {...this.props}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header >
                        <Modal.Title id="contained-modal-title-vcenter">
                            <i className='text-danger'>Vous n'avez pas de compte pour effectuer cette opération !</i>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                       <p className='text_attention'> Voulez-vous vous inscrire ?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.props.closeModel} >NON</Button>
                        <Button variant="primary" onClick={this.props.inscrire} >OUI</Button>
                    </Modal.Footer>
                </Modal>
            </div>

        );
    }
}

export default ModalInfo;