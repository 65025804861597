
import './App.css';
import { Fragment } from 'react';
import { BrowserRouter as Router, Switch, Routes, Route } from 'react-router-dom/cjs/react-router-dom.min';
import ActualiteComponent from './components/actualites/ActualitesComponent';
import Avous from './components/Avous/AvousComponent';
import ContactComponent from './components/contact/ContactComponent';
import FooterComponent from './components/footer/FooterComponent';
import HeaderComponent from './components/header/HeaderComponent';
import StatistiquesComponent from './components/statistiques/StatistiquesComponent';
import ThematiqueListeComponent from './components/thematiques/ThematiqueListeComponent';
import NavBarComponent from './components/header/NavBarComponent';
import AideComponent from './components/aide/AideComponent';
import Home from './components/home/Home';
import TopBar from './components/header/TopBar';
import ListeBDComponent from './components/thematiques/ListeBDComponent';
import DetailsBD from './components/thematiques/DetailsBD';
import LoginComponent from './components/login/LoginComponent';
import SignUp from './components/login/SignUp';
import HomeUser from './components/espace_user/HomeUser';
import AddThematique from './components/thematiques/addThematique';

import { Default } from './components/footer/test';
import AddBd from './components/thematiques/AddBd';
import ListeEntites from './components/thematiques/ListeEntites';
import EnvoiDemande from './components/Avous/ModalChoix';
import SendDemande from './components/Avous/SendDemande';
import ListeDemande from './components/Avous/ListeDemande';
import DetailsDemande from './components/Avous/DetailsDemande';
import ListeQuestion from './components/Avous/ListeQuestion';
import DashbordPhase1 from './components/thematiques/DashbordPhase1';
import DashbordPhase2 from './components/thematiques/DashbordPhase2';
import DashbordPhase3 from './components/thematiques/DashbordPhase3';
import ScrollToTop from './components/scrollTop/ScrollToTop';
import Notifications from './components/espace_user/Notifications';
import GestionUserAdmin from './components/espace_user/GestionUserAdmin';
 


function App() {
  return (

    <div className="App" >
      <head>
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" />
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css" />
      </head>
      <Router >
      <ScrollToTop>
        <Switch>
          <Route path='/connect/:user' component={Home}></Route>


          <Route exact path='/' component={Home}></Route>
          <Route path='/aide'>
            <NavBarComponent />
            <AideComponent />
            <FooterComponent />
          </Route>


        </Switch>
        
        <Route path='/login' >
          <NavBarComponent />
          <LoginComponent />
        </Route>

        <Route path='/signUp/:id' >
          <NavBarComponent />
          <SignUp />
        </Route>

        <Route path='/user/:id' >
          <NavBarComponent />
          <HomeUser />
        </Route>

        <Route path='/useradmin/:id' >
          <NavBarComponent />
          <GestionUserAdmin />
        </Route>

        <Route path='/listebd/:id' >
          <NavBarComponent />
          <ListeBDComponent />
          
          
        </Route>

        <Route path='/test' component={Default}></Route>
        <Route path='/detailbd/:id'>
          <NavBarComponent />
          <DetailsBD />
         
         
        </Route>

        <Route path='/nouvelleDemande/:id' >
          <NavBarComponent />
          <SendDemande />
          
        </Route>


        <Route path='/listeDemande/:id' component={ListeDemande}></Route>
        <Route path='/listeQuestion/:id' component={ListeQuestion}></Route>
        <Route path='/notifications/:id' component={Notifications}></Route>
        <Route path='/addBd/:id' component={AddBd}></Route>
        
        <Route path='/listeEntites/:id' component={ListeEntites}></Route>
        <Route path='/dashbord2/:id' component={DashbordPhase2}></Route>
        <Route path='/dashbord3/:id' component={DashbordPhase3}></Route>
        <Route path='/choixEntites/:id' component={DashbordPhase1}></Route>
        <Route path='/detailDemande/:id' component={DetailsDemande}></Route>
        </ScrollToTop>
      </Router>
    </div>
  );
}



export default App;
