import React, { Component } from 'react';
import '../../css/contact/ContactCss.css';
import enveloppe from '../../assets/enveloppe.png'
import mail from '../../assets/mail.png'
import DemandeService from '../../service/DemandeService';
import validator from 'validator';

class ContactComponent extends Component {
    state = {
        objet: "",
        nom: "",
        email: "",
        message: "",
        idUser: localStorage.getItem('idUser'),
        errors: {} 
    }

    handleObjet = (e) => {
        this.setState({
            objet: e.target.value
        })
    }

    handleNom = (e) => {
        this.setState({
            nom: e.target.value
        })
    }

    handleEmail = (e) => {
        this.setState({
            email: e.target.value
        })
    }

    handleMessage = (e) => {
        this.setState({
            message: e.target.value
        })
    }

    formValidation=()=>{

        const{nom,email,objet,message,idUser}=this.state;
        let isValid=true;
        const errors={}
        if(idUser==null){
            if(nom.trim().length<4 ){
                errors.nomLength="Veuillez mettre un nom correct";
                isValid=false;
            }
            if(!validator.isEmail(email)){
                errors.emailerror="Veuillez entrer un e-mail valide";
                isValid=false;
            }
            if(message.trim().length==0){
                errors.messageLength="Le message est obligatoire";
                isValid=false;
            }
    
            if(objet.trim()==""){
                errors.objetLength="Veuillez choisir un objet";
                isValid=false;
            }
    }else{
        if(message.trim().length==0){
            errors.messageLength="Le message est obligatoire";
            isValid=false;
        }

        if(objet.trim()==""){
            errors.objetLength="Veuillez choisir un objet";
            isValid=false;
        }
    }

        

        

        this.setState({errors});
        return isValid;
    }


    saveDemande = (event) => {
        event.preventDefault();
        const isValid=this.formValidation();
        if(isValid){
        let demande = null
        const { nom, email, idUser } = this.state
        if (idUser == null) {
            demande = {
                message: this.state.message,
                objet: this.state.objet,
                dateEnvoi: new Date(),
                type: "question",
                user: nom + "_" + email,
                etat: "non repondu",
                viewbyUser:'personne',
                viewbyAgent:'personne'
            }
        } else {
            demande = {
                message: this.state.message,
                objet: this.state.objet,
                dateEnvoi: new Date(),
                type: "question",
                user: idUser,
                etat: "non repondu"
            }
        }
   


        DemandeService.addDemande(demande).then((res) => {

            this.setState({
                objet: "",
                email: "",
                message: "",
                nom: ""
            })
            //console.log(demande)

            let succes = document.getElementById("succes");
            succes.classList.remove("invisible");
            setTimeout(() => {
                succes.classList.add("invisible");
            }, 10000);

        });
    }
}

    

    render() {
        const { idUser ,errors} = this.state
        return (

            <div className='container'>
                <div></div>
                <div></div>
                <h2 className='title_news contact_nous container d-flex'>Contactez-Nous </h2>

                <hr className='botm-line' />
                <div className='row '>

                    <div className='col-lg-5   container '>
                        <h3 className='interrogation'>Restez en contact avec LARTES-IFAN</h3>
                        <div className='container fluid'>
                            <div className='row d-flex elmt_contact'>
                                <i className='icone_contact bi bi-geo-alt-fill '></i>
                                Université Cheikh Anta DIOP - Camp Jérémy
                            </div>
                        </div>

                        <div className='container fluid'>
                            <div className='row d-flex elmt_contact'>
                                <i className='icone_contact bi  bi-envelope-fill '></i>
                                infos@lartes-ifan.org
                            </div>
                        </div>

                        <div className='container fluid'>
                            <div className='row d-flex elmt_contact'>
                                <i className='icone_contact bi bi-telephone-fill '></i>
                                Bureau : +221 33 825 96 14
                            </div>
                        </div>

                        <div id="succes" className='succes container invisible'>
                            <bi className="bi bi_succes bi-check2-circle"></bi>
                            <div className='message_succes'>
                                Votre message a été bien envoyé .<br /> Nous vous répondrons très bientôt
                            </div>
                        </div>
                    </div>

                    <div className='  form row card-body col-lg-7 '>
                        <div className='container-fluid card-body '>
                           {/*<h4 className='interrogation'>Avez-vous une quelconque interrogation ?</h4>*/} 

                            <div className='col-lg-12'>

                                {(idUser == null) && (
                                    
                                    <div className=''>
                                        <div className='row  d-flex form-group '>
                                        <label className='label_contact'>Nom</label><i className='etoile_rouge'>*</i>
                                        <input value={this.state.nom} onChange={this.handleNom} className='nom  form-control' placeholder='Votre nom' />
                                        
                                        <i className='texte_champs_vide'>{errors.nomLength}</i>
                                       </div>

                                        <div className='row  d-flex form-group '>
                                            <label className='label_contact'>E-mail</label><i className='etoile_rouge'>*</i>
                                            <input value={this.state.email} onChange={this.handleEmail} className='form-group form-control' placeholder='Votre mail' />
                                            <i className='texte_champs_vide'>{errors.emailerror}</i>
                                        </div>
                                    </div>

                                   
                                )}

                                <div className='row  d-flex form-group '>
                                    <label>Objet</label><i className='etoile_rouge'>*</i>
                                    <select onChange={this.handleObjet} className='objet  col-lg-12 form-control'>
                                        <option value="">--choisir--</option>
                                        <option value="info" >Demande d'informations</option>
                                        <option value="partenariat">Demande de données</option>
                                        <option value="partenariat">Demande de partenariat</option>
                                        <option value="Expertise">Expertise</option>
                                        <option value="Autre">Autre</option>
                                    </select>
                                    <i className='texte_champs_vide'>{errors.objetLength}</i>
                                </div>

                                <div className='row d-flex form-group '>
                                    <label>Message</label><i className='etoile_rouge'>*</i>
                                    <textarea value={this.state.message} onChange={this.handleMessage} className='message form-group col-lg-12 form-control py-5' placeholder='' />
                                    <i className='texte_champs_vide'>{errors.messageLength}</i>
                                </div>
                                <a onClick={this.saveDemande} className='btn div_envoi '>
                                    <span className=' btn_envoi'>Envoyer</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default ContactComponent;