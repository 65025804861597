import axios from "axios";

const base_url = "http://localhost:9090/DONNEES-SERVICE/upload_excel"
const base_url_views_datas = "http://localhost:9090/DONNEES-SERVICE/datas"
var xhr = new XMLHttpRequest();
class EnqueteService{

        addEnquete(enquete){
            xhr.open("POST", base_url, true);
            xhr.send(enquete);
            xhr.onreadystatechange = function () {
                if (this.readyState !== 4) return;
                if (this.status === 200) {
                    //var data = JSON.parse(this.responseText);
                    //console.log(data)
                    //alert("Envoyé avec succes");
                    window.location.reload(true);
                } else {
                    alert("Erreur inconnue! ")
                }
            };
        }
        getdatas(enquete) {
            return axios.get(base_url_views_datas+ "/" + enquete)
        }
    }
    export default new EnqueteService()