import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import BDService from '../../service/BDService';
import EntiteService from '../../service/EntiteService';
import IndicateurService from '../../service/IndicateurService';
import ThematiqueService from '../../service/ThematiqueService';
import NavBarComponent from '../header/NavBarComponent';

class ModalChoix extends Component {
    state = {
        entites: [],
        thematiques: [],
        bds: [],
        entite: '',
        thematique: '',
        bd: '',
        indicateurs: [],
        message: '',
        entite: this.props.entite_bd,
        errors:{}
    }
    componentDidMount() {
        ThematiqueService.getAllThematique().then((res) => {
            this.setState({
                thematiques: res.data
            })
        });

    }

    formValidation=()=>{

        const{bd,thematique,entite}=this.state;
        let isValid=true;
        const errors={}
        if(thematique==''){
            errors.videerror="Veuillez remplir d'abord  ce formulaire de demande avant de choisir vos indicateurs ! ";
            isValid=false;
        }

        if(bd==''){
            errors.bderror="Veuillez choisir une base de données ";
            isValid=false;
        }

        if(entite=='' || entite==undefined){
            errors.voleterror="Veuillez choisir un volet ";
            isValid=false;
        }
        this.setState({errors});
        return isValid;
    }
    handleEntite = (e) => {
        this.setState({
            entite: e.target.value
        })
    }


    handleThematique = (e) => {
        this.setState({
            thematique: e.target.value
        })
    }

    handleBD = (e) => {
        this.setState({
            bd: e.target.value
        })
    }
    handleMessage = (e) => {
        this.setState({
            message: e.target.value
        })
    }


    afficherBds = () => {
        const { thematique } = this.state
        let bd = document.getElementById("bd");
        let entite = document.getElementById("entite");
        ThematiqueService.getThematiqueByNom(thematique).then((res) =>
            BDService.getBdsByThematique(res.data.idThematique).then((res) => {
                this.setState({
                    bds: res.data
                })
            })
        );
        if (thematique !== '') {
            bd.classList.remove("invisible");
        }
        else {
            bd.classList.add("invisible");
            entite.classList.add("invisible");
        }

    }

    afficherEntites = () => {
         const { bd } = this.state
        
        let entite = document.getElementById("entite");
        BDService.getBDByNom(bd).then((result) =>
        
            EntiteService.getEntiteByBd(result.data.idBD).then((res) => {
                //console.log("entites : ",res.data) 
               // console.log("IdBD BD : ",result.data.idBD)
                this.setState({
                    entites: res.data
                })
            })
        );
        if (bd !== '') {
            entite.classList.remove("invisible");
        }
        else {
           
            entite.classList.add("invisible");
        }

    }


    envoiDemande = () => {
        const { bd, entite } = this.state
        const isValid=this.formValidation();
        if(isValid){
        BDService.getBDByNom(bd).then((res1) =>
            EntiteService.getEntiteByBDAndNom(res1.data.idBD, entite).then((res) => {
                this.props.history.push(`/nouvelleDemande/${res.data.idEntite + '_-' + this.state.bd+ '_-' + this.state.message}`)
                console.log(entite)
            })
        );
        }
    }


    render() {
        const{errors}=this.state;
        return (
           
            <div className='card-body '>
                 
                <Modal
                    {...this.props}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header >
                        <Modal.Title id="contained-modal-title-vcenter">
                            <div onClick={()=>console.log(this.state.entite)} className='etape1'>Étape N°1</div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row   form-group card-body'>
                           <div className='card-body remplir'> {errors.videerror}</div>
                            <br /><br />
                            <div className='formulaire-choix container  '>

                                <div className='form-group label_et_input '>


                                    <div id="theme" className='theme'>
                                        <label> Thematique</label><i className='etoile_rouge'>*</i>
                                        <select onClick={this.afficherBds} onChange={this.handleThematique} className='form-control'>
                                            <option value=''>--choisir--</option>
                                            {

                                                this.state.thematiques.map(liste_theme =>
                                                    (liste_theme.visibility) && (
                                                        <option >{liste_theme.nom}</option>)
                                                )
                                            }

                                        </select>

                                    </div>

                                    <div id="bd" className='invisible'>
                                        <label> Base de données </label><i className='etoile_rouge'>*</i>
                                        <select onClick={this.afficherEntites} onChange={this.handleBD} className='form-control'>
                                            <option value=''>--choisir--</option>
                                            {
                                                this.state.bds.map(liste_bds =>
                                                    <option >{liste_bds.nom}</option>
                                                )
                                            }
                                        </select>
                                        <i className='texte_champs_vides'>{errors.bderror}</i>
                                    </div>

                                    <div id="entite" className='invisible'>
                                        <label> Volet </label><i className='etoile_rouge'>*</i>
                                        <select onClick={this.afficherIndicateurs} onChange={this.handleEntite} className='form-control'>
                                            <option value=''>--choisir--</option>
                                            {
                                                this.state.entites.map(liste_entites =>
                                                    <option >{liste_entites.nom}</option>
                                                )
                                            }
                                        </select>
                                        <i className='texte_champs_vides'>{errors.voleterror}</i>
                                    </div>

                                    <div id="message" className='form-group'>
                                        <label> Avez-vous un message particulier à nous  laisser ?</label>
                                         <textarea onChange={this.handleMessage} className='form-control py-4'/>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.props.closeModelChoix} >Annuler</Button>
                        <Button variant="primary" onClick={this.envoiDemande} >Continuer</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default withRouter(ModalChoix);