import React, { Component } from 'react';
import loginCSS from '../../css/login/loginCSS.css';
import login1 from '../../assets/login_hf.png'
import UserService from '../../service/UserService';
import { withRouter } from 'react-router-dom';
import validator from 'validator';


class LoginComponent extends Component {
    state = {
        user: '',
        username: '',
        password: '',
        profil: '',
        condition: false,
        errors:{}
    }

    setCookieFunction = (data) => {
        localStorage.setItem('idUser', JSON.stringify(data));
        //console.log(this.state.username);
    }
    formValidation=()=>{

        const{password,username,condition,user}=this.state;
        let isValid=true;
        const errors={}
        if(password.trim().length<8){
            errors.passworderror="Votre mot de passe doit contenir 8 caractères";
            isValid=false;
        }

        else if(!condition){
            errors.conditionerror="Veuillez accepter les conditions d'utilisation";
            isValid=false;
        }

        else if(!validator.isEmail(username)){
            errors.emailerror="Veuillez entrer un e-mail valide";
            isValid=false;
        }
       
       

        this.setState({errors});
        return isValid;
    }


    login = (e) => {
        e.preventDefault();
        const isValid=this.formValidation();
        
       UserService.getUserByEmailAndPwd(this.state.username, this.state.password).then((res) => {
            this.setState({
                user: res.data
            })

            if((isValid) &&(this.state.condition && this.state.user !== '')) {

                this.props.history.push(`/connect/${this.state.user.username}`)
                this.setCookieFunction(this.state.user.idUser);
                window.location.reload(true);
            }
            
        }


        );

    

    }

    HandleCondition = (e) => {
        this.setState({
            condition: e.target.checked
        })
    }

    HandleUsername = (e) => {
        this.setState({
            username: e.target.value
        })
    }

    HandlePassword = (e) => {
        this.setState({
            password: e.target.value
        })
    }

    render() {
        const{errors}=this.state;
        return (
            <div className='row shadow  d-flex justify-content-center card-body login_component col-lg-12'>
                <div className='card-body row d-flex justify-content-center col-lg-7  '>

                    <div className='container div_left  card-body col-lg-4'>
                    
                    <div className='icone_user_login'>
                    <i className='rounded-circle icon_person bi bi-person'></i>
                            <h3 className='connexion_texte'>Connexion </h3>
                    </div>
                    </div>
                    <div className=' container card-body shadow  div_right col-lg-8'>
                    <i className='texte_champs_vide parametres_con'>{errors.connexionerror}</i>
                        <div className='card-body'>
                        
                            <div className='row d-flex  form-group'>
                                <input className='form-control col-lg-11' onChange={this.HandleUsername} placeholder='votre email' />
                                <i className='texte_champs_vide'>{errors.emailerror}</i>
                            </div>

                            <div className='row d-flex  form-group'>

                                <input className='form-control col-lg-11' onChange={this.HandlePassword} type="password" placeholder='mot de passe' />
                                <i className='texte_champs_vide'>{errors.passworderror}</i>
                            </div>
                            <div className='conditions'>
                                <input onChange={this.HandleCondition} className='card-body checkbox' type="checkbox" />
                                <span className=''>J'ai lu et j'accepte <a href='#'>les conditions d'utilisation</a></span>
                                <br/>
                                <i className='texte_champs_vide'>{errors.conditionerror}</i>
                            </div>


                            <div className='boutons container '>
                                <div className='btn_top  col-lg-11   container '>
                                    <button className='bouton ' onClick={this.login}>Se connecter</button>
                                    <br/>
                                   <p><i><a className='mdp_forgot' href='#'>Mot de passe oublié ?</a></i></p>
                                </div>



                            </div>
                        </div>

                        <i className='inscrire'>Vous n'avez pas de compte ?<i className='btn_inscrire'><a href='/signUp/-1'>S'inscrire</a></i></i>
                    </div>
                </div>



                
            </div>

        );
    }
}


export default withRouter(LoginComponent);