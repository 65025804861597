import React, { Component } from 'react';
import mail from '../../assets/newThematique.png'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import ThematiqueService from '../../service/ThematiqueService';
import { Button, Modal } from 'react-bootstrap';

class UpdateThematique extends Component {
    state = {
       
        errors:{}

    }

        componentDidMount() {
            
        }

    HandleDescription = (e) => {
        this.setState({
            description: e.target.value
        })
    }

    handleSelectedImage = (e) => {
        this.setState({
            image: e.target.files[0]
        })
    }


    HandleThematique = (e) => {
        this.setState({
            thematique: e.target.value
        })
    }

    
    //charger l'image de la thematique
    imageThematiquehandler = (e) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                this.setState({
                    thematiqueImg: reader.result
                })
            }
        }
        reader.readAsDataURL(e.target.files[0])

    }
    formValidation=()=>{

        const{thematique}=this.state;
        let isValid=true;
        const errors={}
        if(thematique.trim().length==0){
            errors.themathiqueLength="Le nom de la thématique ne doit pas être vide ";
            isValid=false;
        }
        this.setState({errors});
        return isValid;
    }
    saveThematique = (event) => {
        event.preventDefault();
        const isValid=this.formValidation();
        if(isValid){
            let thematique = {
                nom: this.state.thematique,
                description: this.state.description,
                icone: this.state.thematiqueImg,
                visibility: true
    
            }
            // if (this.state.idthematique == -1) {
            ThematiqueService.addThematique(thematique).then((res) => {
                //this.props.history.push('/#thematique');
                this.props.fermerThematique2()
    
            });
    
            // } else {
            //    ThematiqueService.updateThematique(this.state.idthematique, thematique).then((res) => {
            //  this.props.history.push('/#thematique')
            ////   });
            // }
        }
        
    }




    render() {
        const { thematiqueImg,errors } = this.state
        console.log(this.props.thematique_nom)
        return (

            <div className=' col-lg-6 modal_add_them'>

                <Modal
                    {...this.props}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header >
                        <Modal.Title id="contained-modal-title-vcenter">
                            
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row  '>
                            <div className=' container col-lg-10'>
                                <div className='row d-flex form-group '>
                                    <label onClick={()=>console.log(this.props.thematique_nom)}>Nom de la thématique<i className='etoile_rouge'>*</i></label>
                                    <input value={this.props.thematique_nom} onChange={this.HandleThematique} className=' form-group col-lg-12 form-control' required />
                                    <i className='texte_champs_vides'>{errors.themathiqueLength}</i>
                                </div>

                                <div className='row d-flex form-group '>
                                    <label >Description</label>
                                    <textarea value={this.props.description} onChange={this.HandleDescription} className='form-group col-lg-12 form-control py-5' />
                                </div>

                                <div className='row d-flex form-group '>
                                    <label>Image</label>
                                    
                                    <input onChange={this.imageThematiquehandler} className=' form-group btn col-lg-12 form-control' type="file" accept='image/*' name="image_upload" />
                                </div>
                            </div>

                                   {/* <a className='btn div_envoi '>
                                           <span className='btn-info btn_envoi' onClick={this.saveThematique}>Valider</span>
                                        </a>
                                    */}
                        </div>
                     <span className='champs_etoile'> <i><i className='etoile_rouge'>*</i> Les champs marqués d'un astérisque sont obligatoires.</i> </span> 
                    </Modal.Body>
                    <Modal.Footer>
                       <Button variant="outline-danger" onClick={this.props.fermerThematique} >Quitter</Button>
                        <Button variant="outline-primary" onClick={this.saveThematique} >Enregistrer</Button>
                        {/** 
                            Object.keys(errors).map((key)=>{
                                return <div key={key} >{errors[key]}</div>
                            })
                       */ }
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default withRouter(UpdateThematique);