import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import axios from "axios"
import DataTable from 'react-data-table-component';
import IndicateurService from '../../service/IndicateurService';
import EnqueteService from '../../service/EnqueteService';

function Papa(entite) {

  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [nom_indicateur, setNom] = useState('');
  const [erreur, setErreur] = useState('');
  const [colors, setColors] = useState(
    ['#0088FE', '#00C49F', '#FFBB28', '#FF8042','#7D3C98','#FFC300 ','#58D68D','#C1BDC4','#FAE31D',  '#1D2AFA',
    '#DE0374', '#9398FF ','#40E8C7 ','#5D6D7E ','#F5B7B1 ','#B7950B '
    ]
  );

  // process CSV data
  const processData = dataString => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"')
              d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"')
              d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter(x => x).length > 0) {
          list.push(obj);
        }
      }
    }

    // prepare columns list from headers
    const columns = headers.map(c => ({
      name: c,
      selector: c,
    }));

    setData(list);
    setColumns(columns);
  }


  function handleNomIndiacateur(event) {
    setNom(event.target.value);
  }

  // handle file upload
  const handleFileUpload = e => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      processData(data);

    };
    reader.readAsBinaryString(file);


  }

  const saveIndicateurs = () => {

    for (let i = 0; i < data.length; i++) {
      var element = { indicateur: data[i] };
      var indicateur = element.indicateur
      var keys = Object.keys(indicateur)
      var key_pos_1 = keys[0]
      var key_pos_2 = keys[1]

      var value_1 = indicateur[key_pos_1]
      var value_2 = indicateur[key_pos_2]

      let indicateur_element = {
        reference: value_1,
        libelle: value_2,
        entite: entite.entite

      }

      IndicateurService.addIndicateur(indicateur_element).then(
        window.location.reload(true));




    }
  }
  function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex != 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }
  
  const send_data = event => {
    event.preventDefault()
    if(nom_indicateur!=''){
   
    shuffle(colors);
    var abcisse=''
    var keys=[]
    for (let i = 0; i < data.length; i++)
     {
      var element = { indicateur: data[i] };
      var indicateur = element.indicateur
      keys = Object.keys(indicateur)
      abcisse = keys[0]
     
    }
   
  var  ordonnees = keys.filter(word => word!== abcisse);
  var tabOrd=[];
  for (let i = 0; i < ordonnees.length; i++)
     {
      var element = { name: ordonnees[i],color:colors[i]}
     tabOrd.push(element)
     
    }
    var myForm = document.getElementById(event.target.id);
    const formData = new FormData(myForm);
    try{
      EnqueteService.addEnquete(formData)//.then((res)=>{
       
    }catch(error){
      setErreur(error)
    }
   if(erreur==''){
    var indicateur = {
      libelle: nom_indicateur,
      entite: entite.entite,
      abcisse:abcisse,
      ordonnees:tabOrd
    }
   
    IndicateurService.addIndicateur(indicateur) 
   }
        
    console.log(ordonnees);
   //// }

    //);
      
  }}

  return (
    <div className=' '>
      <div className='btns-import  '>


        <form key={entite.entite} encType="multipart/form-data" id={"entite_" + entite.entite} onSubmit={send_data}>
          <div className='form-group '>
            <label className='labels'>Nom de l'indicateur</label>

            <input value={nom_indicateur} onChange={handleNomIndiacateur} type="text" className='form-control'/>

            <input
              key={entite.entite}
              name='tablename'
              className='form-control'
              type="hidden"
              defaultValue={nom_indicateur + "*-*" + entite.entite}
            />
          </div>
          <div className='form-group '>
            <label for='my-file' className='labels'>Charger un fichier</label>
            <input
              key={entite.entite}
              type="file"
              name='les_fichiers'
              id="my-file"
              className='form-control'
              accept=".csv,.xlsx,.xls"
              onChange={handleFileUpload}
              required
            />
          </div>
          {(data.length !== 0) && (<input id="btn_ajout_indicateur" className='btn  btn-primary d-flex btn_ajout_indicateur' type="submit" value="Ajouter" />)}
        </form>




      </div>

      {(data.length !== 0) && (
        <DataTable
          pagination
          highlightOnHover
          columns={columns}
          data={data}

        />)}
    </div>
  );
}

export default Papa;