import ActualiteCss from '../../css/actualites/ActualitesCss.css'
import image from '../../assets/lumiere.jpg'
import { Component } from 'react'
import BDService from '../../service/BDService'
import ConvertDate from '../Avous/ConvertDate'
class ActualiteComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            top3Bd: {},
            top2Bd: {},
            top1Bd: {},
            top23Bd:[],
            listeAllBD:[]
        }

    }
    componentDidMount() {
        BDService.getTop3Bds().then((res) => {
           
            this.setState({
                top23Bd:[res.data[1],res.data[2]],
                top1Bd: res.data[0],
                top2Bd: res.data[1],
                top3Bd: res.data[2]
            })     
        }
        );

        BDService.getAllBds().then((res) => {
            this.setState({
                listeAllBD:res.data
            })  
        }
        );
    }

    afficherToutesLesBD=()=>{
        //afficher toutes les bases
        let allbd = document.getElementById("allbd");
        let btnmoins = document.getElementById("btnmoins");
        allbd.classList.remove("invisible");
        btnmoins.classList.remove("invisible");
        //cacher les top 3 bases
        let troisbd = document.getElementById("troisbd");
        let btntout = document.getElementById("btntout");
        btntout.classList.add("invisible");
        troisbd.classList.add("invisible");
        this.props.history.push("/#actualite");
    }
    afficherTop3BD=()=>{
        //cacher  toutes les bases
        let allbd = document.getElementById("allbd");
        let btnmoins = document.getElementById("btnmoins");
        allbd.classList.add("invisible");
        btnmoins.classList.add("invisible");
        // afficher les top 3 bases
        let troisbd = document.getElementById("troisbd");
        let btntout = document.getElementById("btntout");
        btntout.classList.remove("invisible");
        troisbd.classList.remove("invisible");
        this.props.history.push("/#actualite");
        
    }
    render() {
        const { top23Bd, top1Bd,top2Bd  ,top3Bd ,listeAllBD} = this.state
        return (
            <div className='container news'>
                
                {/*<h2 id="title" className='' ><i  id="icone" ></i><span>Thématiques</span></h2>*/}
                <div id="troisbd"className='troisbd'>
                <h2 onClick={()=>console.log(this.state.top23Bd)} className='row  card-body title_news container d-flex'>Données nouvellement ajoutées</h2>
                <div className='row col-lg-12  '>
                {(top1Bd!=null)&&( 
                    <div className='container col-lg-7 div_image_et_texte'>
                        {/*Top 1 BD recente */}
                        <div className='row div_sur_limage '>
                   
                            <div className='div1'>
                                <br />
                                <span className='le_nom_de_la_bd'>{top1Bd.nom}{/*top3Bd[0].nom*/} </span>
                                <br />
                                <span className='description_de_la_bd'>{top1Bd.description}
                                </span>
                                <br />
                                <a className='lienn' href={`/detailbd/${top1Bd.idBD}`}>En savoir plus...</a><br />
                                <small className='date'>
                                    <i>
                                        <ConvertDate key={top1Bd.idBD} date={top1Bd.dateImportation} />

                                    </i>
                                </small>
                                <br />
                            </div>
                    
                        </div>
                        <img src={image} className='row   col-lg-12 image' />
                    </div>
                     )}

                {((top2Bd!=null)&&(top3Bd!=null))&&(
                    <div className='bds_recentes col-lg-5   container'>
                        {/*Top 2 et 3  BD recente */}
                        
                            {top23Bd.map(listetop2et3=>
                                <div className='une_bd_recente '><br />
                                        <span className='le_nom_de_la_bd'>{listetop2et3.nom}{/*top3Bd[0].nom*/} </span>
                                        <br />
                                        <span className='description_de_la_bd'>{listetop2et3.description}
                                        </span>
                                        <br />
                                        <a className='lienn' href={`/detailbd/${listetop2et3.idBD}`}>En savoir plus...</a><br />
                                        <small className='date'>
                                            <i>
                                                <ConvertDate key={listetop2et3.idBD} date={listetop2et3.dateImportation} />
        
                                            </i>
                                        </small>
                                        <br />
                                    <hr />
        
                                </div>
            
                             )}
                        
                        
                    </div>
                    )}








                    </div>
                    </div>
                   <div id="allbd"className='invisible'>
                   <div className='bds_recentes    container'>
                    <h2 className='row  card-body title_news container d-flex'>Toutes les bases de données </h2>
                        {/*All BD */}
                        <div className='row'>
                        {listeAllBD.map(listeAllBD2=>
                        <div className='une_bd_recente col-lg-6 '><br />
                                <span className='le_nom_de_la_bd'>{listeAllBD2.nom}{/*top3Bd[0].nom*/} </span>
                                <br />
                                <span className='description_de_la_bd'>{listeAllBD2.description}
                                </span>
                                <br />
                                <a className='lienn' href={`/detailbd/${listeAllBD2.idBD}`}>En savoir plus...</a><br />
                                <small className='date'>
                                    <i>
                                        <ConvertDate key={listeAllBD2.idBD} date={listeAllBD2.dateImportation} />

                                    </i>
                                </small>
                                <br />
                            <hr />
</div>
                      

                        
                     )}
                      </div>  
                     
                </div>
                </div>
                {/*Bouton voir  plus de BD*/}
                <div className='container'>

                <div id="btntout" onClick={this.afficherToutesLesBD}>
                <a className='btn div_demande ' href='/#actualite'>
                    <span className=' btn_demande'><span>Voir tout...</span></span>
                </a>
                </div>

                <div id="btnmoins" onClick={this.afficherTop3BD} className=' invisible'>
                <a  href='/#actualite' className='btn div_demande '>
                    <span className=' btn_demande'><span>Voir moins...</span></span>
                </a>
                </div>
                </div>



            </div>
        )
    }
}
export default ActualiteComponent;