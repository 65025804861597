import axios from "axios"

const url_entite = "http://localhost:9090/ENQUETE-SERVICE/entite"
//const url_entite="http://192.168.0.53:8080/entite"
class EntiteService {


    addEntite(entite) {
        return axios.post(url_entite + "/add", entite);
    }

    getNbreEntiteByBD(idBD) {
        return axios.get(url_entite + "/count" + "/" + idBD);
    }

    getEntiteByBDAndNom(bd, nom) {
        return axios.get(url_entite + '/' + bd + '/' + nom)
    }

    getEntiteByBd(bd) {
        return axios.get(url_entite + "/" + bd)
    }

    getEntiteById(entite) {
        return axios.get(url_entite + "/search" + "/" + entite)
    }
}

export default new EntiteService()