import axios from "axios"

const url_mot = "http://localhost:9090/ENQUETE-SERVICE/mot"
//const url_mot="http://192.168.0.53:8080/mot"
class MotCle {

    getAllMotCle() {
        return axios.get(url_mot + '/all')
    }

    addMotCle(mot) {
        return axios.post(url_mot + "/add", mot);
    }

    getMotCleById(mot){
        return axios.get(url_mot + "/search" + "/" + mot)
    }

}
export default new MotCle()