import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';



class DmdModalConfirm extends Component {
    constructor(props) {
        super(props)
    }
    render() {

        return (
            
            <Modal
                {...this.props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                            <Modal.Header >
                        <Modal.Title id="contained-modal-title-vcenter">
                            <div  className='etape1 text-danger'>Attention !!!</div>
                        </Modal.Title>
                    </Modal.Header>
                <Modal.Body>

                    <p className='text_attention'>
                        Vous n'avez pas choisi d'indicateur !<br/>
                         Si vous vous voulez faire une demande sur des indicateurs que vous n'avez pas
                          trouvé dans cette liste, vous pouvez nous laisser un  message pour ce faire .<br/>
                          Voulez-vous annuler cette demande et nous laisser un message ?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <a href='/#contact'><Button variant="danger" onClick={this.props.oui}>Oui</Button></a>
                    <Button variant="primary" onClick={this.props.non}>Non</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default DmdModalConfirm;