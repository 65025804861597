import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { withRouter } from 'react-router-dom';
import DemandeService from '../../service/DemandeService';
import EntiteService from '../../service/EntiteService';
import UserService from '../../service/UserService';
import Aside from '../espace_user/Aside';
import NavBarComponent from '../header/NavBarComponent';
import ConvertDate from './ConvertDate';
import DetailsDemande from './DetailsDemande';
import ModalChoix from './ModalChoix';
import ModalReply from './ModalReply';
import RetrieveBD from './RetrieveBD';
import RetrieveEntite from './RetrieveEntite';
import HomeUserCSS from '../../css/espace_user/HomeUserCSS.css'

class ListeQuestion extends Component {
    state = {
        demandesUser: [],
        allDemandesUser: [],
        idUser: localStorage.getItem('idUser'),
        addModalShow: false,
        addModalDetailShow: false,
        entite: null,
        bd: null,
        entite_bd: null,
        utilisateur: this.props.match.params.id,
        idUser: parseInt(localStorage.getItem('idUser')),
        user: '',
        addModalReplyhow: false
    }
    closeModelReply = () => {
        this.setState({
            addModalReplyhow: false
        });

    }



    openModalReply = (demande_) => {
        this.setState({
            demande: demande_,
            addModalReplyhow: true
        });

    }
    componentDidMount() {
        UserService.getUserById(this.state.idUser).then((res) => {
            this.setState({
                user: res.data
            })

        }
        );
        const { idUser, allDemandesUser, demandesUser, utilisateur } = this.state

        if (utilisateur == -1) {
            DemandeService.getDemandeByType("question").then((res => {
                this.setState({
                    allDemandesUser: res.data
                })

            }));
        } else {
            DemandeService.getDemandeByUserAndType(idUser, "question").then((res => {
                this.setState({
                    allDemandesUser: res.data
                })

            }));
        }
    }
    closeModelChoix = () => {
        this.setState({
            addModalShow: false
        });

    }



    openModalDemande = () => {
        this.setState({
            addModalShow: true
        });

    }

    ViewDetail = (id) => {
        this.props.history.push(`/detailDemande/${id}`);

    }
    deleteQuestion=(id)=>{
        DemandeService.deleteDemande(id).then((res) => {

            this.setState({
                allDemandesUser: this.state.allDemandesUser.filter(thematique => thematique.idDemande !== id),
                
            })

        })
    }

    render() {
        const { demandesUser } = this.state
        return (
            <div>
                <NavBarComponent />
                <div className='row col-lg-12'>
                    <Aside active={2}/>
                    <div className='col-lg-10'>
                        <a className=' div_demande_ ' href="/#contact" >
                        <span><bi className="bi  btn-add-thematique  bi-plus-circle-fill"><span className='ajout_theme'>Ajouter une question</span> </bi></span>
                           
                        </a>
                        <div className="">
                            <table className="table-hover     table table-bordered table-striped">
                                <thead className="bg-light">
                                    <tr>
                                        <th>Date d'envoi</th>
                                        <th>Objet</th>
                                        <th className='msg_td_dmd'>Message</th>
                                        {/*<th>Indicateurs</th>*/}
                                        <th>Etat</th>
                                        <th><bi className="bi bi-three-dots" ></bi></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.allDemandesUser.map(
                                            demandes =>
                                                <tr key={demandes.idDemande}>
                                                    <ConvertDate date={demandes.dateEnvoi} />
                                                    <td>{demandes.objet}</td>
                                                    <td  className='msg_demande_tableau'>{demandes.message}</td>
                                                    {(demandes.etat == 'non repondu') && (<td><bi className="bi bi-check2 etat_notreply"></bi></td>)}
                                                    {(demandes.etat !== 'non repondu') && (<td><bi className="bi bi-check2-all etat"></bi></td>)}

                                                    <td>
                                                        <button className="btn  btn-primary" onClick={() => this.ViewDetail(demandes.idDemande)}><bi className="bi bi-eye-fill"></bi></button>
                                                        {/*(this.state.user.profil == "visiteur") && (<button className="btn btn-info" style={{ marginLeft: "10px" }} onClick={() => this.deleteEmploye(demandes.idDemande)}><bi className="bi  bi bi-pencil-fill"></bi></button>)*/}
                                                        {(this.state.user.profil == "visiteur") && (<button className="btn btn-danger" style={{ marginLeft: "10px" }} onClick={() => this.deleteQuestion(demandes.idDemande)}><bi className="bi  bi-trash-fill"></bi></button>)}
                                                        {(this.state.user.profil == "agent") && (<button onClick={() => this.openModalReply(demandes.idDemande)} key={demandes.idDemande} id={demandes.idDemande} className="btn btn-success" style={{ marginLeft: "10px" }}><bi className="bi  bi-reply-fill"></bi></button>)}
                                                    </td>
                                                </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <ModalChoix
                        show={this.state.addModalShow}
                        closeModelChoix={this.closeModelChoix}

                    />
                    <ModalReply
                        show={this.state.addModalReplyhow}
                        closeModelReply={this.closeModelReply}
                        demande={this.state.demande}
                        id={this.state.demande}
                        question="question"

                    />

                </div>
            </div>
        );
    }
}

export default withRouter(ListeQuestion);