import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { BrowserRouter as Router, Switch, Routes, Route } from 'react-router-dom/cjs/react-router-dom.min';

import HomeUserCSS from '../../css/espace_user/HomeUserCSS.css'
import UserService from '../../service/UserService';
import AideComponent from '../aide/AideComponent';
import ListeDemande from '../Avous/ListeDemande';
import ContactComponent from '../contact/ContactComponent';
import NavBarComponent from '../header/NavBarComponent';
import AddBd from '../thematiques/AddBd';
import Aside from './Aside';
import image from '../../assets/im.png'
import validator from 'validator';
import SocieteService from '../../service/SocieteService';
import ThematiqueService from '../../service/ThematiqueService';
import BDService from '../../service/BDService';
import DemandeService from '../../service/DemandeService';
import CountUp from 'react-countup';
import DataTable from 'react-data-table-component';
class HomeUser extends Component {
    state = {
        idUser: parseInt(localStorage.getItem('idUser')),
        user: '',
        idpage: parseInt(this.props.match.params.id),
        mail: '',
        mdp: '',
        mdp_conf: '',
        sexe: '',
        prenom: '',
        nom: '',
        adresse_user: '',
        ville_user: '',
        telephone1: '',
        telephone2: '',
        profession: '',
        pays_user: '',
        verification: '',
        date_inscription: '',
        date_de_derniere_connexion: '',
        profil: 'visiteur',
        vide:'',

        type_user: 'physique',
        validation_condition: '',

        nom_structure: '',
        description: '',
        courriel: '',
        telephone: '',
        adresse_structure: '',
        boite_postale: '',
        ville: '',
        pays_structure: '',
        site_web: '',
        type_societe: '',
        errors: {},
        nbreBds: 0,
            nbreThematiques: 0,
            nbreDemandes: 0,
            nbreUsers:0,
            Columns:["Prenom","Nom","E-mail","Telephone","Adresse","Action"],
            listeUsers:[]
    }
    componentDidMount() {

        ThematiqueService.getNbreThematique(true).then((res) => {
            this.setState({
                nbreThematiques: res.data
            })
        });

        UserService.getAllUser().then((res)=>{
            this.setState({
                listeUsers:res.data
            })
        });
        DemandeService.getnbreAllDemande().then((res) => {
            this.setState({
                nbreDemandes: res.data
            })
        });

        BDService.getnbreAllBds().then((res) => {
            this.setState({
                nbreBds: res.data
            })
        });

        UserService.getnbreAllUser().then((res) => {
            this.setState({
                nbreUsers: res.data
            })
        });



        UserService.getUserById(this.state.idpage).then((res) => {

            this.setState({
                user: res.data,
                username: res.data.username,
                profil: res.data.profil,
                prenom: res.data.prenom,
                type_user: res.data.type,
                nom: res.data.nom,
                telephone2: res.data.telephone2,
                telephone1: res.data.telephone1,
                adresse_user: res.data.adresse,
                pays_user: res.data.pays,
                sexe: res.data.sexe,
                profession: res.data.profession,
                mdp: res.data.password,
                //informations relatives a la societe de l'utilisateur
                nom_structure: res.data.societe?.nom,
                description: res.data.societe?.description,
                courriel: res.data.societe?.courriel,
                telephone: res.data.societe?.telephone,
                adresse_structure: res.data.societe?.adresse,
                boite_postale: res.data.societe?.boite_postale,
                ville: res.data.societe?.ville,
                pays_structure: res.data.societe?.pays,
                site_web: res.data.societe?.site_web,
                type_societe: res.data.societe?.type



            })
        }

        );
    }
    formValidation = () => {

        const {username, mdp, mdp_conf, prenom, nom, profession, telephone1, telephone2, telephone, nom_structure, courriel, validation_condition, type_user } = this.state;
        let isValid = true;
        const errors = {}
        if (!validator.isEmail(username)) {
            errors.emailerror = "Veuillez entrer un e-mail valide";
            isValid = false;
        }



        if (mdp.trim().length < 8) {
            errors.passworderror = "Votre mot de passe doit contenir 8 caractères";
            isValid = false;
        }

        if (prenom.trim().length < 2) {
            errors.prenomerror = "Mettez un prenom correct";
            isValid = false;
        }

        if (nom.trim().length < 2) {
            errors.nomerror = "Mettez un nom correct";
            isValid = false;
        }



        if (profession.trim() == '') {
            errors.professionerror = "Il faut choisir une profession";
            isValid = false;
        }


        var ma_regexp = /^(77|78|75|76|70|33)[0-9]{7}$/;
        if (!ma_regexp.test(telephone1)) {
            errors.telephonerror = "Le numero de telephone est incorrect";
            isValid = false;
        }
        if (telephone2 != '') {
            if (!ma_regexp.test(telephone2)) {
                errors.telephonerror = "Le numero de telephone est incorrect";
                isValid = false;
            }
        }


        if (type_user == "morale") {
            if (!ma_regexp.test(telephone)) {
                errors.telephonerror = "Le numero de telephone est incorrect";
                isValid = false;
            }

            if (nom_structure.trim().length < 2) {
                errors.nomstructureerror = "Mettez un nom de structure correct";
                isValid = false;
            }
            if (!validator.isEmail(courriel)) {
                errors.courrielerror = "Veuillez entrer un e-mail valide";
                isValid = false;
            }
        }



        this.setState({ errors });
        return isValid;
    }

    Handle_Personne_Morale = (e) => {
        this.setState({
            type_user: e.target.value

        })
        let personne_morale = document.getElementById("personne_morale");
        personne_morale.classList.remove("invisible");
        //console.log(this.state.idpage);
    }

    Handle_Personne_Physique = (e) => {
        this.setState({
            type_user: e.target.value

        })
        let personne_morale = document.getElementById("personne_morale");
        personne_morale.classList.add("invisible");
    }

    setCookieFunction = (data) => {
        localStorage.setItem('idUser', JSON.stringify(data));
    }
    saveUser = (event) => {
        event.preventDefault();
        const isValid = this.formValidation();
        if (isValid) {


            if (this.state.user.type == "physique" || this.state.user.type == "") {
                let new_user = {
                    profil: this.state.profil,
                    prenom: this.state.prenom,
                    type: this.state.type_user,
                    nom: this.state.nom,
                    telephone2: this.state.telephone2,
                    telephone1: this.state.telephone1,
                    adresse: this.state.adresse_user,
                    pays: this.state.pays_user,
                    sexe: this.state.sexe,
                    profession: this.state.profession,
                    username: this.state.username,
                    password: this.state.mdp,
                    verification: 1,



                }
                UserService.updateUser(this.state.idpage, new_user).then((res1) => {

                    window.location.reload(true);

                });
            } else {
                let societe = {
                    nom: this.state.nom_structure,
                    description: this.state.description,
                    courriel: this.state.courriel,
                    telephone: this.state.telephone,
                    adresse: this.state.adresse_structure,
                    boite_postale: this.state.boite_postale,
                    ville: this.state.ville,
                    pays: this.state.pays_structure,
                    site_web: this.state.site_web,
                    type: this.state.type_societe
                }

                let new_user = {
                    profil: this.state.profil,
                    prenom: this.state.prenom,
                    type: this.state.type_user,
                    nom: this.state.nom,
                    telephone2: this.state.telephone2,
                    telephone1: this.state.telephone1,
                    adresse: this.state.adresse_user,
                    pays: this.state.pays_user,
                    sexe: this.state.sexe,
                    profession: this.state.profession,
                    username: this.state.username,
                    password: this.state.mdp,
                    date_inscription: this.state.date_inscription,
                    societe: societe,
                    verification: this.state.verification,

                }
                UserService.updateUser(this.state.idpage, new_user).then((res1) => {

                    window.location.reload(true);



                });
            }
        }
    }


    HandleNom = (e) => {
        this.setState({
            nom: e.target.value
        })
    }

    HandlePays_user = (e) => {
        this.setState({
            pays_user: e.target.value
        })
    }
    HandleProfession = (e) => {
        this.setState({
            profession: e.target.value
        })
    }
    HandleTelephone2 = (e) => {
        this.setState({
            telephone2: e.target.value
        })
    }

    HandleProfil = (e) => {
        this.setState({
            profil: e.target.value
        })
    }
    HandleTypeSociete = (e) => {
        this.setState({
            type_societe: e.target.value
        })
    }

    HandleTelephone1 = (e) => {
        this.setState({
            telephone1: e.target.value
        })
    }
    HandleVilleUser = (e) => {
        this.setState({
            ville_user: e.target.value
        })
    }
    HandleAdresseUser = (e) => {
        this.setState({
            adresse_user: e.target.value
        })
    }
    HandlePrenom = (e) => {
        this.setState({
            prenom: e.target.value
        })
    }

    HandleSexe_Masculin = (e) => {
        this.setState({
            sexe: e.target.value
        })
    }

    HandleSexe_Feminin = (e) => {
        this.setState({
            sexe: e.target.value
        })
    }
    HandleMdp_conf = (e) => {
        this.setState({
            mdp_conf: e.target.value
        })
    }
    HandleMdp = (e) => {
        this.setState({
            mdp: e.target.value
        })
    }
    HandleMail = (e) => {
        this.setState({
            username: e.target.value
        })
    }


    HandleValidation_condition = (e) => {
        this.setState({
            validation_condition: e.target.checked
        })
    }


    HandleSite_web = (e) => {
        this.setState({
            site_web: e.target.value
        })
    }
    HandlePays_structure = (e) => {
        this.setState({
            pays_structure: e.target.value
        })
    }
    HandleVille = (e) => {
        this.setState({
            ville: e.target.value
        })
    }
    HandleBoite_postale = (e) => {
        this.setState({
            boite_postale: e.target.value
        })
    }
    HandleAdresse_structure = (e) => {
        this.setState({
            adresse_structure: e.target.value
        })
    }
    HandleTelephone_Societe = (e) => {
        this.setState({
            telephone: e.target.value
        })
    }
    HandleCourriel = (e) => {
        this.setState({
            courriel: e.target.value
        })
    }
    HandleDescription = (e) => {
        this.setState({
            description: e.target.value
        })
    }
    HandleNom_structure = (e) => {
        this.setState({
            nom_structure: e.target.value
        })
    }

    render() {
        const { listeUsers,columns,errors, username, mdp, prenom, nom, adresse_user, ville_user, adresse_structure,
            profession, telephone1, telephone2, nom_structure, type_societe, courriel, telephone, site_web, description
        } = this.state
        return (



            <div className='row col-lg-12'>
                
                <Aside active={0} />
                <div className='col-lg-10'>
                  {/*------------------------------agent--------------------------*/}   
                 
                    <div>
                       {(this.state.user.profil == "agent" || this.state.user.profil == "admin" ) &&(
                       <div className='container'>
                        <div className='div_top'></div>
                        

                        
                        <div className='div_bottom shadow row   d-flex align-items-center justify-content-center  liste_des_statistiques '>
                            {/*Statistiques nombre thematiques */}
                            <div className=' shadow   stat1   py-4  col-lg-5 '>
                                <div className='row  col-lg-12 '>
                                    <div className='col-lg-9 nom_val_stat '>
                                        <b className='nom_stat1'>Thematiques
                                        </b>
                                        <p className='valeur_stat'>
                                            <CountUp end={this.state.nbreThematiques} duration={1}/>
                                            
                                            </p>
                                    </div>
                                    <div className='icone_stat d-flex align-items-center'>
                                        <i className='col-lg-2 bi bi-table'></i>
                                    </div>

                                </div>
                            </div>
                            {/*Fin !!! Statistiques nombre base de thematiques  */}

                            {/*Statistiques nombre base de donnees */}
                            <div className=' shadow  stat2   py-4  col-lg-5 '>
                                <div className='row  col-md-12 '>
                                    <div className='col-lg-9 nom_val_stat '>
                                        <b className='nom_stat2'>Bases de données
                                        </b>
                                        <p className='valeur_stat'> 
                                        <CountUp end={this.state.nbreBds} duration={1}/></p>
                                    </div>
                                    <div className='icone_stat d-flex align-items-center'>
                                        <i className='col-lg-2 bi bi-server'></i>
                                    </div>

                                </div>
                            </div>
                            {/*Fin !!! Statistiques nombre base de Bases de données*/}

                            {/*Statistiques nombre base de demandeurs */}
                            <div className=' shadow  stat3   py-4  col-lg-5 '>
                                <div className='row  col-md-12 '>
                                    <div className='col-lg-9 nom_val_stat '>
                                        <b className='nom_stat3'>Demandes
                                        </b>
                                        <p className='valeur_stat'> 
                                        <CountUp end={this.state.nbreDemandes} duration={1}/></p>
                                    </div>
                                    <div className='icone_stat d-flex align-items-center'>
                                        <i className='col-lg-2 bi bi-envelope'></i>
                                    </div>

                                </div>
                            </div>
                            {/*Fin !!! Statistiques nombre base de demandeurs */}

                            {/*Statistiques nombre utilisateurs */}
                            <div className=' shadow  stat4   py-4  col-lg-5 '>
                                <div className='row  col-md-12 '>
                                    <div className='col-lg-9 nom_val_stat '>
                                        <b className='nom_stat4'>Utilisateurs
                                        </b>
                                        <p className='valeur_stat'> 
                                        <CountUp end={this.state.nbreUsers} duration={1}/></p>
                                    </div>
                                    <div className='icone_stat d-flex align-items-center'>
                                        <i className='col-lg-2 bi bi-people-fill'></i>
                                    </div>
                                </div>
                            </div>
                            {/*Fin !!! Statistiques nombre utilisateurs */}

                        </div>
                    </div>


                        
                      
                       )}
                       {(this.state.user.profil == "admin") && (
                      <div className='row d-flex justitify-content-around'>
                      <h4 className='h3_indicateur card-body'>Utilisateurs</h4>
                      
                        </div> 
                        )}
<div className='shadow'>
{(listeUsers.length !== 0 && this.state.user.profil == "admin") && (
        <>
        
        <table className="table-hover  table_dmd  table table-bordered table-striped">
                                <thead className="bg-light">
                                    <tr>
                                        <th>Prenom</th>
                                        <th>Nom</th>
                                        <th>E-mail</th>
                                        <th className='msg_td_dmd'>Telephone</th>
                                        <th>Adresse</th>
                                        <th><bi className="bi bi-three-dots" ></bi></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.listeUsers.map(
                                            users =>
                                            
                                                <tr key={users.idUser}>
                                                   
                                                    
                                                    {(users.profil != 'admin') && (<td>{users.prenom}</td>)}
                                                    {(users.profil != 'admin') && (<td>{users.nom}</td>)}
                                                    {(users.profil != 'admin') && (<td>{users.username}</td>)}
                                                    {(users.profil != 'admin'&& users.telephone1 != 0) && (<td>{users.telephone1}</td>)}
                                                    {(users.telephone1 == 0) && (<td>{this.state.vide}</td>)}
                                                    {(users.profil != 'admin') && (<td>{users.adresse}</td>)}
                                                    

                                                    <td>
                                                     <a href={`/useradmin/${users.idUser}`}> <button className="btn  btn-primary" ><bi className="bi bi-eye-fill"></bi></button> </a>
                                                    </td>
                                                    
                                                </tr>
                                               
                                        )
                                    }
                                </tbody>
                            </table></>

        )}</div>

                    </div>
                 {/*------------------------------agent--------------------------*/} 
                {/*------------------------------Visiteur et Utilisateur Lartes--------------------------*/}
                {(this.state.user.profil == "visiteur" ||this.state.user.profil == "user_lartes") &&(

                    <div class="container-fluid rounded bg-white mt-5 mb-5">
                        <div class="row">
                            <div class="col-md-3 border-right">
                                <div class="d-flex flex-column align-items-center text-center p-3 py-5"><img class="rounded-circle mt-5"
                                    width="190px"
                                    src={image} />
                                    <div className='row'>
                                        <span className='font-weight-bold'>{this.state.user.prenom}</span><br />
                                        <span className='nom_personne font-weight-bold black'>{this.state.user.nom}</span>
                                    </div>

                                    <span class="text-black-50">{this.state.user.username}</span><span> </span></div>
                            </div>
                            <div class="col-md-5 border-right">
                                <div class="p-3 py-5">
                                    <div class="d-flex justify-content-between align-items-center mb-3">
                                        <h4 class="text-right text_title">Informations d'accés </h4>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-12"><label class="labels">E-mail</label><input onChange={this.HandleMail} type="text" className='form-control' value={username} />
                                            <i className='texte_champs_vide'>{errors.emailerror}</i>
                                        </div>
                                        <div class="col-md-12"><label class="labels">Mot de passe</label> <input onChange={this.HandleMdp} type="password" className='form-control' value={mdp} />
                                            <i className='texte_champs_vide'>{errors.passworderror}</i></div>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center mb-3">
                                        <h4 class="text-right text_title">Informations personnelles </h4>
                                    </div>


                                    <div class="row mt-3">
                                        <div class="col-md-12"><label class="labels">Prenom</label><input onChange={this.HandlePrenom} type="text" className='form-control' value={prenom} />
                                            <i className='texte_champs_vide'>{errors.prenomerror}</i></div>
                                        <div class="col-md-12"><label class="labels">Nom</label><input onChange={this.HandleNom} type="text" className='form-control' value={nom} />
                                            <i className='texte_champs_vide'>{errors.nomerror}</i></div>
                                        <div class="col-md-12"><label class="labels">Adresse</label><input onChange={this.HandleAdresseUser} type="text" className='form-control' value={adresse_user} /></div>
                                        <div class="col-md-12"><label class="labels">Ville</label><input onChange={this.HandleVilleUser} type="text" className='form-control' value={ville_user} /></div>
                                        <div class="col-md-12"><label class="labels">Profession</label>
                                            <select onChange={this.HandleProfession} type="text" className='form-control'>
                                                <option value={profession}>{profession}</option>
                                                {(profession != 'Etudiant') && (<option>Etudiant</option>)}
                                                {(profession != 'Chercheur') && (<option>Chercheur</option>)}
                                                {(profession != 'Enseignant') && (<option>Enseignant</option>)}
                                                {(profession != 'Autre') && (<option>Autre</option>)}
                                            </select>
                                            <i className='texte_champs_vide'>{errors.professionerror}</i>
                                        </div>
                                        <div class="col-md-12"><label class="labels">Telephone 1</label><input onChange={this.HandleTelephone1} className='form-control' value={telephone1} />
                                            <i className='texte_champs_vide'>{errors.telephonerror}</i></div>
                                        <div class="col-md-12"><label class="labels">Telephone 2</label><input onChange={this.HandleTelephone2} type="text" className='form-control' value={telephone2} />
                                            <i className='texte_champs_vide'>{errors.telephonerror}</i></div>

                                    </div>

                                    <div class="mt-5 text-center"><button onClick={this.saveUser} class="btn btn-primary profile-button" type="button">Enregistrer les modifications</button></div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="p-3 ">
                                    <div class="d-flex justify-content-between align-items-center mb-3">
                                        <h4 class="text-right text_title">Structure </h4>
                                    </div>
                                </div>
                                <div class="col-md-12"><label class="labels">Raison sociale</label><input onChange={this.HandleNom_structure} type="text" className='form-control' value={nom_structure} />
                                    <i className='texte_champs_vide'>{errors.nomstructureerror}</i></div>

                                <div class="col-md-12"><label class="labels">Type</label>
                                    <select onChange={this.HandleTypeSociete} type="text" className='form-control'>
                                        <option value={type_societe}>{type_societe}</option>
                                        <option>Ministère</option>
                                        <option>Direction nationale</option>
                                        <option>Institution/Coopération</option>
                                        <option>Fondation</option>
                                        <option>Cabinet d'Expertise privé</option>
                                        <option>Institution privée</option>
                                        <option>Association </option>
                                        <option>Syndicat</option>
                                        <option>Coopérative </option>
                                        <option>Mutuelle </option>
                                        <option>Entreprise privée </option>
                                        <option>Entreprise sociale </option>
                                        <option>Université </option>
                                        <option>Structure de formation </option>
                                        <option>Organisme Nations Unies </option>
                                        <option>Institution/Organisme de conseil </option>
                                        <option>ONG </option>
                                        <option>Structure de formation </option>
                                        <option>Institution/Organisme  parapublique</option>
                                        <option>Think Tank</option>
                                        <option>Organisation patronale</option>
                                        <option>Autre</option>



                                    </select>
                                </div>

                                <div class="col-md-12"><label class="labels">Courriel</label><input onChange={this.HandleCourriel} type="text" className='form-control' value={courriel} />
                                    <i className='texte_champs_vide'>{errors.courrielerror}</i></div>

                                <div class="col-md-12"><label class="labels">Telephone</label><input onChange={this.HandleTelephone_Societe} type="text" className='form-control' value={telephone} />
                                    <i className='texte_champs_vide'>{errors.telephonerror}</i></div>

                                <div class="col-md-12"><label class="labels">Adresse</label><input onChange={this.HandleAdresse_structure} type="text" className='form-control' value={adresse_structure} /></div>

                                <div class="col-md-12"><label class="labels">Site</label><input onChange={this.HandleSite_web} type="text" className='form-control' value={site_web} /></div>

                                <div class="col-md-12"><label class="labels">Présentation</label><textarea onChange={this.HandleDescription} type="text" className='py-5 form-control' value={description} /></div>

                            </div>
                        </div>

                    </div>
                    )}
                </div>
            </div>



        );
    }
}

export default withRouter(HomeUser);