import axios from "axios"

const url_bd = "http://localhost:9090/ENQUETE-SERVICE/bd"
//const url_bd="http://localhost:9090/ENQUETE-SERVICE/bd"
class BDService {
    getBdsByThematique(thematique) {
        return axios.get(url_bd + "/" + thematique)
    }

    getNbreBdsByThematique(thematique) {
        return axios.get(url_bd + "/count" + "/" + thematique);
    }
    getnbreAllBds() {
        return axios.get(url_bd + "/count");
    }
    getTop3Bds() {
        return axios.get(url_bd + "/top3bd");
    }
    getAllBds() {
        return axios.get(url_bd + "/all");
    }

    addBD(bd) {
        return axios.post(url_bd + "/add", bd);
    }

    getBDById(id) {
        return axios.get(url_bd + "/search" + "/" + id);
    }

    getBDByNom(nom) {
        return axios.get(url_bd + "/nom" + "/" + nom);
    }
    deleteDemande(id) {
        return axios.delete(url_bd + "/delete" + "/" + id)
    }
    updateBase(id,bd) {
        return axios.put(url_bd + '/update/' + id,bd)
    }
}
export default new BDService()