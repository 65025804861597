import React, { PureComponent, Component } from 'react';
import BDService from '../../service/BDService';
import EntiteService from '../../service/EntiteService';
import EnqueteService from '../../service/EnqueteService';
import '../../css/thematiques/dashbord.css'
import line from '../../assets/line.png';
import area from '../../assets/area.png';
import bar from '../../assets/bar.png';
import shape from '../../assets/scatter.png';
import radar from '../../assets/radar.PNG';
import pie from '../../assets/pie.jpg';
import {
    ResponsiveContainer,
    ComposedChart,
    Line,
    Area,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Scatter,
    Pie,
    PieChart,
    Radar,
    AreaChart,
    PolarRadiusAxis,
    PolarAngleAxis,
    PolarGrid,
    RadarChart
    
} from 'recharts';
import IndicateurService from '../../service/IndicateurService';
import { keys } from '@mui/material/node_modules/@mui/system';
import NavBarComponent from '../header/NavBarComponent';
import MenuBd from './MenuBd';
import UserService from '../../service/UserService';

class DashbordPhase2 extends Component {
    static demoUrl = 'https://codesandbox.io/s/composed-chart-in-responsive-container-pkqmy';
    constructor(props) {
        super(props);
        this.state = {
            listeIndicateurs: [],
            entite: this.props.match.params.id,
            nom_bd: null,
            nom_entite: null,
            indicateurName: '',
            indicateurAbcisse: '',
            user: '',
            idUser: parseInt(localStorage.getItem('idUser')),
            datas: [],
            keys: [],
            clic:false,
            abcisse: "",
            ordonnees: [],
            entries: [],
            selectedIndicators: [],
            colors:
                ['#0088FE', '#00C49F', '#FFBB28', '#FF8042','#7D3C98', '#FFC300 ', '#58D68D', '#C1BDC4', '#FAE31D', '#1D2AFA',
                    '#DE0374', '#9398FF ', '#40E8C7 ', '#5D6D7E ', '#F5B7B1 ', '#B7950B '
                ],
            checkbox: [],
            graphique: 0


        }
    }

    async componentDidMount() {
        IndicateurService.getIndicateursByEntite(this.state.entite).then((res) => {
            this.setState({
                listeIndicateurs: res.data
            })
        });
        UserService.getUserById(this.state.idUser).then((res) => {
            this.setState({
                user: res.data
            })

        }
        );
    }

    

 
    shuffle = (array) => {
        let currentIndex = array.length, randomIndex;
        while (currentIndex != 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }
        return array[0];
    }

    handleIndicateurClic = async (e) => {
this.setState({
    selectedIndicators: [],
    checkbox:[]
   
})

        IndicateurService.getIndicateurById(e.target.name).then((res) => {
            this.setState({
                ordonnees: res.data.ordonnees,
                checkbox: res.data.ordonnees,
                clic:true

                             
            })
            
        });
        //window.location.reload(true)
        EnqueteService.getdatas(e.target.value + "*-*" + this.state.entite).then((res) => {
            //console.log(res.data);


            if (res.data?.length !== 0) {
                this.setState({

                    datas: res.data,
                    indicateurName: e.target.name,
                    indicateurAbcisse: e.target.id,
                    keys: Object.keys(res.data[0]),
                      

                    //abcisse:this.state.keys[1]


                })
            } else {
                this.setState({
                    datas: res.data
                })

                //window.location.reload(true)
            }


            console.log(e.target)



        });
    }


    handleSelection = (e) => {
        var color = this.shuffle(this.state.colors)
        var element = { name: e.target.value, color }
        if (e.target.checked) {
            this.state.selectedIndicators.push(element)
            this.setState({
                ordonnees:this.state.selectedIndicators,
                
            })
        } else {
            this.state.selectedIndicators = this.state.selectedIndicators.filter(data => data.name != e.target.value);
            this.setState({
                ordonnees:this.state.selectedIndicators
            })
        }

    }
 //handle selection graphiques
handleGrpahique1selection=()=>{
    this.setState({
        graphique:1
    })
}

handleGrpahique2selection=()=>{
    this.setState({
        graphique:2
    })
}

handleGrpahique3selection=()=>{
    this.setState({
        graphique:3
    })
}



handleGrpahique6selection=()=>{
    this.setState({
        graphique:6
    })
}
 
    getDashbord = (ordonnees) => {
        if(this.state.graphique==0 || this.state.graphique==6 )
        {
        return <>
            {ordonnees?.map(ords => (ords.name?.toUpperCase() !== "Total".toUpperCase()) && (<Bar type="monotone" dataKey={ords.name} fill={ords.color} stroke={ords.color} />))}

            {ordonnees?.map(ords => (ords.name?.toUpperCase() == "Total".toUpperCase()) && (<Line type="monotone" dataKey={ords.name} stroke="#F55012" />))}
            </>
        }
        //courbe line
        else if(this.state.graphique==1)
        {
        return <>
            {ordonnees?.map(ords => <Line type="monotone" dataKey={ords.name} fill={ords.color} stroke={ords.color} />)}
            </>
        }
         //courbe area
         else if(this.state.graphique==2 )
         {
         return <>
             {ordonnees?.map(ords => <Area type="monotone" dataKey={ords.name} fill={ords.color} stroke={ords.color} />)}
             </>
         }
          
           //courbe Scatter
         else if(this.state.graphique==3)
         {
         return <>
             {ordonnees?.map(ords =>
                 <Scatter type="monotone" dataKey={ords.name} fill={ords.color} stroke={ords.color}  />)}
             </>
         }
         
      


    }
    chart=(datas,ordonnees)=>{
        if(this.state.graphique==0||this.state.graphique==1
            ||this.state.graphique==2||this.state.graphique==3
            ||this.state.graphique==6){
      return  <>
            <ComposedChart
                width={1000}
                height={300}
                data={datas}
                margin={{
                    top: 2,
                    right: 2,
                    bottom: 2,
                    left: 20,
                }}
            >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis dataKey={this.state.indicateurAbcisse} scale="band" />
                <YAxis />
                <Tooltip />
                <Legend />
                {this.getDashbord(ordonnees)}
            </ComposedChart>
            </>
            }
            

                
    }
    render() {
        const { listeIndicateurs, datas, ordonnees, checkbox } = this.state
        return (
            <>
             <NavBarComponent />
                <button onClick={()=> this.props.history.goBack()} className='btn  d-flex btn-indicateur btn-primary'><bi className='bi bi-arrow-left'></bi><span className=''>Retour aux volets</span></button>
                <div >
         
            <div className='row col-lg-12 d-flex justify-content-around'>
                <div className='row  dashbords  card-body col-lg-9' style={{ width: '100%', height: 300 }}>
                    {(datas !== []) && (<div className='card-body'>{checkbox?.map(ords =>
                        <div className='row '>
                            <input key={ords?.name} onChange={this.handleSelection} className='col-lg-1 card-body' value={ords?.name} type="checkbox" />
                            
                            <span>{ords?.name}</span>
                        </div>)}
                        <br/> <br/> <br/>
                    </div>
                    )}


                    {(datas !== []) && (
                     <div className='container'>  
                     <div className='card-body'>{(this.state.user.profil == "user_lartes" && this.state.clic ) && (<button className="btn btn-success d-flex justify-content-right" style={{float:'right' }} ><bi className="bi  bi-download"></bi></button>)}
                     </div>
                       <div className='card-body'>
                          
                        <ResponsiveContainer>                    
                           {this.chart(datas,ordonnees)}
                        </ResponsiveContainer>
                    </div>
                    </div>
                   
                    )}

                    {(datas !== []) && (<div className='row card-body'>

                        <img onClick={this.handleGrpahique1selection} className='btn_change-graphique col-lg-2' src={line} />
                        <img onClick={this.handleGrpahique2selection} className='btn_change-graphique col-lg-2' src={area} />
                        <img onClick={this.handleGrpahique3selection} className='btn_change-graphique col-lg-2' src={shape} />
                        
                        <img onClick={this.handleGrpahique6selection} className='btn_change-graphique col-lg-2' src={bar} />

                    </div>
                    )}

                </div>
                <div className='row indicateurs card-body col-lg-2'>
                    {listeIndicateurs.map(indicateurs =>
                        <input type="button" onClick={this.handleIndicateurClic} key={indicateurs.idIndicateur} id={indicateurs.abcisse} name={indicateurs.idIndicateur} className='btn btn-indicateur btn-light' value={indicateurs.libelle} />
                    )}
                </div>
            </div>
            </div>
            </>
        );
    }
}

export default DashbordPhase2;