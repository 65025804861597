import axios from "axios"

const url_thematique = "http://localhost:9090/ENQUETE-SERVICE/thematique"
//const url_thematique="http://192.168.0.53:8080/thematique"
class ThematiqueService {
  getAllThematique() {
    return axios.get(url_thematique + '/all');
  }
  getnbreAllThemqtiques() {
    return axios.get(url_thematique + "/count");
  }

  getNbreThematique(visibility) {
    return axios.get(url_thematique + "/nbre" + "/" + visibility);
  }

  getVisibleThematique(visibility) {
    return axios.get(url_thematique + "/visible" + "/" + visibility);
  }

  addThematique(theme) {
    return axios.post(url_thematique + "/add", theme);
  }


  getThematiqueById(id) {
    return axios.get(url_thematique + "/search" + "/" + id);
  }

  getThematiqueByNom(nom) {
    return axios.get(url_thematique + "/" + nom);
  }


  updateThematique(thematiqueId, thematique) {
    return axios.put(url_thematique + '/update/' + thematiqueId, thematique)
  }

}
export default new ThematiqueService()