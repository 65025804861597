import React, { Component } from 'react';
import ThematiqueListeCss from '../../css/thematiques/ThematiqueListeCss.css'
import ThematiqueService from '../../service/ThematiqueService';
import image from '../../assets/no-image.png';
import { withRouter } from 'react-router-dom';
import BDService from '../../service/BDService';
import UserService from '../../service/UserService';
import NbreBds from './NbreBds';
import AddThematique from './addThematique';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import UpdateThematique from './UpdateThematique';
import { Modal } from 'bootstrap';
import { Button } from 'bootstrap';

class ThematiqueListeComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listeThematique: [],
            nbreBds: '',
            theme: '',
            idUser: parseInt(localStorage.getItem('idUser')),
            user: '',
            addThemeShow: false,
            addThemeShowUpd: false,
            thematique_nom: "",
            description: "",
            icone: "",
            x: 0,
            listeAllThematique:[],
            
        }
    }


    closeThematique = () => {
        this.setState({
            addThemeShow: false
        });

    }
    fermerThematique2 = () => {
        this.setState({
            addThemeShow: false,
            thematique_nom: "",
            description: "",
            icone: ""
        });
        window.location.reload(true);
    }
    fermerThematique2Upd = () => {
        this.setState({
            addThemeShowUpd: false,
            thematique_nom: "",
            description: "",
            icone: ""
        });
        window.location.reload(true);
    }
    fermerThematique = () => {
        this.setState({
            addThemeShow: false,
            thematique_nom: "",
            description: "",
            icone: ""
        });
        
    }
    fermerThematiqueUpd = () => {
        this.setState({
            addThemeShowUpd: false,
            thematique_nom: "",
            description: "",
            icone: ""
        });    
    }
    openThematiqueModal = (e) => { 
        this.setState({
            addThemeShow: true
        });
    }
    openThematiqueModalUpd = (e) => {
        this.setState({
            addThemeShowUpd: true
        });
    }

    componentDidMount() {
        ThematiqueService.getVisibleThematique(true).then((res) => {
            this.setState({
                listeThematique: res.data
            })
        });
        UserService.getUserById(this.state.idUser).then((res) => {
            this.setState({
                user: res.data
            })

        }
        );

    }


    //redirection vers la liste des BD  de la thematiques 
    versSesBD = (id) => {    
        this.props.history.push(`/listebd/${id}/#`);
    }

    updateThematique = (id) => {
        //this.props.history.push(`/addTheme/${id}`);
        ThematiqueService.getThematiqueById(id).then((res) => {
            let thematique = res.data;
            this.setState({
                thematique_nom: thematique.nom,
                description: thematique.description,
                icone: thematique.icone,
                

            })
        });
        this.openThematiqueModalUpd()

    }

    deleteThematique = (id) => {

        ThematiqueService.getThematiqueById(id).then((res) => {
            this.setState({
                theme: res.data
            })
            let thematique = {
                visibility: false,
                description: this.state.theme.description,
                nom: this.state.theme.nom,
                icone: this.state.theme.icone,

            }
              

            ThematiqueService.updateThematique(id, thematique).then((res) => {

                this.setState({
                    listeThematique: this.state.listeThematique.filter(thematique => thematique.idThematique !== id),
                    
                })

            })
        })


    }


    afficherToutesLesThematiques=()=>{
        //afficher toutes les thematiques
        let voirtout = document.getElementById("voirtout");
        let btntop4_them = document.getElementById("btntop4_them");
        voirtout.classList.remove("invisible");
        btntop4_them.classList.remove("invisible");
        //cacher les top 3 thematiques
        let containListe = document.getElementById("containListe");
        let btntout_them = document.getElementById("btntout_them");
        containListe.classList.add("invisible");
        btntout_them.classList.add("invisible");
        
    }
    afficherTop3Thematiques=()=>{
        //cacher  toutes les thematiques
        let voirtout = document.getElementById("voirtout");
        let btntop4_them = document.getElementById("btntop4_them");
        btntop4_them.classList.add("invisible");
        voirtout.classList.add("invisible");
        // afficher les top 3 thematiques
        let containListe = document.getElementById("containListe");
        let btntout_them = document.getElementById("btntout_them");
        containListe.classList.remove("invisible");
        btntout_them.classList.remove("invisible");
    }
    render() {
        const { listeThematique } = this.state
        return (
            <div className=''>
                <div className='row'>
                    <h2 id="title"  ><i id="icone" ></i><span>Thématiques </span>

                    </h2>

                </div>
                <AddThematique
                    show={this.state.addThemeShow}
                    thematique_nom={this.state.thematique_nom}
                    description={this.state.description}
                    icone={this.state.icone}
                    fermerThematique={this.fermerThematique}
                    fermerThematique2={this.fermerThematique2}
                />

               <UpdateThematique
                    show={this.state.addThemeShowUpd}
                    thematique_nom={this.state.thematique_nom}
                    description={this.state.description}
                    icone={this.state.icone}
                    fermerThematique={this.fermerThematiqueUpd}
                    fermerThematique2={this.fermerThematique2Upd}
                />
                <div className='container-fluid'>
                    {(this.state.user.profil == "admin") && (<a href="#" onClick={this.openThematiqueModal}><span><bi className="bi  btn-add-thematique  bi-plus-circle-fill"><span className='ajout_theme'>Ajouter une thématique</span> </bi></span></a>)}
<div id='containListe' >
                    <div  className='d-flex justify-content-center card-body row col-md-12' >

                  {/*Thematique 1*/}
                  {(this.state.listeThematique[0]!=null)&&(
                                    <div id="table" className='col-lg-4 py-5  groupe_thematique  '>
                                        <button className='btns'>
                                            {(this.state.user.profil == "admin") && (<a onClick={() => this.updateThematique(listeThematique[0].idThematique)} ><span><bi className="bi pencil  bi-pencil-fill"></bi></span></a>)}
                                            {(this.state.user.profil == "admin") && (<a ><span><bi onClick={() => this.deleteThematique(listeThematique[0].idThematique)} className="bi pencil supprimer  bi-trash-fill"></bi></span></a>)}
                                        </button>

                                        <button onClick={() => this.versSesBD(listeThematique[0].idThematique)} key={listeThematique[0].idThematique} className='col-lg-12 uneThematique'>
                                            <div className='div_image_themaqtique'>
                                                {(listeThematique[0].icone !== '') && (<img className='image_thematique' src={listeThematique[0].icone}></img>)}
                                                {(listeThematique[0].icone == '') && (<img className='image_thematique' src={image}></img>)}
                                            </div>
                                            <h3 className='nom_thematique'>{listeThematique[0].nom}</h3>
                                            <p className='description_thematique'>{listeThematique[0].description}</p>
                                            <span className='totalBd'>
                                                <NbreBds thematique={listeThematique[0].idThematique} />
                                            </span>
                                 
                                            <br />
                                        </button>
                                    </div>
                               )}
                       {/*Fin Thematique 1*/}    
                        

                        {/*Thematique 2*/}
                        {(this.state.listeThematique[1]!=null)&&(
                                    <div id="table" className='col-lg-4 py-5  groupe_thematique  '>
                                        <button className='btns'>
                                            {(this.state.user.profil == "admin") && (<a onClick={() => this.updateThematique(listeThematique[1].idThematique)} ><span><bi className="bi pencil  bi-pencil-fill"></bi></span></a>)}
                                            {(this.state.user.profil == "admin") && (<a ><span><bi onClick={() => this.deleteThematique(listeThematique[1].idThematique)} className="bi pencil supprimer  bi-trash-fill"></bi></span></a>)}
                                        </button>
                                       
                                        <button onClick={() => this.versSesBD(listeThematique[1].idThematique)} key={listeThematique[1].idThematique} className='col-lg-12 uneThematique'>
                                            <div className='div_image_themaqtique'>
                                                {(listeThematique[1].icone !== '') && (<img className='image_thematique' src={listeThematique[1].icone}></img>)}
                                                {(listeThematique[1].icone == '') && (<img className='image_thematique' src={image}></img>)}
                                            </div>
                                            <h3 className='nom_thematique'>{listeThematique[1].nom}</h3>
                                            <p className='description_thematique'>{listeThematique[1].description}</p>
                                            <span className='totalBd'>
                                                <NbreBds thematique={listeThematique[1].idThematique} />
                                            </span>
                                 
                                            <br />
                                        </button>
                                    </div>
                               )}
                       {/*Fin Thematique 2*/}  


                       {/*Thematique 3*/}
                       {(this.state.listeThematique[2]!=null)&&(
                                    <div id="table" className='col-lg-4 py-5  groupe_thematique  '>
                                        <button className='btns'>
                                            {(this.state.user.profil == "admin") && (<a onClick={() => this.updateThematique(listeThematique[2].idThematique)} ><span><bi className="bi pencil  bi-pencil-fill"></bi></span></a>)}
                                            {(this.state.user.profil == "admin") && (<a ><span><bi onClick={() => this.deleteThematique(listeThematique[2].idThematique)} className="bi pencil supprimer  bi-trash-fill"></bi></span></a>)}
                                        </button>

                                        <button onClick={() => this.versSesBD(listeThematique[2].idThematique)} key={listeThematique[2].idThematique} className='col-lg-12 uneThematique'>
                                            <div className='div_image_themaqtique'>
                                                {(listeThematique[2].icone !== '') && (<img className='image_thematique' src={listeThematique[2].icone}></img>)}
                                                {(listeThematique[2].icone == '') && (<img className='image_thematique' src={image}></img>)}
                                            </div>
                                            <h3 className='nom_thematique'>{listeThematique[2].nom}</h3>
                                            <p className='description_thematique'>{listeThematique[2].description}</p>
                                            <span className='totalBd'>
                                                <NbreBds thematique={listeThematique[2].idThematique} />
                                            </span>
                                 
                                            <br />
                                        </button>
                                    </div>
                               )}
                       {/*Fin Thematique 3*/}  


                    </div>
</div>

                    {/*Voir tout*/}
                    <div id='voirtout' className='invisible'>
                    <div  className=' d-flex justify-content-center card-body row col-md-12' >

                        {

                            listeThematique.map(listetheme =>
                                
                                    <div id="table" className='col-lg-4 py-5  groupe_thematique  '>
                                        <button className='btns'>
                                            {(this.state.user.profil == "admin") && (<a onClick={() => this.updateThematique(listetheme.idThematique)} ><span><bi className="bi pencil  bi-pencil-fill"></bi></span></a>)}
                                            {(this.state.user.profil == "admin") && (<a ><span><bi onClick={() => this.deleteThematique(listetheme.idThematique)} className="bi pencil supprimer  bi-trash-fill"></bi></span></a>)}
                                        </button>

                                        <button onClick={() => this.versSesBD(listetheme.idThematique)} key={listetheme.idThematique} className='col-lg-12 uneThematique'>
                                            <div className='div_image_themaqtique'>
                                                {(listetheme.icone !== '') && (<img className='image_thematique' src={listetheme.icone}></img>)}
                                                {(listetheme.icone == '') && (<img className='image_thematique' src={image}></img>)}
                                            </div>
                                            <h3 className='nom_thematique'>{listetheme.nom}</h3>
                                            <p className='description_thematique'>{listetheme.description}</p>
                                            <span className='totalBd'>
                                                <NbreBds thematique={listetheme.idThematique} />
                                            </span>
                                 
                                            <br />
                                        </button>
                                    </div>
                                
                            )
                        }
                    </div>
                    </div>


               {/*Bouton voir  plus de Thematiques*/}
                    <div className='btns_plus_moins'>
                    <div id="btntout_them"  >
                        <button className='voirplus  ' onClick={this.afficherToutesLesThematiques} >
                           <a className='btns_plus_moins' href='/#thematique'>
                            <span className='btn_plus'>
                                Plus de thematiques  <i class="bi bi-chevron-right"></i></span>
                        </a>
                        </button>
                    </div>
                    <div id="btntop4_them"className='invisible' >
                        <button className='voirplus  ' onClick={this.afficherTop3Thematiques}>
                           <a  className='btns_plus_moins' href='/#thematique'>
                            <span className='btn_plus'>
                            <i class="bi bi-chevron-left"></i> Reduire ... </span>
                            </a>
                        </button>
                    </div>
                       
                    </div>
                </div>
            </div>
        );
    }
   
}

export default withRouter(ThematiqueListeComponent);