import axios from "axios"

const url_pays = "http://localhost:9090/ENQUETE-SERVICE/pays"
//const url_pays="http://192.168.0.53:8080/pays"
class Pays {

    getAllPays() {
        return axios.get(url_pays + '/all')
    }

    addPays(pays) {
        return axios.post(url_pays + "/add", pays);
    }

    getPaysById(pays) {
        return axios.get(url_pays + "/search" + "/" + pays)
    }

    addPays(pays) {
        return axios.post(url_pays + "/add", pays);
    }
}
export default new Pays()