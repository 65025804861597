import React, { Component } from 'react';
import UnderNavBarCss from '../../css/header/UnderNavBarCss.css';
import heroImg from '../../assets/accueil.png';
import { Router, withRouter } from 'react-router-dom';


class UnderNavBarComponent extends Component {


    render() {

        return (
            <div className='row ' id="under-navbar">

                <div class="container col-lg-6 d-flex flex-column justify-content-center " id="under-navbar-left">

                    <h1 className='container'>Observatoire socio-économique des ménages Sénégal/Afrique</h1>
                    <br />
                    <h2 className='container'><i>Produire et partager des données de qualité probantes au service 
                        des politiques publiques intelligentes.</i>
                    </h2>
                    <a href='/#contact'>
                        <button onClick={this.redirection} className='btn d-inline-flex align-items-center justify-content-center align-self-center"'>
                            <span>Nous Contacter</span>
                        </button>
                    </a>
                </div>
                <div className='col-lg-6' id="under-navbar-right">
                    <div className='col-lg-12 contain_recherche form-group d-flex justify-content-center  row '>
                        <input className='form-control shadow   card-body  col-lg-10' placeholder='Rechercher... ' />
                        <a className='btn div_recherche  '>
                            <span className=' btn_recherche col-lg-2'><i className='bi bi-search '></i></span>
                        </a>
                    </div>
                    <img className='row col-lg-12' src={heroImg} />

                </div>
            </div>
        );
    }
}

export default withRouter(UnderNavBarComponent);