import React, { PureComponent,Component } from 'react';
import BDService from '../../service/BDService';
import EntiteService from '../../service/EntiteService';
import EnqueteService from '../../service/EnqueteService';
import  '../../css/thematiques/dashbord.css'

import IndicateurService from '../../service/IndicateurService';
import { keys } from '@mui/material/node_modules/@mui/system';
import DashbordPhase3 from './DashbordPhase3';
import { withRouter } from 'react-router-dom';

class DashMenuIndicateur extends Component {
    static demoUrl = 'https://codesandbox.io/s/composed-chart-in-responsive-container-pkqmy';
    constructor(props) {
        super(props);
        this.state = {
            listeIndicateurs: [],
            entite: this.props.entite
           
        }
    }

    async componentDidMount() {    
        IndicateurService.getIndicateursByEntite(this.state.entite).then((res) => {
            this.setState({
              listeIndicateurs: res.data
            })
          });   
    }

     handleIndicateurClic=(id)=>{
        

         this.props.history.push(`/dashbord3/${id}`)
        
    }
    render() {
    const{listeIndicateurs,datas,keys}=this.state
        return (
            
                <div  className='container dashbords  card-body ' style={{ width: '100%', height: 300 }}>
    
                <div className='row indicateurs  '>
                    {listeIndicateurs.map(indicateurs=>
                       <button onClick={()=>this.handleIndicateurClic(indicateurs.idIndicateur+"*-*"+indicateurs.entite+"*-*"+indicateurs.libelle)}  key={indicateurs.idIndicateur} id={indicateurs.idIndicateur}  className='btn btn-indicateur btn-light'>{indicateurs.libelle}</button> 
                        )}
                </div>
            </div>
        );
    }
}

export default withRouter(DashMenuIndicateur) ;