import axios from "axios";
const url_user = "http://localhost:9090/ENQUETE-SERVICE/user"
//const url_user="http://192.168.0.53:8080/user"
class UserService {
    getAllUser() {
        return axios.get(url_user + '/all')
    }

    addUser(user) {
        return axios.post(url_user + "/add", user);
    }

    getnbreAllUser() {
        return axios.get(url_user+ "/count");
      }
    getUserByEmailAndPwd(email, pwd) {
        return axios.get(url_user + '/' + email + '/' + pwd)
    }

    getUserById(idUser) {
        return axios.get(url_user + "/search" + "/" + idUser)
    }

    updateUser(userId, user) {
        return axios.put(url_user + '/update/' + userId, user)
    }
}
export default new UserService()