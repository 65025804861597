import React, { Component } from 'react';

class ConvertDate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idUser: parseInt(localStorage.getItem('idUser')),
            date: null

        }

    }

    async componentDidMount() {
        this.setState({
            date: new Date(this.props.date).toLocaleDateString()
        })
    }


    render() {
        return (
            <td>{this.state.date}</td>
        );
    }
}

export default ConvertDate;