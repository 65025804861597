import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class IndicateursDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idUser: parseInt(localStorage.getItem('idUser')),
            indicateurs: this.props.indicateurs

        }

    }

    async componentDidMount() {
        const { indicateurs } = this.state
        this.setState({
            indicateurs: indicateurs.map(e => e.toString().split(",").map(f => 
            
            <div className='row card-body divIndicateur ' > 
             <input  className='check'  checked type="checkbox" />
            {f} </div>))
        })


    }

    render() {
        const { indicateurs } = this.state
        return (
            <div >
                <h3 className='h3_indicateur'>Indicateurs</h3>
                {indicateurs}
            </div>
        );
    }
}

export default withRouter(IndicateursDetails);